// Covers
//    @srs_20.1 @srs_20.2

/*
 * Provides Vue mixin to make a input field barcode-enabled. Will auto-tab to
 * next field under the assumption that the next field will contain the next
 * barcode to scan so it saves the user having to stop barcoding to hit tab.
 *
 * Use by calling onInput when the input event is emitted from the text field.
 */

import { formatTimestamp } from 'date_time_format'

import UnitNumber from 'barcode/unit_number'
import ProductCode from 'barcode/product_code'
import BloodType from 'barcode/blood_type'
import Expiration from 'barcode/expiration'

const barcodeMatchers = [UnitNumber, ProductCode, BloodType, Expiration]

export default {
  emits: ['update:modelValue'],
  methods: {
    onKeyDown(event) {
      // Discard Enter and Tab control codes if barcode just recognized
      if( this.suppressControlCode && ['Enter', 'Tab'].includes(event.key) )
        event.preventDefault()

      this.suppressControlCode = false
    },

    onInput(event) {
      let value = event.target.value

      // Try to match the current value to a barcode. If we are able to then
      // convert from raw barcode value to data value.
      barcodeMatchers.forEach(matcher => {
        const m = new matcher(value)
        if( !m.isValid() ) return

        this.suppressControlCode = true
        value = m.value()

        // The barcode matcher returns an ISO formatted timestamp as it assumes
        // we will be storing as structured data in the database. But instead we
        // are storing in a plain text field so convert to the locale-specific
        // timestamp format to make it more user-friendly even though it makes
        // the data unstructured.
        if( matcher == Expiration ) value = formatTimestamp(value)

        // Slight delay so anything else so if a control code is entered after
        // the barcode it can be discarded and not sent to the next field.
        setTimeout((()=> this.tabNext(event.target)), 100)
      })

      event.target.value = value
      this.$emit('update:modelValue', value)
    },

    // Not going to take tabindex into account as we are not currently using
    // that in this app. Just assume the next field matches document order
    // (which is the order querySelectorAll returns).
    //
    // Based on https://stackoverflow.com/a/2456761 but without jQuery
    tabNext(currentField) {
      this.suppressControlCode = false

      // Just in case something else already navigated us trust it not this
      if( currentField != document.activeElement ) return

      const form = currentField.closest('form')
      if( !form ) return

      const fields = Array.from(form.querySelectorAll('input, select, textarea'))
      const currentIdx = fields.indexOf(currentField)
      const nextField = fields[currentIdx+1]

      if( nextField ) nextField.focus()
    }
  }
}
