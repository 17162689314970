// Covers
//   @srs_1.1 @srs_1.3 @srs_1.9 @srs_8.1 @srs_9.1 @srs_20.4 @srs_20.5 @srs_21.2 @srs_22.1

// Use jQuery as bootstrap sends jQuery events instead of real DOM events
import $ from 'jquery'

$(document).on('shown.bs.modal', e => {
  const modal = e.target

  // Prefer where developer has specified field to focus but if not found then
  // just find first input element as a reasonable default
  const input = (
    modal.querySelector('[autofocus]') ||
    modal.querySelector('input:not([type=hidden]), textarea, select')
  )

  if( input ) {
    // Set the focus on the field
    input.focus()

    // Move cursor to end of line. Conditional is because not all input types
    // support setting the selection range
    if( input.setSelectionRange ) {
      const contentLength = input.value.length
      input.setSelectionRange(contentLength, contentLength);
    }
  }
})
