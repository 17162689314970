Covers
  @srs_15.5 @srs_15.6 @srs_23.1 @srs_24.1

Outputs UDF field and formatted value if the visibility list allows
"Search Result"

<template>
  <div v-if="visible">
    <dt>{{ label }}</dt>
    <dd>{{ formattedValue }}</dd>
  </div>
</template>

<script>
import initializeField from './field_types'

export default {
  props: ['title', 'abbr_title', 'value', 'visibility'],
  inheritAttrs: false,
  computed: {
    label() {
      return this.abbr_title || this.title
    },
    formattedValue() {
      const field = initializeField(Object.assign({}, this.$attrs, this.$props))
      return field.displayValue().replaceAll("\n", ', ')
    },
    visible() {
      return this.visibility && this.visibility.includes('Search Results')
    }
  }
}
</script>
