Covers:
  @srs_1.1 @srs_1.3 @srs_1.9 @srs_7.1 @srs_7.5 @srs_8.1 @srs_9.1 @srs_12.1 @srs_20.6 @srs_21.4 @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1 @srs_25.2

Reduce the boilerplate for outputting a properly formatted Bootstrap field with
label. Usage:

  <field label="Name">
    <input type="text" />
  </field>

<template>
  <div class="form-group">
    <label v-if="label" :for="fieldId" class="control-label">
      {{ label }}
      <abbr v-if="required" title="required">*</abbr>
    </label>
    <slot />
    <small v-if="hint" class="form-text text-muted">{{ hint }}</small>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  props: ['label', 'hint'],

  computed: {
    fieldId() { return `a${uuid()}` },
  },

  data() {
    return {
      required: false
    }
  },

  // Tweak the DOM manually after Vue rendered to reduce boilerplate
  mounted() {
    const input = this.$el.querySelector('input, select, textarea')
    if( !input ) return

    input.classList.add('form-control')

    if( input.tagName == 'SELECT' )
      input.classList.add('custom-select')

    if( this.label )
      input.setAttribute('id', input.getAttribute('id') || this.fieldId)

    if( input.required )
      this.required = true
  },
}
</script>
