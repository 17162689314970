// Covers
//   @srs_20.5

/* Conceptually the same as:
 *
 *   search.append(key, data)
 *
 * Only data can be any value, object or array (mostly supporting nesting).
 * Following PHP convention for encoding. `[]` suffix indicates value of array.
 * `[key]` suffix indicates object (aka hash, dictionary, associative array,
 * etc).
 *
 * The `prefix` param is more for internal use. Not really supporting nested
 * arrays as it gets ambigous. PHP works around this by the fact that arrays
 * and associative arrays are really the same thing so a key of 0 can also work
 * as the array index. It won't thrown an error if you pass nested arrays but
 * the data parsed later may be different than how it was when encoded.
 */
export function assign(search, key, data, prefix='') {
  if( Array.isArray(data) ) {
    data.forEach((val) => assign(search, `${key}[]`, val, prefix))
  } else if( typeof(data) === 'object' ) {
    Object.entries(data).forEach(([k, v]) => {
      assign(search, `[${k}]`, v, `${prefix}${key}`)
    })
  } else {
    search.append(`${prefix}${key}`, data)
  }
}
