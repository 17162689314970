Covers:
  @srs_15.3

Integrate Vue's transition system with Bootstrap's `fade` effect so `v-if`
and `v-show` can trigger content fading in and out. Example:

    <fade>
      <div v-if="show">Faded Content</div>
    </fade>

<template>
  <transition :css="false"
    @beforeEnter="beforeEnter" @beforeLeave="ensureFade"
    @enter="enter" @leave="leave">
    <slot />
  </transition>
</template>

<script>
export default {
  methods: {
    beforeEnter(el) {
      el.style.display = ''
      this.ensureFade(el)
    },

    enter(el, done) {
      el.addEventListener('transitionend', done)
      // QUESTION: Why do we need to wait here. What are we waiting for?
      setTimeout(()=> el.classList.add('show'), 50)
    },
    leave(el, done) {
      el.addEventListener('transitionend', done)
      el.classList.remove('show')
    },
    ensureFade(el) { el.classList.add('fade') },
  }
}
</script>
