Covers
  @srs_1.1 @srs_5.1 @srs_6.1 @srs_7.5 @srs_20.5 @srs_21.4 @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1 @srs_25.2 @srs_26.1

Pre-packaged button for creating a new item. Label passed in as slot content.
Defaults to using `a` tag to be semantically correct and simpler. Passes on
all extra attributes (such as href) and events. Usage:

    <new-button href="/admin/users/new">New User</new-button>

<template>
  <styled-button :tag="tag"
    icon="plus" priority="secondary" :purpose="purpose" v-bind="$attrs">
    <slot />
  </styled-button>
</template>

<script>
import StyledButton from 'bootstrap/styled_button'

export default {
  props: {
    tag: { default: 'a' },
    purpose: { default: 'success' },
  },
  components: { StyledButton },
}
</script>
