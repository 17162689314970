Covers
  @srs_3.1

Top-level audit component. Build on normal `results` and `filters` components

<template>
  <div>
    <filters>
      <options-filter label="Fields Changed" multiple="true"
        v-model="query.fields" :options="searchFields"/>
    </filters>

    <results :initial-uri="`${this.auditableType}/${this.auditableId}/audits`" :query="query">
      <template #default="{ rows }">
        <audit v-for="audit in rows"
          :key="audit.id" :audit="audit" :owner-type="ownerType" />
      </template>
    </results>
  </div>
</template>

<script>
import Filters from 'index/filters'
import OptionsFilter from 'index/filter/options'
import Results from 'index/results'

import Audit from './item'

export default {
  props: ['auditableType', 'auditableId', 'ownerType', 'searchFields'],
  data() {
    return {
      query: {
        fields: [],
      }
    }
  },
  components: { Audit, Filters, OptionsFilter, Results },
}
</script>
