Covers
  @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1 @srs_26.1

Vue component for managing the field_schema of a UserDefinedField in the
application. Note if updating any of the labels for the fields you likely
want to make a corresponding update in the audit presenter.

<template>
  <panel>
    <div class="field-schema">
      <div class="row">
        <div class="col"></div>
        <div class="col-auto">
          <delete-button v-if="needsDeleteButton" @click="$emit('delete-schema', fieldSchema)" class="field-schema-delete" />
        </div>
      </div>
      <div class="row">
        <div class="col-md">
          <field label="Title">
            <input v-model="fieldSchema.title" required />
          </field>
          <field label="Input Type">
            <select v-model="fieldSchema.type" required>
              <option disabled value="0">-- Select Type --</option>
              <option v-for="fieldType in fieldTypes" :key="fieldType.display_name" :value="fieldType.internal_name">
                {{ fieldType.display_name }}
              </option>
            </select>
          </field>
          <field label="Abbreviated Title">
            <input v-model="fieldSchema.abbr_title" />
          </field>
          <field label="Hint">
            <input v-model="fieldSchema.hint" />
          </field>
          <field v-if="hasVisibilityTypes" label="Visibility">
            <checkbox
              v-for="(desc, type) in visibilityTypes" :key="type"
              :value="type" :checked="isVisibilitySelected(type)"
              @click="visibilityCheckboxClick">
              {{ desc }}
            </checkbox>
          </field>
        </div>
        <div class="col-md">
          <field label="More Information URL">
            <input v-model="fieldSchema.more_information_url" />
          </field>
          <field label="Options">
            <toggle-switch v-if="fieldUsesRequired" v-model="fieldSchema.required"
              :noPadding="true">
              User must fill in field
            </toggle-switch>
            <toggle-switch v-if="fieldUsesSearchable" v-model="fieldSchema.searchable"
              :noPadding="true">
              Can be used in searching for a record
            </toggle-switch>
            <toggle-switch v-for="(desc, flag) in additionalFlags" :key="flag"
              v-model="fieldSchema[flag]" :no-padding="true">
              {{ desc }}
            </toggle-switch>
          </field>
          <option-lists v-if="fieldUsesOptionValues" :field-schema="fieldSchema" :option-lists="optionLists" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <field label="Hook">
            <code-editor v-model="fieldSchema.hook" />
          </field>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
// Components
import CodeEditor from 'code_editor'
import Checkbox from 'bootstrap/checkbox'
import DeleteButton from 'bootstrap/delete_button'
import Field from 'bootstrap/field'
import OptionLists from './field_schema/option_lists'
import Panel from 'bootstrap/panel'
import ToggleSwitch from 'bootstrap/toggle_switch'

// Models
import initializeField from '../field_types'

export default {
  props: {
    'fieldSchema': {},
    'needsDeleteButton': {},
    'visibilityTypesAllowed': { default() { return ['Summary', 'Report', 'Search Results'] } },
    'additionalFlags': {},
    'optionLists': {},
  },
  emits: ['delete-schema', 'update:field-schema'],
  data() {
    return {
      showOptionValues: false
    }
  },
  watch: {
    "fieldSchema.type": function(newVal) { this.initFromType(newVal) }
  },
  computed: {
    fieldTypes() {
      // NOTE: If updating this also update the audit presenter to match
      return [
        { display_name: 'One-line', internal_name: 'one_line' },
        { display_name: 'Multi-line', internal_name: 'text_box' },
        { display_name: 'Number', internal_name: 'number' },
        { display_name: 'Date', internal_name: 'date' },
        { display_name: 'Time', internal_name: 'time' },
        { display_name: 'Drop-down', internal_name: 'select_list' },
        { display_name: 'Multi-select List', internal_name: 'multi_select_list' },
        { display_name: 'Yes/No', internal_name: 'yes_no' },
        { display_name: 'Switch', internal_name: 'switch' },
        { display_name: 'Checkboxes', internal_name: 'checkboxes' },
        { display_name: 'Radio Buttons', internal_name: 'radio_buttons' },
        { display_name: 'File Upload', internal_name: 'file' },
      ]
    },
    visibilityTypes() {
      // NOTE: If updating this also update the audit presenter to match
      const types = {
        'Summary': 'Display when panel in closed state',
        'Report': 'Display on report',
        'Search Results': 'Display on cards in search results',
      }
      return this.visibilityTypesAllowed.reduce((acc, type) => {
        acc[type] = types[type]
        return acc
      }, {})
    },
    hasVisibilityTypes() { return this.visibilityTypesAllowed.length > 0 },
    field() { if( this.fieldSchema.type ) return initializeField(this.fieldSchema) },
    fieldUsesSearchable() { return this.fieldSchema.searchable != null },
    fieldUsesRequired() { return this.fieldSchema.required != null },
    fieldUsesOptionValues() { return this.field?.requiresOptionValues() },
  },
  methods: {
    initFromType(type) {
      if( !type ) return

      this.$emit('update:field-schema', initializeField(this.fieldSchema).payload())
    },
    isVisibilitySelected(visibilityType) { return this.fieldSchema.visibility && this.fieldSchema.visibility.indexOf(visibilityType) > -1 },
    visibilityCheckboxClick(event) {
      let checked = event.target.checked
      let value = event.target.value

      if(checked) {
        this.fieldSchema.visibility ??= []
        this.fieldSchema.visibility.push(value)
      }
      else {
        let found_index = this.fieldSchema.visibility.findIndex(visibility => visibility == value)
        if(found_index > -1) {
          this.fieldSchema.visibility.splice(found_index, 1)
        }
      }
    }
  },
  components: {
    Checkbox, DeleteButton, Field, OptionLists, Panel, ToggleSwitch, CodeEditor,
  },
}
</script>
