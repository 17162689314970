Covers
  @srs_15.3

Root component for the transaction index screen. Displays list of transactions
grouped by transaction type. Provides search options to filter list.

<template>
  <div>
    <filters>
      <div class="row">
        <search v-model="query.search">
          <ul class="list-unstyled">
            <li>Transaction ID</li>
            <li>Unit Number</li>
          </ul>
        </search>

        <options-filter label="Transaction Status"
          v-model="query.status_eq" :options="['Completed', 'Pending']"/>
      </div>

      <date-filter label="Last Updated"
        v-model:at-gteq="query.updated_at_gteq"
        v-model:at-lteq="query.updated_at_lteq" />
    </filters>

    <result-groups count-uri="/transactions/counts" :query="query">
      <template #default="{ panelGroupId, counts }">
        <result-group
          name="Available" :icon="maIcon" :count="counts['make_available']"
          initial-uri="make_available/transactions"
          :panel-group-id="panelGroupId" :query="query">
          <template #default="{ rows }">
            <make-available-table :rows="rows" />
          </template>
        </result-group>

        <result-group
          name="Quarantined" :icon="qIcon" :count="counts['quarantine']"
          initial-uri="quarantine/transactions"
          :panel-group-id="panelGroupId" :query="query">
          <template #default="{ rows }">
            <quarantine-table :rows="rows" />
          </template>
        </result-group>

        <result-group
          name="Ship" :icon="sIcon" :count="counts['ship']"
          initial-uri="ship/transactions"
          :panel-group-id="panelGroupId" :query="query">
          <template #default="{ rows }">
            <ship-table :rows="rows" />
          </template>
        </result-group>
      </template>
    </result-groups>
  </div>
</template>

<script>
import Filters from 'index/filters'
import Search from 'index/filter/search'
import DateFilter from 'index/filter/date'
import OptionsFilter from 'index/filter/options'
import ResultGroups from 'index/result_groups'
import ResultGroup from 'index/result_group'
import QueryPersistence from 'index/query_persistence'
import MakeAvailableTable from './index/make_available'
import QuarantineTable from './index/quarantine'
import ShipTable from './index/ship'

import moment from 'moment-timezone'

import MakeAvailableIcon from 'bootstrap-icons/icons/plus-square.svg'
import QuarantinedIcon from 'bootstrap-icons/icons/exclamation-circle.svg'
import ShipIcon from 'bootstrap-icons/icons/truck.svg'

export default {
  mixins: [QueryPersistence],
  data() {
    return {
      query: this.fromUrlWithDefaults({
        search: '',
        status_eq: 'Completed',

        // Default to past 7 days. Code copied from DateFilter. Not strictly
        // necessary for functionality but ensures the query persistence can
        // properly recognize the default query
        updated_at_gteq: moment().subtract(7, 'days').startOf('day').format(),
        updated_at_lteq: moment().endOf('day').format(),
      })
    }
  },
  computed: {
    maIcon() { return MakeAvailableIcon },
    qIcon() { return QuarantinedIcon },
    sIcon() { return ShipIcon },
  },
  components: {
    Search, Filters, DateFilter, OptionsFilter, ResultGroups, ResultGroup,
    MakeAvailableTable, QuarantineTable, ShipTable
  }
}
</script>
