Covers
  @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

Vue component for managing a list of custom field schema option values for a UserDefinedField in the new and edit forms.

<template>
  <div>
    <div class="row">
      <field class="col">
        <input type='text' class="new-option-value" v-model="newOptionValue" @keypress.enter="addOptionValue" placeholder="New Option Value" />
      </field>
      <div class="col-auto">
        <new-button class="add-option" tag="button" @click="addOptionValue">
          Add
        </new-button>
      </div>
    </div>

    <container class="option-value-list" drag-handle-selector=".sortable-handle" @drop="updateSort">
      <OptionValue v-for="optionValue in optionValues" :key="optionValue"
        :optionValue="optionValue" @delete-option="removeOptionValue" />
    </container>
  </div>
</template>

<script>
import { Container } from 'vue3-smooth-dnd'

import Field from 'bootstrap/field'
import NewButton from 'bootstrap/new_button'
import OptionValue from './option_value'

export default {
  components: { Field, NewButton, OptionValue, Container },
  props: ['optionValues'],
  data() {
    return {
      newOptionValue: ''
    }
  },
  methods: {
    addOptionValue() {
      if( this.newOptionValue == '' ) return

      this.optionValues.push(this.newOptionValue)
      this.newOptionValue = ''
    },

    removeOptionValue(removalValue) {
      let found_index = this.optionValues.indexOf(removalValue)
      if(found_index > -1) {
        this.optionValues.splice(found_index, 1)
      }
    },

    updateSort({ removedIndex, addedIndex }) {
      const item = this.optionValues.splice(removedIndex, 1)[0]
      this.optionValues.splice(addedIndex, 0, item)
    },
  },
}
</script>

<style scoped>
.option-value-list { max-height: 20rem; overflow-y: auto }
</style>
