// Covers
//  @srs_1.1 @srs_1.3 @srs_1.9 @srs_7.5 @srs_8.1 @srs_9.1 @srs_12.1 @srs_20.1 @srs_20.2 @srs_20.5 @srs_20.6 @srs_21.4

import { createApp, h, markRaw } from 'vue'

import Toast from 'bootstrap/toast'
import Alert from 'bootstrap/alert'

import onNodeRemove from 'on_node_remove'

import NoticeIcon from 'bootstrap-icons/icons/check2.svg'
import AlertIcon from 'bootstrap-icons/icons/exclamation-triangle-fill.svg'

export function notice(message, props={}) {
  render(message, Object.assign({ purpose: 'success', icon: markRaw(NoticeIcon), closable: true }, props))
}

export function alert(message, props={}) {
  render(message, Object.assign({ purpose: 'danger', icon: markRaw(AlertIcon) }, props))
}

const container = c => c ??= '.toast-positioning'
const component = c => c ? Alert : Toast

function render(message, props={}) {
  const containerNode = document.querySelector(container(props.container))
  containerNode.innerHTML = ''

  if( window.lastNotice ) window.lastNotice.unmount()

  window.lastNotice = createApp({
    render() { return h(component(props.container), props, ()=> message) }
  })
  window.lastNotice.mount(containerNode)

  onNodeRemove(containerNode, ()=> {
    if( window.lastNotice ) window.lastNotice.unmount()
    window.lastNotice = null
  })
}

document.addEventListener('turbo:before-fetch-response', ({ detail: { fetchResponse } })=> {
  const flashHeader = fetchResponse.header('X-Flash')
  if( !flashHeader ) return

  const flashMessages = JSON.parse(decodeURIComponent(flashHeader))

  if( flashMessages.notice ) notice(flashMessages.notice)
  if( flashMessages.alert ) alert(flashMessages.alert)
})
