// Covers
//   @srs_7.5 @srs_18.2 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

import api from 'api'
import { backendUri } from './common'
import lockSetFor from '../lock_set'

export default async function(fieldId, scope, field, callback) {
  const [_, body] = await api(backendUri(scope, field))

  const lockSet = lockSetFor(fieldId)
  if( !lockSet.get(scope) ) lockSet.set(scope, body.lock_version)

  callback(body.value)
}
