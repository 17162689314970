Covers
  @srs_1.1 @srs_5.1 @srs_15.3 @srs_15.4 @srs_15.5 @srs_15.6 @srs_22.1 @srs_24.1

Generic text search filter for index component. Although a filter, it is not
designed to be contained within the `Filters` container component but instead
placed right before that component.

Content passed via the slot is placed in the popover for the question mark
icon. A variable bound to this component is automatically debounced to 500ms to
prevent excessive searching. Example usage:

    <search v-model="query.search">
      Help text
    </search>

<template>
  <container label="Search">
    <div class="input-group">
      <input type="search" autocomplete="off"
        :value="modelValue" @input.stop="debouncedInputChange($event.target.value)" />
      <div class="input-group-append">
        <span class="input-group-text border-0 bg-transparent">
          <span class="badge badge-circle badge-info"
            data-toggle="popover" data-trigger="hover click"
            data-placement="right" data-html="true"
            data-container="body" data-title="Search By"
            :data-content="renderedHelp()">
            ?
          </span>
        </span>
      </div>
    </div>
  </container>
</template>

<script>
import Container from 'index/filter/container'
import debounce from 'debounce'
import vnodeRender from 'ext/vue/vnode_render'

export default {
  props: ['modelValue'],
  emits: ['update:modelValue'],
  components: { Container },
  created() { this.debouncedInputChange = debounce(this.inputChange, 500) },
  methods: {
    renderedHelp() {
      if( this.$slots.default )
        return vnodeRender(this.$slots.default())
    },
    inputChange(value) { this.$emit('update:modelValue', value) },
  },
}
</script>
