// Covers
//   @srs_1.1 @srs_3.1 @srs_5.1 @srs_6.1 @srs_15.3 @srs_15.4 @srs_15.5 @srs_15.6 @srs_22.1 @srs_24.1

import LinkHeader from 'http-link-header'

export default {
  methods: {
    setPagination( linkHeader ) {
      this.previousUri = null
      this.nextUri = null
      if( linkHeader ) {
        const links = LinkHeader.parse(linkHeader)
        this.previousUri = links.rel('prev')[0]
        if( this.previousUri ) this.previousUri = this.previousUri.uri
        this.nextUri = links.rel('next')[0]
        if( this.nextUri ) this.nextUri = this.nextUri.uri
      }
    }
  }
}
