// Covers
//   @srs_7.1 @srs_8.1 @srs_9.1 @srs_12.1 @srs_15.3 @srs_15.4

import moment from 'moment-timezone'
import { meta_value } from 'meta_value'

document.addEventListener('turbo:load', ()=> {
  const time_zone = meta_value('time_zone')
  if( time_zone ) {
    moment.tz.setDefault(time_zone)
  } else {
    moment.tz.setDefault(moment.tz.guess())
  }
})
