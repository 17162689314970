// Covers
//   @srs_7.5 @srs_7.6 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

export default class {
  // On every instance regardless if just uploaded and not yet associated with
  // the field or an existing file. Only time signedId is not present is when
  // it's still in the process of uploading.
  fileName
  signedId

  // When displaying previously saved files a `url` which points to the URL
  url

  // During upload the progress is tracked and an error might be set
  errorMessage
  loaded
  total

  // Flag for if the user has marked for delete
  pendingDelete = false

  // Constructor generally assumes it's working with an existing file so it
  // should have a all three arguments. But in the case of a new file only
  // the filename will be initially known. The signed_id is added once the
  // upload is complete.
  constructor({ filename, signed_id, url }) {
    this.fileName = filename
    this.signedId = signed_id
    this.url = url
  }

  isAttachable() {
    return this.fileName && this.signedId && !this.pendingDelete
  }

  isUploading() {
    // If no info about how much loaded or total then query indeterminate
    if( this._progressUnknown() ) return

    // If there was an error we are no longer uploading
    if( this.errorMessage ) return false

    return this.loaded < this.total
  }

  percent() { return Math.floor((this.loaded / this.total) * 100) }

  // ActiveStorage callback DirectUpload will call to gain access to the XHR
  // object doing the upload. Use this to keep the progress up-to-date.
  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event => {
      const { loaded, total } = event
      this.loaded = loaded
      this.total = total
    })
  }

  _progressUnknown() {
    return !Number.isInteger(this.loaded) || !Number.isInteger(this.total)
  }
}
