Covers
  @srs_1.1 @srs_15.3 @srs_15.4 @srs_15.5 @srs_15.6 @srs_22.1 @srs_24.1

Internal component used by the index/filters/*.vue files for common boilerplate
to style each filter option. Not meant to be used directly.

Sends `namespace` param to scoped slot to allow any HTML control to be bound
to the ID assigned to the label.

<template>
  <div class="col" :data-label="label">
    <field :label="label" :hint="hint">
      <slot />
    </field>
  </div>
</template>

<script>
import Field from 'bootstrap/field'

export default {
  props: ['label', 'hint'],
  components: { Field },
}
</script>
