Covers
  @srs_20.5 @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

Component to edit source code.

Currently only supports javascript although more could be added later.
`esbuild.config.js` will need to be updated if other languages (or themes) are
later supported so they are included with the bundle.

<template>
  <div>
    <div ref="container"
      :class="`language-${language} border text-monospace`"
      :style="`height: ${height}; padding: 0.25rem`" />
  </div>
</template>

<script>
import { CodeJar } from 'codejar'
import { withLineNumbers } from 'codejar-linenumbers'
import 'codejar-linenumbers/es/codejar-linenumbers.css'
import Prism from 'prismjs'
import 'prismjs/themes/prism'

Prism.manual = true

export default {
  props: {
    modelValue: {},
    height: { default: '15rem' },
    language: { default: 'javascript' },
  },
  emits: ['update:modelValue'],
  mounted() {
    this.editor = CodeJar(
      this.$refs.container,
      withLineNumbers(Prism.highlightElement),
      { tab: '  ' }
    )
    this.editor.updateCode(this.modelValue)
    this.editor.onUpdate(updatedVal => this.$emit('update:modelValue', updatedVal))
  },
  watch: {
    modelValue() {
      if( this.editor.toString() != this.modelValue )
        this.editor.updateCode(this.modelValue)
    },
  },
  beforeUnmount() { this.editor.destroy() },
}
</script>

<style>
.codejar-wrap { tab-size: 2 }
.codejar-linenumbers { text-align: center }
</style>
