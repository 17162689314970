Covers
  @srs_7.5 @srs_20.6 @srs_21.4 @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

Reduces some of the bootstrap boilerplate when outputting a checkbox in Vue.
Can be generally treated like a `[type=checkbox]` tag only the it has a closing
tag and the content of the tag is the label.

It does not support operating on an array of values like a raw [type=checkbox].
In that case use ":checked", "@change" and ":value" attributes. But if an
independent boolean `v-model` can be used.

<template>
  <div class="custom-control custom-checkbox">
    <input type="checkbox" class="custom-control-input" v-bind="$attrs"
      :checked="modelValue || checked" @change="changed" :id="fieldId" />
    <label class="custom-control-label" :for="fieldId">
      <slot />
    </label>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  inheritAttrs: false,
  props: ['modelValue', 'checked'],
  emits: ['update:modelValue', 'change'],
  computed: {
    fieldId() { return `a${uuid()}` },
  },
  methods: {
    changed(event) {
      this.$emit('update:modelValue', event.target.checked)
      this.$emit('change', event.target.checked)
    }
  }
}
</script>
