Covers
  @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

Vue component for presenting an individual custom field schema option value for a UserDefinedField in the new and edit forms.

<template>
  <draggable>
    <div class="row">
      <div class="col sortable-handle">
        {{optionValue}}
      </div>

      <div class="col-auto">
        <delete-button @click="$emit('delete-option', optionValue)" />
      </div>
    </div>
  </draggable>
</template>

<script>
import { Draggable } from 'vue3-smooth-dnd'

import DeleteButton from 'bootstrap/delete_button'

export default {
  components: { DeleteButton, Draggable },
  props: ['optionValue'],
  emits: ['delete-option'],
}
</script>
