// Covers
//   @srs_1.1 @srs_5.1 @srs_6.1 @srs_15.3 @srs_15.4 @srs_20.2 @srs_22.1

/*
 * Based on the stacked-table code in:
 * https://github.com/Hemasystems/bloodhub_core/blob/master/app/assets/javascripts/lib/arrive_events/other_uis.js.coffee
 *
 * Isolated just the stacked-table portion. Refactored as JS from CoffeeScript.
 * Converted to using plain DOM API rather than going through jQuery abstraction
 *
 * Goal is to annotate table data cells with the header value from the header
 * so the stacked table can display the label next to each value on small
 * screens.
 */

// Side effects that install arrive on globalobjects
import 'arrive'

function titles_for(table) {
  // Cache on DOM for performance on a lot of rows
  if( table.titles ) return table.titles

  const header_cells = table.querySelectorAll('thead:first-of-type th, thead:first-of-type td')
  table.titles = []
  header_cells.forEach(cell => {
    const colspan = parseInt(cell.getAttribute('colspan') || 1)
    table.titles.push(cell.textContent.trim())
    for(let i=1; i < colspan; i++)
      table.titles.push('')
  })

  return table.titles
}

const class_names = [
  'table-stacked',
  'table-stacked-xs',
  'table-stacked-sm',
  'table-stacked-md',
  'table-stacked-lg',
]

const selector = class_names.map(class_name => [
  `.${class_name} table > tbody > tr > td`,
  `table.${class_name} > tbody > tr > td`,
  `.${class_name} table > tbody > tr > th`,
  `table.${class_name} > tbody > tr > th`,
].join(',')).join(',')

document.arrive(selector, {existing: true}, (element)=> {
  if( element.dataset.th ) return
  const index = Array.from(element.parentNode.children).indexOf(element);
  element.dataset.th = titles_for(element.closest('table'))[index]
})
