// Covers
//   @srs_20.5

document.addEventListener('click', evt => {
  const button = evt.target
  if( !button.matches('.report-actions [data-event]') ) return

  const config = button.dataset
  const event = config.event
  const nextState = config.nextState
  const suggestedReportStatus = config.suggestedReportStatus
  const suggestedDisclaimer = config.suggestedDisclaimer
  const remote = config.formRemote

  const modal = document.querySelector('#report-status-modal')
  const form = modal.querySelector('form')
  const eventFld = modal.querySelector('.event')
  const nextLabel = modal.querySelector('.next-state')
  const reportStatus = modal.querySelector('[name="order[report_status]"]')
  const suggestedDisclaimerButton = Array.from(modal
    .querySelectorAll('[type=radio][name="order[disclaimer_html]"]'))
    .find(disclaimer => disclaimer.value == suggestedDisclaimer)

  form.dataset.turbo = remote
  eventFld.value = event
  nextLabel.innerText = nextState
  reportStatus.value = suggestedReportStatus
  if( suggestedDisclaimerButton ) suggestedDisclaimerButton.checked = true
})
