Covers
  @srs_1.3

Renders a flash message that can be bound to a specific container. This
shouldn't generally be used directly but instead called by the `notice` and
`alert` methods in `flash.js`

<template>
  <div class="alert" role="alert"
    :class="[`alert-${purpose}`, { 'alert-dismissable fade show': closable }]">

    <div class="media">
      <div v-if="icon" class="badge badge-circle badge mr-2" :class="`badge-${purpose}`">
        <component :is="icon" />
      </div>

      <div class="media-body"><slot /></div>
    </div>

    <button v-if="closable" type="button" class="btn close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">
        <close-icon />
      </span>
    </button>
  </div>
</template>

<script>
import CloseIcon from 'bootstrap-icons/icons/x.svg'

export default {
  props: ['purpose', 'icon', 'closable'],
  components: { CloseIcon },
}
</script>
