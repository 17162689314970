Covers
  @srs_1.1 @srs_15.5 @srs_15.6

Container used in listing of objects where each item is presented as a card

<template>
  <div class="card">
    <div class="card-body">
      <header class="card-body-header">
        <h4 class="tabbed-heading tabbed-heading-rounded">
          <slot name="title" />
        </h4>

        <slot name="controls" />
      </header>

      <slot />
    </div>
  </div>
</template>

<script>
export default {}
</script>
