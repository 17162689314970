// Covers
//   @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

// Based on https://stackoverflow.com/a/62976113
export default function(node, callback) {
  new MutationObserver((_mutations, observer) => {
    if( document.body.contains(node) ) return

    observer.disconnect()
    callback()
  }).observe(document, { subtree: true, childList: true })
}
