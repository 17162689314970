// Covers
//   @srs_14.8

// Side effects install on global objects
import 'arrive'

// Really wished `select` supported defaultValue
function originalValue(element) {
  if( typeof element.defaultValue !== 'undefined' )
    return element.defaultValue

  if( typeof element.options != 'undefined' ) {
    const options = Array.from(element.options)
    const defaultSelected = options.find(o => o.defaultSelected) || options[0]
    return defaultSelected.value
  }

  throw new Error('Input type not supported')
}

function addWarning(input) {
  input.classList.add('is-warning')

  const existingWarning =
    input.nextElementSibling &&
    input.nextElementSibling.matches('.warning-feedback')
  if( existingWarning ) return

  const message = input.dataset.changeWarning || 'Value changed'
  const messageContainer = document.createElement('div')
  messageContainer.classList.add('warning-feedback')
  messageContainer.innerText = message
  input.after(messageContainer)
}

document.arrive('[data-change-warning][data-previous-value]', { existing: true }, input => {
  if( input.dataset.previousValue == '' ) return

  addWarning(input)
})

document.addEventListener('input', event => {
  const input = event.target
  if( !input.matches('[data-change-warning]') ) return

  // If original value was blank then don't consider it "changed"
  if( originalValue(input) == '' ) return

  addWarning(input)
}, true)
