Covers
  @srs_7.5 @srs_7.6 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

Must be provided `fieldName', and `fieldSchema'
And either `recordParamKey' or `inputName' if the caller is doing something custom (like the field list)

<template>
  <field v-if="fieldSchema" :data-type="fieldSchema.type" :data-labeled="fieldSchema.title" :data-original-readonly="readonly" class="user-defined-field-input">
    <previous-value-notice v-if="oldFieldSchema" :field-schema="fieldSchema" :old-field-schema="oldFieldSchema" />
    <label v-if="showLabel && fieldSchema.type != 'switch'" :for="labelId" class='control-label'>
      {{ fieldSchema.title }}
      <abbr v-if="flagToBoolean(fieldSchema.required)" title="required">*</abbr>
      <more-info-link :url="fieldSchema.more_information_url" />
    </label>
    <component :is="fieldComponentName"
      v-model="fieldSchema.value"
      :aria-label="showLabel ? null : fieldSchema.title"
      :name="inputName"
      :id="labelId"
      :disabled="readonly"
      :option-values="fieldSchema.option_values"
      @update:modelValue="triggerHook('input')">
      {{ fieldSchema.title }}
    </component>
    <help-text :hint="fieldSchema.hint" />
  </field>
</template>

<script>
import { v4 as uuid } from 'uuid'

import fieldHook from 'hooks'
import { addAsyncSubmitListener, removeAsyncSubmitListener } from 'add_async_submit_listener'

// Components
import Field from 'bootstrap/field'

import HelpText from './common/help_text'
import MoreInfoLink from './common/more_info_link'
import PreviousValueNotice from './common/previous_value_notice'

import CheckboxesField from './types/checkboxes'
import DateField from 'bootstrap/date_input'
import MultiSelectListField from './types/multi_select_list'
import NumberField from './types/number_input'
import OneLineField from './types/text_input'
import RadioButtonsField from './types/radio_buttons'
import SelectListField from './types/select_list'
import SwitchField from 'bootstrap/toggle_switch'
import TextBoxField from './types/text_box'
import TimeField from './types/time_input'
import FileField from './types/file_input'
import YesNoField from './types/yes_no'

export default {
  props: {
    'fieldSchema': {},
    'oldFieldSchema': {},
    'fieldName': {},
    'recordParamKey': {},
    'showLabel': { default: true },
    "inputName": {
      default(props) {
        return [props.recordParamKey,`[${props.fieldName}_value]`].join('')
      }
    },
    'readonly': { default: false },
  },
  created() {
    if(this.fieldSchema) {
      if(['checkboxes', 'multi_select_list'].includes(this.fieldSchema.type)) {
        this.fieldSchema.value = this.fieldSchema.value || []
      } else if(this.fieldSchema.type == 'file') {
        this.fieldSchema.value = this.fieldSchema.value || { filename: undefined, signed_id: undefined }
      }
    }

    this.boundTriggerSubmitHook = this.triggerSubmitHook.bind(this)
  },
  mounted() {
    this.triggerHook('load')
    addAsyncSubmitListener(this.form, this.boundTriggerSubmitHook)
  },
  beforeUnmount() {
    removeAsyncSubmitListener(this.form, this.boundTriggerSubmitHook)
  },
  computed: {
    fieldComponentName() {
      if(!this.fieldSchema) { return '' }

      return `${this.fieldSchema.type.split('_').map(function(str) {
        return str[0].toUpperCase() + str.slice(1).toLowerCase()
      }).join('')}Field`

    },
    labelId() { return `a${uuid()}` },
    form() { if( this.$el.closest ) return this.$el.closest('form') },
  },
  methods: {
    flagToBoolean(value) { return value === true || value === 'true' },

    triggerHook(event) {
      if( !this.fieldSchema ) return

      return fieldHook(this.fieldSchema.hook, this.labelId, event)
    },

    async triggerSubmitHook(event) {
      const submittingEvent = new CustomEvent('hook:submit', { cancelable: true, bubbles: true })
      this.$el.dispatchEvent(submittingEvent)

      await this.triggerHook('submit')

      // If an earlier hook determined we should cancel the submit reset that
      // and let this later hook make that determination since things may have
      // changed on the form due to the above hook running.
      event.reset()

      const cancelSubmit = !!this.form.querySelector('.user-defined-field-input .invalid-feedback')
      if( cancelSubmit ) {
        event.preventDefault()

        // Must use immediate since async listeners happen on the document
        // element during the capture phase and that is also the same place
        // that bootstrap makes HTML5 validation issues visible which we want to
        // not do if there is an hook error.
        event.stopImmediatePropagation()

        const cancelEvent = new CustomEvent('hook:cancel-submit', { cancelable: true, bubbles: true })
        this.$el.dispatchEvent(cancelEvent)
      }
    },
  },
  components: {
    Field, HelpText, MoreInfoLink, CheckboxesField, DateField, MultiSelectListField,
    NumberField, OneLineField, RadioButtonsField, SelectListField, SwitchField, TextBoxField,
    TimeField, FileField, YesNoField, PreviousValueNotice
  },
}
</script>
