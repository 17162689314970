// Covers:
//   @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4 @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

import { v4 as uuid } from 'uuid'

export default class {
  constructor(attributes={}) {
    this.reset()
    if(!attributes.id) {
      attributes.id = uuid()
    }
    Object.assign(this, attributes)
  }

  payload() {
    let { id, value, title, abbr_title, hint, code, more_information_url, required, searchable, type, option_values, list_id, visibility, hook } = this
    return { id, value, title, abbr_title, hint, code, more_information_url, required, searchable, type, option_values, list_id, visibility, hook }
  }

  disableFlag(flagName) {
    this[flagName] = null
  }

  enableFlag(flagName) {
    if(this[flagName] === null) {
      this[flagName] = false
    }
  }

  requiresOptionValues() { return false }

  displayValue() {
    if( !this.value )
      return ''
    else if(Array.isArray(this.value))
      return this.value.join(", ")
    else
      return String(this.value)
  }

  reset() {
    this.id = null
    this.value = null
    this.title = null
    this.abbr_title = null
    this.hint = null
    this.code = null
    this.more_information_url = null
    this.required = null  // init to false in sub-class if it can be required eg. switches cannot be required
    this.searchable = null // init to false in sub-class if it can be searchable eg. switches cannot be searched
    this.type = null
    this.option_values = null
    this.list_id = null
    this.visibility = []
    this.hook = null
  }
}
