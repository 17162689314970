Covers
  @srs_5.1

Root component for the customer admin search screen. Displays list of customers.
Provides search options to filter list.

<template>
  <div>
    <filters>
      <search v-model="query.search">
        <ul class="list-unstyled">
          <li>Name</li>
          <li>Organization</li>
        </ul>
      </search>

      <options-filter label="Status"
        v-model="query.active" :options="options" />
    </filters>

    <results initial-uri="customers" :query="query" ref="results">
      <template #default="{ rows }">
        <stacked-table class="results-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Organization</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <item v-for="row in rows" :key="row.id" v-bind="row" @refresh="refresh" />
          </tbody>
        </stacked-table>
      </template>
    </results>
  </div>
</template>

<script>
import StackedTable from 'stacked_table/component'
import Filters from 'index/filters'
import Search from 'index/filter/search'
import OptionsFilter from 'index/filter/options'
import Results from 'index/results'
import QueryPersistence from 'index/query_persistence'
import Item from './item'

export default {
  mixins: [QueryPersistence],
  components: { Search, Filters, OptionsFilter, Results, StackedTable, Item },
  data() {
    return {
      query: this.fromUrlWithDefaults({
        search: '',
        active: 'true',
      })
    }
  },
  computed: {
    options() {
      return [
        ['Active', 'true'],
        ['Inactive', 'false'],
      ]
    },
  },
  methods: {
    refresh() { this.$refs.results.load() }
  },
}
</script>
