Covers
  @srs_1.5 @srs_7.5 @srs_20.6 @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

Reduces some of the bootstrap boilerplate when outputting a radio option in Vue.
Can for the most part treat it as a regular input[type=radio] element except
it has a closing take and the content is the label.

<template>
  <div class="custom-control custom-radio">
    <input type="radio" class="custom-control-input" :id="fieldId" v-bind="$attrs" />
    <label class="custom-control-label" :for="fieldId">
      <slot />
    </label>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  inheritAttrs: false,
  computed: {
    fieldId() { return `a${uuid()}` },
  },
}
</script>
