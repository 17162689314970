// Covers
//   @srs_8.1 @srs_9.1 @srs_12.1

import api from 'api'

/*
 * Object to construct sending a transaction to the server. Designed to be
 * generic enough to handle any transaction type. Set `type` attribute to
 * indicate transaction type. Any other attributes set on the object will be
 * sent as attributes to the root transaction datastructure.
 */
export default class {
  async send() {
    const {type, id, ...data} = Object.assign({}, this)

    let path, method
    if( id ) {
      path = `${type}/transactions/${id}`
      method = 'PATCH'
    } else {
      path = `${type}/transactions`
      method = 'POST'
    }
    data.status = 'Ready'

    const [, body] = await api(path, { method, body: data })
    return body
  }
}
