Covers
  @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

Root component for the user_defined_function admin search screen. Displays list of user_defined_fields.
Provides search options to filter list.

<template>
  <div>
    <results initial-uri="user_defined_fields" :query="query" ref="results">
      <template #default="{ rows }">
        <stacked-table class="results-table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col"></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="row in rows" :key="row.id">
              <td>
                {{ row.display_name }}
              </td>
              <td class="min-width">
                <view-button :href="`user_defined_fields/${row.id}/edit`" />
              </td>
            </tr>
          </tbody>
        </stacked-table>
      </template>
    </results>
  </div>
</template>

<script>
import StackedTable from 'stacked_table/component'
import ViewButton from 'bootstrap/view_button'
import Results from 'index/results'

export default {
  components: {
    Results, ViewButton, StackedTable
  },
  data() {
    return {
      query: { }
    }
  },
}
</script>
