Covers
  @srs_1.1 @srs_5.1 @srs_6.1 @srs_15.3 @srs_15.4 @srs_15.5 @srs_15.6 @srs_22.1 @srs_24.1

Simple "next" and "previous" navigation elements. When clicked emit a `navigate`
event with the URL of the page chosen. If there is no URL for a direction the
element is hidden. Example Usage:

  <pagination previousUri="/transactions?page=2" nextUri="/transactions?page=4" />

<template>
  <div class="row justify-content-end">
    <div class="col-auto">
      <nav v-if="previousUri || nextUri" aria-label="Page navigation">
        <ul class="pagination">
          <fade>
            <li v-show="previousUri" class="page-item previous">
              <a @click.prevent.stop="$emit('navigate', previousUri)" class="page-link" href="#">
                Previous
              </a>
            </li>
          </fade>

          <fade>
            <li v-show="nextUri" class="page-item next">
              <a @click.prevent.stop="$emit('navigate', nextUri)" class="page-link" href="#">
                Next
              </a>
            </li>
          </fade>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import Fade from 'bootstrap/fade'

export default {
  props: ['previousUri', 'nextUri'],
  emits: ['navigate'],
  components: { Fade }
}
</script>
