Covers
  @srs_7.5 @srs_7.6 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

  Renders a number input for a UDF/custom field. Required `fieldSchema' and `name'

<template>
  <input type="number" class="form-control" v-bind="$attrs"
    :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
</template>

<script>
export default {
  props: ['modelValue'],
  emits: ['update:modelValue'],
}
</script>
