Covers
  @srs_20.1 @srs_20.2

Displays ok/cancel confirmation notice in a toast notification.

Useful for prompting a user for optional notifications. Somewhat of a cross
between window.confirm but less modal. More like a
snackbar (https://material.io/components/snackbars#anatomy) with actions.

<template>
  <toast :closable="true">
    <slot />

    <template #footer>
      <styled-button priority="secondary" data-dismiss="toast">Cancel</styled-button>
      <styled-button priority="primary" @click="$emit('approve')" data-dismiss="toast">OK</styled-button>
    </template>
  </toast>
</template>

<script>
import Toast from './toast'
import StyledButton from './styled_button'

export default {
  emits: ['approve'],
  components: { Toast, StyledButton },
}
</script>
