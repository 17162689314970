import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "topbar-impersonate-results"
}
const _hoisted_2 = { class: "topbar-impersonate-list" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-auto ml-auto mt-3" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_result = _resolveComponent("result")
  const _component_styled_button = _resolveComponent("styled-button")
  const _component_collapse = _resolveComponent("collapse")
  const _component_overlay = _resolveComponent("overlay")

  return (_openBlock(), _createBlock(_component_overlay, {
    show: $data.loading,
    class: "m-sm-3"
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("input", {
        type: "search",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.search) = $event)),
        autocomplete: "off",
        class: "form-control",
        placeholder: "Search for an organization to impersonate"
      }, null, 512 /* NEED_PATCH */), [
        [_vModelText, $data.search]
      ]),
      _createVNode(_component_collapse, null, {
        default: _withCtx(() => [
          ($data.organizations.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.organizations, (organization) => {
                    return (_openBlock(), _createBlock(_component_result, {
                      modelValue: $data.selected,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.selected) = $event)),
                      organization: organization,
                      key: organization.id,
                      search: $data.search,
                      "default-organization-id": $data.defaultOrganizationId,
                      "onUpdate:defaultOrganizationId": _cache[2] || (_cache[2] = $event => (($data.defaultOrganizationId) = $event))
                    }, null, 8 /* PROPS */, ["modelValue", "organization", "search", "default-organization-id"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_styled_button, {
                      purpose: "primary",
                      icon: "box-arrow-up-right",
                      disabled: !$data.selected,
                      onClick: $options.impersonate,
                      class: "impersonate"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Impersonate ")
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["disabled", "onClick"])
                  ])
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["show"]))
}