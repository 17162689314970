Covers
  @srs_1.1

Component to allow a user to create a new API key under their own account.

<template>
  <div class="mt-5">
    <new-button tag="button" data-toggle="modal" data-target="#new-api-key">
      New App Credentials
    </new-button>

    <modal id="new-api-key" @submit="submit">
      <template #header>New App Credentials</template>

      <template #notification>
        <div class="alert alert-info">
          <i class="fa fa-info-circle icon-lg" />
          To create app credentials, you may be asked to login on submit.
        </div>
      </template>

      <div class="form-inline">
        <input type="text" placeholder="Name" class="form-control mr-sm-2"
          name="app_credential_name" v-model="name" />
        <styled-button purpose="primary" icon="check2" type="submit">Submit</styled-button>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from 'bootstrap/modal'
import StyledButton from 'bootstrap/styled_button'
import NewButton from 'bootstrap/new_button'

export default {
  props: [
    'redirectUri', 'clientId', 'domain', 'state', 'audience',

    // Has default impl that just assigns to `location.href` but for testing
    // purposes we use dependency injection so we can avoid a page change in
    // the test suite.
    'setHref'
  ],
  components: { Modal, StyledButton, NewButton },
  data: function() {
    return {
      name: null,
    }
  },
  methods: {
    submit: function() {
      const payload = {
        audience: this.audience,
        scope: 'sub offline_access',
        response_type: 'code',
        client_id: this.clientId,
        redirect_uri: this.redirectUri,
        state: this.state,
        device: this.name,
      }

      const url = new URL('/authorize', `https://${this.domain}`)
      Object.keys(payload).forEach(key => url.searchParams.append(key, payload[key]))

      const setHref = this.setHref || ((href) => { location.href = href })
      setHref(url.href)
    },
  }
}
</script>
