Covers
  @srs_1.1 @srs_6.1 @srs_15.3 @srs_15.4 @srs_15.5 @srs_15.6 @srs_16.1 @srs_22.1 @srs_24.1

Links to the detail page are all caried out via a "View" button. This component
ensures that common style consistency.

<template>
  <styled-button priority="primary" tag="a" :size="size">
    <slot>View</slot>
  </styled-button>
</template>

<script>
import StyledButton from 'bootstrap/styled_button'

export default {
  props: {
    size: {},
  },
  components: { StyledButton },
}
</script>
