// Covers
//   @srs_21.1 @srs_21.2

/*
 * Unobtrustive JS that helps with cascading fields (where the value one field
 * affects the visibility of another field). The dependent field would indicate
 * what field it is dependent on by setting the data attribute
 * `data-conditional-field` with a selector that describes the field. It would
 * also use the `data-conditional-value` to indicate what values triggers it's
 * display. The value can be a single value or an serialized array of possible
 * values.
 */

// Side effects that install arrive on global objects
import 'arrive'

import $ from 'jquery'

document.arrive('[data-conditional-field]', { existing: true }, function(container) {
  const field = document.querySelector(container.dataset.conditionalField)
  let values = container.dataset.conditionalValue
  try {
    values = JSON.parse(values)
  } catch {
    // May not be JSON which is ok
  }

  // Not JSON array, convert to single value array so we can treat the same
  if( !Array.isArray(values) ) values = [values]

  function isShown() {
    return field.checked || values.includes(field.value)
  }

  function toggle() {
    if( isShown() )
      $(container).collapse('show')
    else
      $(container).collapse('hide')
  }

  container.classList.add('collapse')
  field.addEventListener('change', toggle)

  if( isShown() ) container.classList.add('show')
})
