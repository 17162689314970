// Covers
//   @srs_7.5 @srs_20.5 @srs_20.6 @srs_21.4

document.addEventListener('submit', (event)=> {
  const form = event.target

  form.querySelectorAll('trix-editor[required]').forEach((editor)=> {
    const hiddenInput = document.getElementById(editor.getAttribute('input'))
    const container = editor.closest('.rich-text')
    const formGroup = editor.closest('.form-group')
    const invalid = hiddenInput.value == ''

    let validationContainer = formGroup.querySelector('.invalid-feedback')
    if( !validationContainer ) {
      validationContainer = document.createElement('div')
      validationContainer.classList.add('invalid-feedback')
      formGroup.appendChild(validationContainer)
    }

    container.classList.toggle('is-invalid', invalid)
    editor.classList.toggle('is-invalid', invalid)
    if( invalid ) {
      validationContainer.innerText = "Can't be blank"
      event.stopImmediatePropagation()
      event.preventDefault()
    } else {
      validationContainer.innerText = ''
    }
  })

  form.classList.add('was-validated')
}, true)
