Covers
  @srs_15.3

Abstraction common transaction table UI

<template>
  <stacked-table class="results-table">
    <thead>
      <tr>
        <th>Transaction Num</th>
        <slot name="additional-headers" />
        <th class="text-right">Total Items</th>
        <th>Last Mod Date</th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="row in rows" :key="row.id">
        <td>{{ row.id }}</td>
        <slot :row="row" />
        <td class="text-right">{{ row.total_items }}</td>
        <td>{{ formattedUpdatedAt(row) }}</td>
        <td class="min-width">
          <view-button v-if="isLinked(row)" :href="linkUrl(row)" />
        </td>
      </tr>
    </tbody>
  </stacked-table>
</template>

<script>
import ViewButton from 'bootstrap/view_button'
import StackedTable from 'stacked_table/component'
import { formatTimestamp } from 'date_time_format'

export default {
  props: ['rows', 'type'],
  methods: {
    isLinked(row) { return this.linkUrl(row) !== undefined },
    linkUrl(row) {
      const baseUrl = `/${this.type}/transactions/${row.id}`
      switch( row.status ) {
        case 'Draft':
          return `${baseUrl}/edit`
        case 'Completed':
          return baseUrl
      }
    },

    formattedUpdatedAt(row) {
      return formatTimestamp(row.updated_at)
    },
  },

  components: { StackedTable, ViewButton }
}
</script>
