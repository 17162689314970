import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["data-toggle", "title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_styled_button = _resolveComponent("styled-button")

  return ($options.disabledTitle)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: "d-inline-block",
        "data-toggle": $options.toggle,
        title: $props.title
      }, [
        _createVNode(_component_styled_button, _mergeProps($options.disabledTitleAttrs, {
          type: "button",
          disabled: true,
          style: {"pointer-events":"none"}
        }), {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3 /* FORWARDED */
        }, 16 /* FULL_PROPS */)
      ], 8 /* PROPS */, _hoisted_1))
    : (_openBlock(), _createBlock(_resolveDynamicComponent($options.normalizedTag), _mergeProps({
        key: 1,
        href: $options.normalizedHref,
        class: "btn",
        type: $options.buttonType
      }, _ctx.$attrs, {
        disabled: $props.disabled ? 'disabled' : null,
        "data-toggle": $options.toggle,
        title: $props.title,
        onClick: $options.click,
        class: [$options.buttonClasses, $options.sizeButtonClasses, $options.iconButtonClasses]
      }), {
        default: _withCtx(() => [
          _createElementVNode("span", null, [
            ($props.icon)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.iconComponent), {
                  key: 0,
                  class: _normalizeClass({ 'mr-1': $props.link })
                }, null, 8 /* PROPS */, ["class"]))
              : _createCommentVNode("v-if", true)
          ]),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3 /* FORWARDED */
      }, 16 /* FULL_PROPS */, ["href", "type", "disabled", "data-toggle", "title", "onClick", "class"]))
}