// Covers
//   @srs_18.2

const lockSets = {}

// Just a collection of lock versions indexed by scope. The lockset should
// always be cleared before a hook is run.
class LockSet {
  constructor() { this.clear() }
  get(scope) { return this.locks[scope] }
  set(scope, lockVersion) { this.locks[scope] = lockVersion }
  clear() { this.locks = {} }
}

// Locksets are indexed by id. The exported function returns the lockset
// associated with the given ID.
export default function(id) { return lockSets[id] ??= new LockSet() }
