Covers
  @srs_22.1

Each test has results. This generally consists of a intepretation and/or grid.
This component allows those things to be configured.

<template>
  <div>
    <grid :form-scope="formScope" :record-schema="dRecordSchema"
      :record-labels="dRecordLabels" :dynamic="dDynamic" :new-record="newRecord"
      :option-lists="optionLists" @grid-interpreted="gridInterpreted = $event" />

    <div v-if="!gridInterpreted" class="interpretation">
      <h3>Interpretation</h3>
      <input type="hidden" :name="`${formScope}[interpretation_schema_json]`" :value="JSON.stringify(this.dInterpretationSchema)" />

      <single-field-schema
        v-model:field-schema="dInterpretationSchema"
        :visibilityTypesAllowed="['Summary', 'Report']"
        :option-lists="optionLists"
        @delete-schema="clearInterpretation"
        @init-schema="newInterpretation" />
    </div>
  </div>
</template>

<script>
import SingleFieldSchema from 'custom_field_schema/edit_field_ui/single_field_schema'
import BaseField from 'custom_field_schema/field_types/base'
import Grid from 'admin/test_types/grid'

export default {
  props: [
    'formScope',
    'recordSchema',
    'interpretationSchema',
    'recordLabels',
    'dynamic',
    'newRecord',
    'optionLists',
  ],
  components: { SingleFieldSchema, Grid },
  data() {
    return {
      dInterpretationSchema: this.interpretationSchema,
      dRecordLabels: this.recordLabels || [],
      dDynamic: this.dynamic,
      dRecordSchema: this.recordSchema || [],
      gridInterpreted: false
    }
  },
  methods: {
    newInterpretation() {
      const field = new BaseField({
        // User can change any of these values but let's provide good defaults
        title: 'Interpretation',
        visibility: ['Summary', 'Report'],
        required: true,
      })
      this.dInterpretationSchema = field.payload()
    },
    clearInterpretation() { this.dInterpretationSchema = null },
  }
}
</script>
