import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-6" }
const _hoisted_3 = { class: "col-sm-6" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  key: 0,
  class: "col-sm-4"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_customer = _resolveComponent("customer")
  const _component_field = _resolveComponent("field")
  const _component_list = _resolveComponent("list")
  const _component_ship_summary = _resolveComponent("ship-summary")
  const _component_layout = _resolveComponent("layout")

  return (_openBlock(), _createBlock(_component_layout, {
    "is-submittable": _ctx.isSubmittable,
    onSubmit: $options.send
  }, {
    params: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_customer, {
            label: "Ship to Customer",
            modelValue: _ctx.customerId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.customerId) = $event)),
            customers: $props.customers
          }, null, 8 /* PROPS */, ["modelValue", "customers"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_field, { label: "Order ID" }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.orderNum) = $event))
              }, null, 512 /* NEED_PATCH */), [
                [_vModelText, _ctx.orderNum]
              ])
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass({'col-sm-8': _ctx.summary, 'col-12': !_ctx.summary})
        }, [
          _createVNode(_component_list, {
            items: _ctx.items,
            "transaction-type": "ship",
            onNextItem: _cache[2] || (_cache[2] = $event => (_ctx.nextItem())),
            onDeleteItem: _cache[3] || (_cache[3] = $event => (_ctx.deleteItem($event)))
          }, null, 8 /* PROPS */, ["items"]),
          _createVNode(_component_field, null, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.comment) = $event)),
                placeholder: "Comment...",
                rows: "5"
              }, null, 512 /* NEED_PATCH */), [
                [_vModelText, _ctx.comment]
              ])
            ]),
            _: 1 /* STABLE */
          })
        ], 2 /* CLASS */),
        (_ctx.summary)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_ship_summary, { summary: _ctx.summary }, null, 8 /* PROPS */, ["summary"])
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-submittable", "onSubmit"]))
}