import { findFieldContainer } from './common'

// Make field read only if `readonly` is true
export default function(currentFieldId, scope, field, readonly) {
  const fld = findFieldContainer(currentFieldId, scope, field)

  // If was originally read only then this hook action is a no-op
  if( fld.dataset.originalReadonly == 'true' ) return

  fld.querySelectorAll('input, textarea').forEach(w => w.disabled = readonly)
}
