import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col sortable-handle" }
const _hoisted_3 = { class: "col-auto" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_delete_button = _resolveComponent("delete-button")
  const _component_draggable = _resolveComponent("draggable")

  return (_openBlock(), _createBlock(_component_draggable, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString($props.optionValue), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_delete_button, {
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('delete-option', $props.optionValue)))
          })
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}