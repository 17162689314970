import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_pdf_viewer = _resolveComponent("pdf-viewer")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_pdf_viewer, {
      href: $props.href,
      ref: "viewer"
    }, null, 8 /* PROPS */, ["href"])
  ]))
}