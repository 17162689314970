// Covers
//   @srs_7.5 @srs_20.5 @srs_20.6 @srs_21.4 @srs_23.1

/*
 * Initializes any markup using the following convention:
 * - The container for the sortable items is has class `.sortable`
 * - The thing the user can hold to drag the items has '.sortable-handle'
 *
 * Will automatically dispose of the list once the markup is removed from the
 * page.
 */

 // Side effects install on global objects
import 'arrive'

import api from 'api'
import onNodeRemove from 'on_node_remove'
import { smoothDnD, dropHandlers } from 'smooth-dnd'

document.arrive('.sortable', { existing: true }, container =>  {
  const handle = smoothDnD(container, {
    dragHandleSelector: '.sortable-handle',
    // The next two work in concert with our fork of smooth-dnd
    // so we can use the basic smooth-dnd functionality alongside
    // the vue-smooth-dnd package.
    wrapChild: true,
    dropHandler: dropHandlers.domDropHandler,
    onDrop: (_) => {
      // Return early if the sortable doesn't want us to communicate sort changes
      if( !container.dataset.model ) return

      const ids = [...container.querySelectorAll('[data-id]' )].map(e => e.dataset.id)
      api(`${container.dataset.model}/position`, { method: 'PATCH', body: { ids } })
    },
    // Use the child payload to translate the DnD index position to the
    // sortable-handle element being modified.
    getChildPayload: (index) => {
      // Each sortable-handle element should have a data-id atttribute directly or
      // at least a one-to-one relationship to a another element that will move around with
      // the sortable handle and have a data-id attribute.
      return container.querySelectorAll('[data-id]')[index]
    },
  })
  onNodeRemove(container, ()=> handle.dispose())
})
