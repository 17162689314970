Covers
  @srs_20.2

Top-level component to manage the list of products requested for an order.
Serializes all data to a hidden field to allow traditional form submissions.

<template>
  <div class="product-request">
    <input type="hidden" :name="fieldName" :value="productsJson" />

    <stacked-table>
      <thead><tr>
        <th>QTY</th>
        <th>Type</th>
        <th>Product Category</th>
      </tr></thead>

      <tbody>
        <tr v-for="product in products">
          <td class="min-width">
            <field>
              <input type="number" min="0" max="999" v-model="product.quantity" :disabled="readonly" />
            </field>
          </td>
          <td class="min-width">
            <field>
              <select v-model="product.type" :disabled="readonly">
                <option v-for="type in bloodTypes">{{ type }}</option>
              </select>
            </field>
          </td>
          <td>
            <field>
              <input type="text" v-model="product.category" :disabled="readonly" />
            </field>
          </td>
        </tr>
      </tbody>
    </stacked-table>

    <new-button v-if="!readonly" tag="button" @click="addBlank">Add Product</new-button>
  </div>
</template>

<style scoped>
input[type=number], select { width: 5rem }
</style>

<script>
import StackedTable from 'stacked_table/component'
import NewButton from 'bootstrap/new_button'
import Field from 'bootstrap/field'

export default {
  props: ['fieldName', 'bloodTypes', 'value', 'readonly'],
  components: { StackedTable, NewButton, Field },
  data() {
    return {
      products: this.value || [],
    }
  },
  methods: {
    addBlank() {
      this.products.push({ quantity: '', type: '', category: ''})
    },
  },
  computed: {
    productsJson() { return JSON.stringify(this.products) },
  },
}
</script>
