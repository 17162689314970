Covers
  @srs_7.5 @srs_7.6 @srs_20.6 @srs_21.1 @srs_21.2 @srs_2.3 @srs_21.4

  Renders radio buttons with fixed Yes/No choices. Requires `fieldSchema' and `name'

<template>
  <div class="yes-no-field form-row field-group" :id="id">
    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" class="custom-control-input" :id="yesFieldId"
        value="true" v-model="yesNoValue" @change="$emit('update:modelValue', 'true')"
        v-bind="$attrs" />
      <label class="custom-control-label" :for="yesFieldId">Yes</label>
    </div>
    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" class="custom-control-input" :id="noFieldId"
        value="false" v-model="yesNoValue" @change="$emit('update:modelValue', 'false')"
        v-bind="$attrs" />
      <label class="custom-control-label" :for="noFieldId">No</label>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  inheritAttrs: false,
  props: ['id', 'modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      yesNoValue: this.modelValue,
    }
  },
  computed: {
    yesFieldId() { return `a${uuid()}` },
    noFieldId() { return `b${uuid()}` },
  },
}
</script>
