Covers
  @srs_7.5 @srs_7.6 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

  Renders a select list for a UDF/custom field.

<template>
  <select class="custom-select" v-bind="$attrs"
    @change="$emit('update:modelValue', $event.target.value)">
    <option :selected="defaultSelected" value>-- Blank --</option>
    <option v-for="optionValue in optionValues"
      :value="optionValue" :selected="isSelected(optionValue)">
      {{ optionValue }}
    </option>
  </select>
</template>

<script>
export default {
  props: ['optionValues', 'modelValue'],
  emits: ['update:modelValue'],
  computed: {
    defaultSelected() {
      return !this.modelValue || !this.modelValue.trim()
    }
  },
  methods: {
    isSelected(optionValue) {
      return optionValue === this.modelValue
    }
  }
}
</script>
