// Covers
//   @srs_7.5 @srs_21.4

import { findFieldContainer } from './common'
import getFieldValue from './get_value'

// Similar to calling `getFieldValue` on each cell in the grid table.
// Currently this assumes the current grid that the hook is attached to is
// the data we are retrieving.
//
// The main advantage in providing this special action over looping through the
// fields calling getFieldValue for each one is simplification. Since
// `getFieldValue` is async (within a hook) this remove a lot of complexity for
// the hook. Especially if doing an array operation as the JS array operations
// get complex if the callback becomes async.
//
// This overall function is still async but it is a single call to get all the
// fields as a data-structure. From there the hook can operate on that data
// structure without dealing a bunch of async functions.
export default function(currentFieldId, callback) {
  const grid = findFieldContainer(currentFieldId, 'panel', currentFieldId).closest('form')

  const results = Array.from(grid.querySelectorAll('tbody tr')).reduce((data, row, idx)=> {
    let key

    const dynamic = row.querySelector('td:first-of-type .form-group')
    if( dynamic )
      key = idx
    else
      key = row.querySelector('td:first-of-type').innerText

    data[key] = Array.from(row.querySelectorAll('td .form-group')).reduce((row, cell) => {
      getFieldValue(currentFieldId, 'panel', cell, v => row[cell.dataset.labeled] = v)
      return row
    }, {})

    return data
  }, {})

  callback(results)
}
