Covers
  @srs_22.1 @srs_24.1

Root component for management of a list. Given a specific list id will display
the items in that list and provide search options to filter that list.

<template>
  <div>
    <filters class="mb-0 rounded-0 border-top-0">
      <search v-model="query.search">
        <ul class="list-unstyled">
          <li>Name</li>
        </ul>
      </search>
    </filters>

    <results :initial-uri="`lists/${listId}/items`" :query="query" ref="results">
      <template #default="{ rows }">
        <ul class="list-group list-group-flush">
          <li v-for="row in rows" :key="row.id" class="list-group-item d-flex align-items-center py-1">
            {{ row.name }}

            <view-button :href="`items/${row.id}/edit`" class="ml-auto" size="sm" />
            <delete-button @click="remove(row.id)" size="" />
          </li>
        </ul>
      </template>
    </results>
  </div>
</template>

<script>
import api from 'api'
import ViewButton from 'bootstrap/view_button'
import DeleteButton from 'bootstrap/delete_button'
import Filters from 'index/filters'
import Search from 'index/filter/search'
import Results from 'index/results'
import QueryPersistence from 'index/query_persistence'

import schemasToUpdate from 'admin/user_defined_fields/schemas_to_update'

export default {
  mixins: [QueryPersistence],
  props: {
    listId: {},
    classesToUpdateSchema: { default: [] },
  },
  components: {
    Search, Filters, Results,
    ViewButton, DeleteButton
  },
  data() {
    return {
      query: this.fromUrlWithDefaults({
        search: '',
      })
    }
  },
  methods: {
    async remove(item_id) {
      await api(`lists/${this.listId}/items/${item_id}`, { method: 'DELETE' })

      if( this.classesToUpdateSchema.length > 0 )
        this.classesToUpdateSchema.forEach(c => schemasToUpdate.add(c))

      this.$refs.results.load()
    },
  },
}
</script>
