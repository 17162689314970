// Covers
//   @srs_7.1 @srs_8.1 @srs_9.1 @srs_12.1 @srs_20.1 @srs_20.2

import Base from './base'

export default class extends Base {
  static type = 'unit_number'
  static pattern = /=W\d{12}[A-Za-z0-9]{2}/i

  normalizedInput() {
    return super.normalizedInput().replace(/^=/, '').replace(/[A-Za-z0-9]{2}$/, '')
  }
}
