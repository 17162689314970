Covers
  @srs_22.1

Root component for the test type admin search screen. Displays list of test
types. Provides search options to filter list.

<template>
  <div>
    <filters>
      <search v-model="query.search">
        <ul class="list-unstyled">
          <li>Name</li>
          <li>Organization</li>
        </ul>
      </search>
      <options-filter label="Status"
        v-model="query.active_eq" :options="options" />
    </filters>

    <results initial-uri="test_types" :query="query" ref="results">
      <template #default="{ rows }">
        <stacked-table class="results-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Organization</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="row in rows" :key="row.id">
              <td>{{ row.name }}</td>
              <td>{{ row.organization_name }}</td>
              <td class="min-width">
                <view-button :href="`test_types/${row.id}/edit`" />
                <delete-button v-if="row.active" @click="remove(row.id)" />
                <restore-button v-else @click="restore(row.id)" />
              </td>
            </tr>
          </tbody>
        </stacked-table>
      </template>
    </results>
  </div>
</template>

<script>
import api from 'api'
import StackedTable from 'stacked_table/component'
import ViewButton from 'bootstrap/view_button'
import DeleteButton from 'bootstrap/delete_button'
import RestoreButton from 'bootstrap/restore_button'
import Filters from 'index/filters'
import Search from 'index/filter/search'
import OptionsFilter from 'index/filter/options'
import Results from 'index/results'
import QueryPersistence from 'index/query_persistence'

export default {
  mixins: [QueryPersistence],
  components: {
    Search, Filters, OptionsFilter, Results,
    ViewButton, DeleteButton, RestoreButton, StackedTable
  },
  data() {
    return {
      query: this.fromUrlWithDefaults({
        search: '',
        active_eq: 'true',
      })
    }
  },
  computed: {
    options() {
      return [
        ['Active', 'true'],
        ['Inactive', 'false'],
      ]
    }
  },
  methods: {
    async remove(user_id) {
      await api(`test_types/${user_id}`, { method: 'DELETE' })
      this.$refs.results.load()
    },

    async restore(user_id) {
      await api(`test_types/${user_id}/restore`, { method: 'PATCH' })
      this.$refs.results.load()
    },
  },
}
</script>
