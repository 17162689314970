import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_options_filter = _resolveComponent("options-filter")
  const _component_filters = _resolveComponent("filters")
  const _component_audit = _resolveComponent("audit")
  const _component_results = _resolveComponent("results")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_filters, null, {
      default: _withCtx(() => [
        _createVNode(_component_options_filter, {
          label: "Fields Changed",
          multiple: "true",
          modelValue: $data.query.fields,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.query.fields) = $event)),
          options: $props.searchFields
        }, null, 8 /* PROPS */, ["modelValue", "options"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_results, {
      "initial-uri": `${this.auditableType}/${this.auditableId}/audits`,
      query: $data.query
    }, {
      default: _withCtx(({ rows }) => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rows, (audit) => {
          return (_openBlock(), _createBlock(_component_audit, {
            key: audit.id,
            audit: audit,
            "owner-type": $props.ownerType
          }, null, 8 /* PROPS */, ["audit", "owner-type"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["initial-uri", "query"])
  ]))
}