Covers:
  @srs_9.1

Binds together the location and reason field so that when the location changes
the list of reasons is updated to be relevant to that location. While the lookup
is happening the slot content (presumbably the location and reason selections)
is given an overlay to indicate processing. See the quarantine params for
example usage.

<template>
  <overlay :show="loading">
    <slot />
  </overlay>
</template>

<script>
import Overlay from 'overlay'
import api from 'api'

export default {
  props: ['locationId', 'locations', 'type'],
  emits: ['change'],
  data() {
    return {
      loading: false,
    }
  },
  watch: {
    locationId: {
      immediate: true,
      async handler(newLocation, _oldLocation) {
        const orgId = this.organizationIdFor(newLocation)
        if( !orgId ) {
          this.$emit('change', [])
          return
        }

        this.loading = true

        let [_response, body] = await api(`${this.type}/reasons`, {
          search: { 'active_eq': 'true' },
          headers: {
            'X-Impersonated-Organization-Id': orgId,
          },
        })

        this.$emit('change', body)
        this.loading = false
      },
    },
  },
  methods: {
    organizationIdFor(locationId) {
      for( let location of this.locations )
        if( location.id == locationId )
          return location.organization_id
    },
  },
  components: { Overlay },
}
</script>
