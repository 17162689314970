<template>
  <tr>
    <td>{{ name }}</td>
    <td>{{ organization_name }}</td>
    <td class="min-width">
      <view-button :href="`customers/${id}/edit`" />
      <delete-button v-if="deletable" @click="remove" />
      <restore-button v-else @click="restore"
        :disabled="externallyDisabled" :title="restoreTitle" />
    </td>
  </tr>
</template>

<script>
import api from 'api'
import ViewButton from 'bootstrap/view_button'
import DeleteButton from 'bootstrap/delete_button'
import RestoreButton from 'bootstrap/restore_button'

export default {
  props: ['id', 'name', 'organization_name', 'external_active', 'active'],
  components: { ViewButton, DeleteButton, RestoreButton },
  computed: {
    externallyControlled() { return this.external_active !== null && this.external_active !== undefined },
    externallyDisabled() { return this.externallyControlled && !this.external_active },
    deletable() {
      if( this.externallyControlled )
        return this.active && this.external_active
      else
        return this.active
    },
    restoreTitle() {
      if( this.externallyDisabled )
        return 'Disabled by external system'
      else
        return 'Restore'
    },
  },
  methods: {
    async remove() {
      await api(`customers/${this.id}`, { method: 'DELETE' })
      this.$emit('refresh')
    },
    async restore() {
      await api(`customers/${this.id}/restore`, { method: 'PATCH' })
      this.$emit('refresh')
    },
  },
}
</script>
