import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "custom-field-list-item row" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_custom_field = _resolveComponent("custom-field")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.fieldSchemaList, (fieldSchema) => {
      return (_openBlock(), _createElementBlock("div", {
        key: fieldSchema.id,
        class: "custom-field-wrapper col-md-6 col-lg-4"
      }, [
        _createVNode(_component_custom_field, {
          "field-schema": fieldSchema,
          "old-field-schema": $options.oldSchemaFor(fieldSchema),
          "input-name": $options.listInputName(fieldSchema),
          readonly: $props.readonly
        }, null, 8 /* PROPS */, ["field-schema", "old-field-schema", "input-name", "readonly"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}