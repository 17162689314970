import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_styled_button = _resolveComponent("styled-button")
  const _component_field = _resolveComponent("field")
  const _component_modal = _resolveComponent("modal")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_styled_button, {
      priority: "secondary",
      purpose: "success",
      class: "btn-block",
      icon: "arrow-repeat",
      "data-toggle": "modal",
      "data-target": "#password-modal"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Reset Password ")
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_modal, {
      id: "password-modal",
      ref: "modal",
      onSubmit: $options.submit,
      onClose: $options.close
    }, {
      header: _withCtx(() => [
        _createTextVNode("Reset Password")
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_styled_button, {
          priority: "primary",
          type: "submit"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Save")
          ]),
          _: 1 /* STABLE */
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_field, { label: "Password" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "password",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.password) = $event)),
              required: ""
            }, null, 512 /* NEED_PATCH */), [
              [_vModelText, $data.password]
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_field, { label: "Confirm password" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "password",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.passwordConfirmation) = $event)),
              required: ""
            }, null, 512 /* NEED_PATCH */), [
              [_vModelText, $data.passwordConfirmation]
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onSubmit", "onClose"])
  ]))
}