Covers
  @srs_15.3

The index has the option of displaying results grouped into different buckets.
This component contains those groups and should have child `ResultGroup`
components (these two componets are tightly bound together in their operation).

This component is in charge of getting the counts for the different groups
which is retrieved from the given `countUri` prop. The `query` prop applies
a filter to that count and should be the same value passed to each
individual group for getting their records.

Two slot scope params are passed down for use by the child `ResultGroup`
components:

* panelGroupId - The ID of the parent container used to get bootstrap to
  bind the different collapsable panels together so only one is opened at a
  time.
* counts - A hash of the counts once they are loaded.

Example usage of the two components together:

    <result-groups count-uri="/counts" :query="query">
      <template #default="{ panelGroupId, counts }">
        <result-group name="Group 1" icon="plus-square"
          initial-uri="/group1" :query="query"
          :panel-group-id="panelGroupId" :count="counts['group1']" />
        <result-group name="Group 2" icon="exclamation-circle"
          initial-uri="/group2" :query="query"
          :panel-group-id="panelGroupId" :count="counts['group2']" />
      </template>
    </result-groups>

<template>
  <div :id="panelGroupId">
    <slot :panel-group-id="panelGroupId" :counts="counts" />
  </div>
</template>

<script>
import api from 'api'
import { v4 as uuid } from 'uuid'

export default {
  props: ['countUri', 'query'],
  data() {
    return {
      loading: false,
      counts: {},
    }
  },
  computed: {
    panelGroupId() { return `a${uuid()}` }
  },
  watch: {
    query: {
      deep: true,
      immediate: true,
      handler() { this.loadCounts() },
    },
  },
  methods: {
    async loadCounts() {
      if( this.loading ) return
      this.loading = true

      const [_response, body] = await api(this.countUri, { search: this.query })
      this.counts = body

      this.loading = false
    }
  }
}
</script>
