// Covers
//   @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

import { findFieldContainer, fieldMessage } from './common'

// Put the given message as a error on the specified field.
export default function(currentFieldId, scope, field, msg) {
  const container = findFieldContainer(currentFieldId, scope, field)
  fieldMessage(container, msg, 'invalid')
}
