Covers
  @srs_12.1

If the shipment was created as a "Draft" and a summary of what is in the
shipment was provided via the API this displays that summary.

<template>
  <panel>
    <template #title>Requested Order for {{ summary.customer }}</template>

    <stacked-table>
      <thead>
        <tr>
          <th class="min-width">Qty</th>
          <th>Type</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="component in summary.components_outline">
          <td class="text-right">{{ component.quantity }}</td>
          <td>{{ component.type }}</td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td class="text-right">{{ totalQuantity }}</td>
          <td>Total Components(s)</td>
        </tr>
      </tfoot>
    </stacked-table>
  </panel>
</template>

<script>
import Panel from 'bootstrap/panel'
import StackedTable from 'stacked_table/component'

export default {
  props: ['summary'],
  computed: {
    totalQuantity() {
      return this.summary.components_outline.reduce(
        ((memo, cur) => memo + cur.quantity),
        0
      )
    },
  },
  components: { Panel, StackedTable },
}
</script>
