Covers
  @srs_21.1 @srs_21.2

Should only be called if an "oldFieldSchema" is available to be provided but
otherwise will determine if it should display or not depending if the value
has changed.

<template>
  <div
    v-if="shouldDisplayPrevious"
    class="badge badge-warning persisted-value"
    data-toggle="popover" data-trigger="click hover"
    :data-html="hasHtmlContent" :data-content="content">
    <icon />
    Previous Information
  </div>
</template>

<script>
import Icon from 'bootstrap-icons/icons/clock-history.svg'
import initializeField from 'custom_field_schema/field_types.js'

export default {
  props: ['fieldSchema', 'oldFieldSchema'],
  components: { Icon },

  computed: {
    hasHtmlContent() { return !this.oldFieldSchema.value },
    shouldDisplayPrevious() {
      // If both are "falsy-ish" then don't show even if different values
      if( this.blank(this.fieldSchema.value) && this.blank(this.oldFieldSchema.value) ) return false

      return JSON.stringify(this.fieldSchema.value) != JSON.stringify(this.oldFieldSchema.value)
    },
    content() {
      if( this.oldFieldSchema.value ) {
        const fld = initializeField(this.oldFieldSchema)
        return fld.displayValue()
      } else {
        return '<em>Blank</em>'
      }
    },
  },

  methods: {
    // `fasly` is too broad but treat undefined, null, "" and [] the same
    blank(value) {
      if( Array.isArray(value) && !value.length ) return true

      switch( value ) {
        case null:
        case undefined:
        case '':
          return true
        default:
          return false
      }
    }
  },
}
</script>
