Covers
  @srs_7.5 @srs_7.6 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

  Renders the small help text near each custom field

<template>
  <small v-if="hasHint" class="form-text text-muted" v-bind="$attrs">
    {{ hint }}
  </small>
</template>

<script>
export default {
  props: ['hint'],
  computed: {
    hasHint() {
      return !!(this.hint && this.hint.trim())
    }
  }
}
</script>
