// Covers
//   @srs_7.1 @srs_8.1 @srs_9.1 @srs_12.1 @srs_20.1 @srs_20.2

export default class {
  constructor(input) {
    this.input = input
  }

  isValid() {
    return !!this.input.match(this.constructor.completePattern())
  }

  value() {
    if( !this.isValid() ) return
    return this.normalizedInput()
  }

  normalizedInput() {
    return this.input.replace(/[\n\t]+$/, '')
  }

  static completePattern() {
    return new RegExp(`^${this.pattern.source}[\n\t]*$`)
  }
}
