Covers
  @srs_24.1

Alert messages warning the user regarding the impact of updating the schema
on existing records as well as a trigger button to actually carry out that
update when they are ready.

<template>
  <div>
    <div class="alert alert-warning">
      <p class="mb-0">
        Please exercise caution when tightening the possibilities of a field
        since the existing values may be incompatible with the new schema and
        therefore if the system can't automatically map the existing data it will
        no longer display. Any information that no longer displays will be
        preserved in the audit trail.
      </p>
    </div>

    <collapse>
      <div class="alert alert-primary" v-if="pending">
        <p>
          You have updated this configuration. Your change must be pushed out
          to all existing records.
        </p>

        <styled-button purpose="danger" class="mb-3" @click="apply">
          Apply Changes to All {{ schemasToUpdate.description() }}
        </styled-button>

        <p class="mb-0">
          This process will happen in the background and will take some time. If you
          have other configuration changes to make, make them first then push out
          all changes at once.
        </p>
      </div>
    </collapse>
  </div>
</template>

<script>
import StyledButton from 'bootstrap/styled_button'
import Collapse from 'bootstrap/collapse'

import api from 'api'
import { notice } from 'flash'
import schemasToUpdate from 'admin/user_defined_fields/schemas_to_update'

export default {
  components: { StyledButton, Collapse },

  data() { return { pending: false } },

  created() {
    this.checkEmpty()

    // Bind to component so template can reference
    this.schemasToUpdate = schemasToUpdate
  },

  mounted() {
    this.boundCheckEmpty = this.checkEmpty.bind(this)
    document.addEventListener('schemas-to-update:changed', this.boundCheckEmpty)
  },

  beforeUnmount() {
    document.removeEventListener('schemas-to-update:changed', this.boundCheckEmpty)
  },

  methods: {
    apply() {
      api(`${schemasToUpdate.toPath()}/update_schema`, { method: 'PATCH' })
      notice(`${schemasToUpdate.description()} schema is being updated on existing records`)
      schemasToUpdate.clear()
    },

    checkEmpty() { this.pending = !schemasToUpdate.isEmpty() },
  },
}
</script>
