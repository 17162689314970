Covers
  @srs_7.5 @srs_7.6 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

Renders a direct-upload file input for a UDF/custom field. Requires
`fieldSchema' and `name' Also renders a list of links to download any existing
file uploads.

<template>
  <div class="field-group">
    <!-- Using :value.attr="''" instead of just value="" to preserve attribute.
    For more info see: https://github.com/vuejs/core/issues/6277 -->
    <input v-if="willHaveFiles" :name="name" :value.attr="''" type="hidden" />

    <div class="existing-files list-group">
      <file-display v-for="file in files" :key="file.signed_id" :file="file"
        :name="name" :disabled="$attrs.disabled" />
    </div>

    <div class="custom-file">
      <input type="file" ref="input" multiple @input="queueNewFiles"
        class="form-control custom-file-input" v-bind="$attrs" />

      <label class="custom-file-label"></label>
    </div>
  </div>
</template>

<style scoped>
  .progress { height: 3px; }
</style>

<script>
import { reactive } from 'vue'

import { meta_value } from 'meta_value'
import { DirectUpload } from "@rails/activestorage"

import FileDisplay from './file_input/file_display'
import FileInfo from './file_input/file_info'

export default {
  components: { FileDisplay },
  inheritAttrs: false,
  props: ['modelValue', 'name'],
  data() {
    return {
      files: this.modelValue?.map(d => new FileInfo(d)) || [],
    }
  },
  mounted: function () {
    this.directUploadsUrl = meta_value('direct_uploads_url')
  },
  methods: {
    queueNewFiles() {
      const input = this.$refs.input

      for( let file of input.files )
        this._upload(file)

      input.value = null
    },

    _upload(file) {
      const info = reactive(new FileInfo({ filename: file.name }))
      info.loaded = 0
      info.total = file.size
      this.files.push(info)

      const upload = new DirectUpload(file, this.directUploadsUrl, info)
      upload.create((error, blob) => {
        if( error )
          info.errorMessage = error
        else
          info.signedId = blob.signed_id
      })
    },
  },
  computed: {
    willHaveFiles() { return this.activeFiles.length == 0 },
    activeFiles() { return this.files.filter(f => !f.pendingDelete) },
  }
}
</script>
