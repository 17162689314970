import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_styled_button = _resolveComponent("styled-button")

  return (_openBlock(), _createBlock(_component_styled_button, _mergeProps({
    tag: $props.tag,
    icon: "plus",
    priority: "secondary",
    purpose: $props.purpose
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["tag", "purpose"]))
}