Covers
  @srs_15.3

The index has the option of displaying results grouped into different buckets.
This component is one of those groups and it should be within the `ResultGroups`
component (these two components are tightly bound together in their operation).
There are a number of params that need to be provided:

* name - The label for this group
* icon - An icon representing this group
* panel-group-id - Each group is collapsed by default. Opening one closes
  all the other groups so all groups share the same panel group id. This
  information is provided as a slot scope param given by the ResultGroups
  component.
* count - The total number of records (across all pages) for this group.
  This information is provided as a slot scope param given by the ResultGroups
  components
* initial-uri - The HTTP endpoint used to retrieve the first page of results.
  It is expected this endpoint will return a list of records along with
  pagination info in the `Link` header using the `prev` and `next` rel values
  as defined in RFC 5988.
* query - Any search params (querystring) to be included with the request.

See documention in ResultGroups component for example usage.

<template>
  <overlay :show="loading" class="card my-0">
    <div class="card-header">
      <h2>
        <button class="btn" data-toggle="collapse" :data-target="`#${panelId}`">
          <component v-if="icon" :is="icon" />
          <span class="pl-1 pr-3 title-text">{{ name }}</span>
          <fade>
            <small v-show="typeof count !== 'undefined'">
              {{ count }} results
            </small>
          </fade>
        </button>
      </h2>
    </div>

    <div :id="panelId" class="collapse" :data-parent="panelGroupId ? `#${panelGroupId}` : undefined" ref="collapsiblePanel">
      <div v-show="loaded" class="card-body">
        <slot v-if="rows.length > 0" :rows="rows" />
        <template v-else>
          No records found
        </template>
        <pagination :previous-uri="previousUri" :next-uri="nextUri" @navigate="dataUri = $event" />
      </div>
    </div>
  </overlay>
</template>

<script>
import api from 'api'
import Fade from 'bootstrap/fade'
import Overlay from 'overlay'
import Pagination from 'bootstrap/pagination'
import PaginationProcessing from 'pagination'

import $ from 'jquery'
import { v4 as uuid } from 'uuid'

export default {
  mixins: [PaginationProcessing],
  props: ['name', 'icon', 'initialUri', 'query', 'panelGroupId', 'count'],
  mounted() {
    // Bootstrap emits "jQuery" events rather than real native events so we
    // have to listen for those events with jQuery also. :(
    this.$panel.on('show.bs.collapse', (e)=> this.opened(e))
  },
  data() {
    return {
      dataUri: this.$props.initialUri,
      previousUri: null,
      nextUri: null,
      rows: [],
      loaded: false,
      loading: false,
    }
  },
  watch: {
    dataUri() { this.invalidate() },
    query: {
      deep: true,
      // NOTE: We conditionally invalidate if the data URI changed. If it did
      // not (no pagination reset) then we need to trigger a invalidation for
      // fresh data. If it does change (pagination data needs to be removed)
      // then the update to dataUri will trigger an invalidate for us.
      handler() {
        const oldDataUri = this.dataUri
        this.dataUri = this.initialUri
        if( this.dataUri == oldDataUri ) this.invalidate()
      },
    },
  },
  computed: {
    panelId() { return `a${uuid()}` },
    $panel() { return $(this.$refs.collapsiblePanel) }
  },
  methods: {
    invalidate() { if( this.loaded ) this.load() },
    async opened(event) {
      if( this.loaded ) return
      event.preventDefault()
      await this.load()
      this.$panel.collapse('show')
    },

    async load() {
      this.loading = true

      const [response, body] = await api(this.dataUri, { search: this.query })
      this.setPagination(response.headers.get('Link'))
      this.rows = body

      this.loading = false
      this.loaded = true
    }
  },
  components: { Fade, Overlay, Pagination },
}
</script>
