// Covers
//   @srs_7.5 @srs_18.2 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

import api from 'api'
import { backendUri } from './common'
import lockSetFor from '../lock_set'

export default async function(fieldId, scope, field, value) {
  const lockSet = lockSetFor(fieldId)

  const [_, body] = await api(backendUri(scope, field), {
    method: 'PATCH',
    body: { value, lock_version: lockSet.get(scope) }
  })
  lockSet.set(scope, body.lock_version)

  const container = document.querySelector(`[data-backend-object=${scope}]`)
  if( !container ) return

  container.dispatchEvent(new CustomEvent('refresh', { bubbles: true, cancelable: true }))
}
