import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  title: "required"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_previous_value_notice = _resolveComponent("previous-value-notice")
  const _component_more_info_link = _resolveComponent("more-info-link")
  const _component_help_text = _resolveComponent("help-text")
  const _component_field = _resolveComponent("field")

  return ($props.fieldSchema)
    ? (_openBlock(), _createBlock(_component_field, {
        key: 0,
        "data-type": $props.fieldSchema.type,
        "data-labeled": $props.fieldSchema.title,
        "data-original-readonly": $props.readonly,
        class: "user-defined-field-input"
      }, {
        default: _withCtx(() => [
          ($props.oldFieldSchema)
            ? (_openBlock(), _createBlock(_component_previous_value_notice, {
                key: 0,
                "field-schema": $props.fieldSchema,
                "old-field-schema": $props.oldFieldSchema
              }, null, 8 /* PROPS */, ["field-schema", "old-field-schema"]))
            : _createCommentVNode("v-if", true),
          ($props.showLabel && $props.fieldSchema.type != 'switch')
            ? (_openBlock(), _createElementBlock("label", {
                key: 1,
                for: $options.labelId,
                class: "control-label"
              }, [
                _createTextVNode(_toDisplayString($props.fieldSchema.title) + " ", 1 /* TEXT */),
                ($options.flagToBoolean($props.fieldSchema.required))
                  ? (_openBlock(), _createElementBlock("abbr", _hoisted_2, "*"))
                  : _createCommentVNode("v-if", true),
                _createVNode(_component_more_info_link, {
                  url: $props.fieldSchema.more_information_url
                }, null, 8 /* PROPS */, ["url"])
              ], 8 /* PROPS */, _hoisted_1))
            : _createCommentVNode("v-if", true),
          (_openBlock(), _createBlock(_resolveDynamicComponent($options.fieldComponentName), {
            modelValue: $props.fieldSchema.value,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = $event => (($props.fieldSchema.value) = $event)),
              _cache[1] || (_cache[1] = $event => ($options.triggerHook('input')))
            ],
            "aria-label": $props.showLabel ? null : $props.fieldSchema.title,
            name: $props.inputName,
            id: $options.labelId,
            disabled: $props.readonly,
            "option-values": $props.fieldSchema.option_values
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.fieldSchema.title), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "aria-label", "name", "id", "disabled", "option-values"])),
          _createVNode(_component_help_text, {
            hint: $props.fieldSchema.hint
          }, null, 8 /* PROPS */, ["hint"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data-type", "data-labeled", "data-original-readonly"]))
    : _createCommentVNode("v-if", true)
}