Covers:
  @srs_12.1 @srs_12.2

Client-side component to construct a "Ship" transaction

<template>
  <layout :is-submittable="isSubmittable" @submit="send">
    <template #params>
      <div class="row">
        <div class="col-sm-6">
          <customer label="Ship to Customer"
            v-model="customerId" :customers="customers" />
        </div>

        <div class="col-sm-6">
          <field label="Order ID">
            <input type="text" v-model="orderNum" />
          </field>
        </div>
      </div>
    </template>

    <div class="row">
      <div :class="{'col-sm-8': summary, 'col-12': !summary}">
        <list :items="items" transaction-type="ship"
          @next-item="nextItem()" @delete-item="deleteItem($event)" />

        <field>
          <textarea v-model="comment" placeholder="Comment..." rows="5" />
        </field>
      </div>

      <div v-if="summary" class="col-sm-4 ">
        <ship-summary :summary="summary" />
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from './layout'
import List from './list/basic'
import Customer from './params/customer'
import Field from 'bootstrap/field'
import ShipSummary from 'transactions/ship/summary'
import Common from 'transactions/common'

export default {
  mixins: [Common],
  props: ['transaction', 'customers'],
  data: function() {
    return Object.assign({
      summary: this.transaction.summary,
      customerId: this.transaction.params.customer_id,
      orderNum: this.transaction.params.order_num,
      comment: this.transaction.params.comment,
    }, this.baseLoad('ship'))
  },
  methods: {
    async send() {
      this.processingSend(async ()=> {
        await this.ensureItemsValidated('ship')
        if( !this.isSendable ) return

        const transaction = this.newTransaction()
        transaction.type = 'ship'
        transaction.customer_id = this.customerId
        transaction.order_num = this.orderNum
        transaction.comment = this.comment
        await transaction.send()

        this.reset('ship')
      })
    },
  },
  components: { Layout, List, Field, Customer, ShipSummary }
}
</script>
