Covers
  @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

Root component for the user_defined_function admin form.

<template>
  <form class="user-defined-field" @submit.prevent="submitForm">
    <AvailableFieldPicker v-if="!userDefinedField.id" @input="availableFieldChanged" :availableFields="availableFields" />

    <div v-if="userDefinedField.field_name">
      <FieldSchemaList v-if="fieldIsList"
        :field-schema-array="userDefinedField.field_schema"
        :option-lists="optionLists"
        :visibility-types-allowed="visibilityTypesAllowed" />
      <SingleFieldSchema v-else
        v-model:field-schema="userDefinedField.field_schema"
        :option-lists="optionLists"
        @delete-schema="deleteFieldSchema"
        @init-schema="initFieldSchema"
        :visibility-types-allowed="visibilityTypesAllowed" />
    </div>

    <mwc-fab label="Save">
      <icon slot="icon" />
    </mwc-fab>
  </form>
</template>

<script>
// Components
import 'theme/material'
import AvailableFieldPicker from './form/available_field_picker'
import FieldSchemaList from 'custom_field_schema/edit_field_ui/field_schema_list'
import SingleFieldSchema from 'custom_field_schema/edit_field_ui/single_field_schema'
import Icon from 'bootstrap-icons/icons/check.svg'

import { notice } from 'flash'

// Models
import UserDefinedField from './user_defined_field'
import schemasToUpdate from 'admin/user_defined_fields/schemas_to_update'

export default {
  props: {
    'availableFields': {
      default() { return [] }
    },
    "userDefinedFieldJson": {
      default() { return {} }
    },
    "optionLists": {},
    "updatableSchema": { default: false },
    "visibilityTypesAllowed": { default() { return ['Summary', 'Report', 'Search Results'] } },
  },
  components: {
    AvailableFieldPicker, SingleFieldSchema, FieldSchemaList, Icon
  },
  data() {
    return {
      userDefinedField: new UserDefinedField(this.userDefinedFieldJson),
      selectedField: null
    }
  },
  computed: {
    fieldIsList() { return this.userDefinedField.isFieldList() },
  },
  methods: {
    async deleteFieldSchema() { this.userDefinedField.field_schema = null },
    async initFieldSchema() { this.userDefinedField.initFieldSchema() },
    async availableFieldChanged(field_display_name) {
      var new_field = this.availableFields.find(function(field) {
        return field.display_name == field_display_name
      })

      this.userDefinedField.setField(new_field)

      this.selectedField = new_field
    },
    async submitForm() {
      await this.userDefinedField.send()

      if( this.updatableSchema )
        schemasToUpdate.add(this.userDefinedField.class_name)

      document.addEventListener('turbo:load', () => {
        notice('User Defined Field Successfully Saved')
      }, { once: true })
      Turbo.visit(`/admin/user_defined_fields/${this.userDefinedField.id}/edit`)
    }
  },
}
</script>
