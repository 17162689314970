import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, mergeProps as _mergeProps, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "field-group" }
const _hoisted_2 = ["disabled", "name"]
const _hoisted_3 = ["name"]
const _hoisted_4 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      disabled: _ctx.$attrs.disabled,
      name: $options.inputName,
      type: "hidden",
      value: "",
      autocomplete: "off"
    }, null, 8 /* PROPS */, _hoisted_2),
    _withDirectives(_createElementVNode("select", _mergeProps({
      class: "custom-select",
      multiple: "multiple"
    }, _ctx.$attrs, {
      name: $options.inputName,
      onChange: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', $data.selections))),
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.selections) = $event))
    }), [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.optionValues, (optionValue) => {
        return (_openBlock(), _createElementBlock("option", {
          key: optionValue,
          value: optionValue
        }, _toDisplayString(optionValue), 9 /* TEXT, PROPS */, _hoisted_4))
      }), 128 /* KEYED_FRAGMENT */))
    ], 16 /* FULL_PROPS */, _hoisted_3), [
      [_vModelSelect, $data.selections]
    ])
  ]))
}