document.addEventListener('click', function() {
  const sidebarToggler = event.target.closest('[data-toggle="sidebar"]')
  if( !sidebarToggler ) return

  document.body.classList.toggle('sidebar-open')

  if( document.body.classList.contains('sidebar-open') )
    sidebarToggler.setAttribute('aria-expanded', 'true')
  else
    sidebarToggler.setAttribute('aria-expanded', 'false')
})
