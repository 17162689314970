import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_container = _resolveComponent("container")
  const _component_date_input = _resolveComponent("date-input")
  const _component_collapse = _resolveComponent("collapse")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_container, { label: $props.label }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($options.at) = $event))
        }, [
          ($props.includeBlank)
            ? (_openBlock(), _createElementBlock("option", {
                key: 0,
                value: $options.allOptionValue()
              }, null, 8 /* PROPS */, _hoisted_2))
            : _createCommentVNode("v-if", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              value: option[1]
            }, _toDisplayString(option[0]), 9 /* TEXT, PROPS */, _hoisted_3))
          }), 256 /* UNKEYED_FRAGMENT */))
        ], 512 /* NEED_PATCH */), [
          [_vModelSelect, $options.at]
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["label"]),
    _createVNode(_component_collapse, null, {
      default: _withCtx(() => [
        ($data.type === 'specific-date')
          ? (_openBlock(), _createBlock(_component_container, {
              key: 0,
              label: "Date"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_date_input, {
                  modelValue: $options.on,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($options.on) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($data.type === 'date-range')
          ? (_openBlock(), _createBlock(_component_container, {
              key: 1,
              label: "Start"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_date_input, {
                  modelValue: $options.onGteq,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($options.onGteq) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_collapse, null, {
      default: _withCtx(() => [
        ($data.type === 'date-range')
          ? (_openBlock(), _createBlock(_component_container, {
              key: 0,
              label: "End"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_date_input, {
                  modelValue: $options.onLteq,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($options.onLteq) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}