// Covers:
//   @srs_1.1 @srs_1.3 @srs_1.5 @srs_1.10 @srs_2.2 @srs_2.5 @srs_3.1 @srs_5.1 @srs_6.1 @srs_7.1 @srs_7.5 srs_8.1 @srs_9.1 @srs_9.2 @srs_12.1 @srs_12.2 @srs_14.1 @srs_14.2 @srs_14.5 @srs_14.8 @srs_15.2 @srs_15.3 @srs_15.4 @srs_15.5 @srs_15.6 @srs_20.1 @srs_20.2 @srs_20.3 @srs_20.4 @srs_20.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4 @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

/*
 * Avoid silent client-side errors.
 *
 * This is independent from error reporting to the developers. We want to ensure
 * if some unexpected error happens the user knows something went wrong. It also
 * gives the user some information to report when that happens.
 *
 * For now all the handler does is display the exception message within an
 * alert box.
 */

function notify(message) {
  message = message + "\n\n" +
    "The application may be in an unstable state. Press OK to refresh the " +
    "page to ensure a stable state is restored. Press Cancel if you have " +
    "unsaved data you wish to save elsewhere first. You will then need to " +
    "manually refresh this page."

  if( !confirm(message) ) return
  location.reload()
}

window.addEventListener('error', event => {
  notify(event.message)
  throw event.error // rethrow so we have a stacktrace in the console
})
window.addEventListener('unhandledrejection', event => notify(event.reason))
