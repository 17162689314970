// Covers:
//   @srs_1.1 @srs_1.3 @srs_1.5 @srs_1.9 @srs_1.10 @srs_3.1 @srs_5.1 @srs_6.1 @srs_7.4 @srs_7.6 @srs_8.1 @srs_9.1 @srs_12.1 @srs_14.1 @srs_14.2 @srs_14.5 @srs_15.3 @srs_15.4 @srs_15.5 @srs_15.6 @srs_22.1 @srs_24.1

import { csrfToken } from 'meta_value'

export const apiVersion = '2023-08-14'

/*
 * Wrapper for `fetch` function customized for accessing the API. Assumes
 *
 *   - Base URI of /api/<version> where <version> is the desired API version.
 *     If absoltue path (starts with `/`) is given this prefix is not added.
 *     Also if full URI is given then no prefix is added.
 *   - `search` option can be provided which will be turned into querystring
 *     params.
 *   - `allowedErrors` option allows non-200 status codes to not trigger the
 *      failure error handling so the calling code can handle it.
 *   - Request is JSON content type and any body will be automatically encoded.
 *   - Response is JSON content type and will be automatically decoded.
 *
 * Returns both the full response as well as the decoded body. Example usage:
 *
 *   [response, body] = await api('users')
 */
export default async function(path, options={}) {
  path = normalizePath(path)
  path = setupQuerystringParams(path, options)
  setupOptions(options)
  encodeBody(options)
  return await withErrorHandling(options, async function() {
    const response = await fetch(path, options)
    const body = await parseResponse(response)
    return [response, body]
  })
}

function normalizePath(path) {
  if( path[0] != '/' && !path.startsWith('http') ) {
    path = `/api/${apiVersion}/${path}`
  }
  return path
}

function setupQuerystringParams(path, options) {
  if( options.search ) {
    path = new URL(path, location.href)
    Object.keys(options.search).forEach(key => {
      const value = options.search[key]

      if( value === null ) return // Empty values are not appended

      if( Array.isArray(value) ) {
        // Arrays follow PHP convention of appending multiple params with
        // a `[]` suffix
        value.forEach(v => path.searchParams.append(`${key}[]`, v))
      } else {
        path.searchParams.append(key, value)
      }
    })
    delete options.search
  }
  return path
}

function setupOptions(options) {
  if( !options.headers ) options.headers = {}
  options.headers['X-CSRF-Token'] = csrfToken()
  options.credentials = 'same-origin'
}

function encodeBody(options) {
  if( options.body ) {
    if( !options.method ) options.method = 'POST'
    options.body = JSON.stringify(options.body)
    options.headers['Content-Type'] = 'application/json'
  }
}

async function parseResponse(response) {
  const contentType = response.headers.get('Content-Type')
  if( contentType && contentType.startsWith('application/json') )
    try {
      return await response.json()
    } catch(e) {
      // swallow parsing error
    }
}

async function withErrorHandling(options, callback) {
  let allowedErrors = []
  if( options.allowedErrors ) {
    allowedErrors = options.allowedErrors
    delete options.allowedErrors
  }

  const [response, body] = await callback()

  if( response.ok || allowedErrors.includes(response.status) )
    return [response, body]

  throw new Error(responseError(response, body))
}

function responseError(response, body) {
  if( body && body.errors )
    return body.errors.join("\n")
  else
    return response.statusText
}
