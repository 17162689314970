/* If an error comes back from Turbo then turbo will replace the page with
 * the error message. If a 5xx error it does so by not considering anything
 * about the current page. If a 4xx error it operates somewhat like a normal
 * Turbo visit only the history is not changed if the error came from a form
 * submission (to support pressing reload without getting a browser warning).
 *
 * The 4xx behavior works great for a 422 response where the response is uses
 * the normal page layout as it is likely a validation error. But other
 * responses that don't use the normal page layout like a 409 conflict means
 * the tracked assets change causing a reload. This means the eror message is
 * effectively never displayed as it just reloads the page.
 *
 * This ext listens for turbo respones. If the response is anything in the 4xx
 * range except a 422 then it will remove the tracking from the assets to
 * prevent the reload.
 *
 * Will probably look to create an issue on the Turbo repo to see if I can come
 * up with something better but it will be enough at least for now.
 */
document.addEventListener('turbo:before-fetch-response', ({ detail: { fetchResponse } }) => {
  if( !fetchResponse.clientError || fetchResponse.statusCode == 422 ) return

  document.querySelectorAll('[data-turbo-track]').forEach(e => delete e.dataset.turboTrack)
})
