Covers
  @srs_7.5 @srs_7.6 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

  Renders radio buttons for a UDF/custom field.

<template>
  <div class="radio-buttons field-group" :id="id">
    <radio v-for="optionValue in optionValues" :key="optionValue"
      :value="optionValue" @change="$emit('update:modelValue', optionValue)"
      :checked="isChecked(optionValue)" v-bind="$attrs" >
      {{ optionValue }}
    </radio>
  </div>
</template>

<script>
import Radio from 'bootstrap/radio'

export default {
  inheritAttrs: false,
  props: ['id', 'optionValues', 'modelValue'],
  emits: ['update:modelValue'],
  methods: {
    isChecked(optionValue) {
      return optionValue === this.modelValue
    },
  },
  components: { Radio },
}
</script>
