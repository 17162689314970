import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-width" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_view_button = _resolveComponent("view-button")
  const _component_delete_button = _resolveComponent("delete-button")
  const _component_restore_button = _resolveComponent("restore-button")

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, _toDisplayString($props.name), 1 /* TEXT */),
    _createElementVNode("td", null, _toDisplayString($props.organization_name), 1 /* TEXT */),
    _createElementVNode("td", _hoisted_1, [
      _createVNode(_component_view_button, {
        href: `customers/${$props.id}/edit`
      }, null, 8 /* PROPS */, ["href"]),
      ($options.deletable)
        ? (_openBlock(), _createBlock(_component_delete_button, {
            key: 0,
            onClick: $options.remove
          }, null, 8 /* PROPS */, ["onClick"]))
        : (_openBlock(), _createBlock(_component_restore_button, {
            key: 1,
            onClick: $options.restore,
            disabled: $options.externallyDisabled,
            title: $options.restoreTitle
          }, null, 8 /* PROPS */, ["onClick", "disabled", "title"]))
    ])
  ]))
}