// Covers
//   @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

import { findFieldContainer } from './common'

// Gets the value of the given field. Once found runs callback with value
export default function(currentFieldId, scope, field, callback) {
  const container = findFieldContainer(currentFieldId, scope, field)
  const val = (getVal[container.dataset.type] || getVal.default)(container)
  callback(val)
}

const getVal = {
  checkboxes: c => valsFromNodes(c.querySelectorAll('input[type=checkbox]:checked')),
  file: _c => undefined,
  radio_buttons: c => {
    const fld = c.querySelector('[type=radio]:checked')
    if( fld ) return fld.value
  },
  multi_select_list: c => valsFromNodes(c.querySelector('select').selectedOptions),
  select_list: c => c.querySelector('select').value,
  switch: c => !!c.querySelector('[type=checkbox]:checked'),
  yes_no: c => {
    const fld = c.querySelector('[type=radio]:checked')
    switch( fld && fld.value ) {
      case 'true':
        return true
      case 'false':
        return false
    }
  },
  default: c => c.querySelector('input, textarea').value,
}

function valsFromNodes(nodes) {
  return Array.from(nodes).map(n => n.value)
}
