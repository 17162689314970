import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col-1" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_radio = _resolveComponent("radio")
  const _component_styled_button = _resolveComponent("styled-button")

  return (_openBlock(), _createElementBlock("div", {
    class: "row",
    title: $props.organization.name
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_radio, {
        value: $props.organization.id,
        checked: $props.modelValue == $props.organization.id,
        onChange: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', $props.organization.id)))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.highlightChunks, (chunk) => {
            return (_openBlock(), _createElementBlock("span", {
              class: _normalizeClass({ 'font-weight-bold': chunk.match })
            }, _toDisplayString(chunk.text), 3 /* TEXT, CLASS */))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["value", "checked"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_styled_button, {
        icon: "award-fill",
        title: "Toggle Default",
        size: "sm",
        priority: "secondary",
        outline: $options.isDefault,
        onClick: _cache[1] || (_cache[1] = $event => ($options.toggleDefault()))
      }, null, 8 /* PROPS */, ["outline"])
    ])
  ], 8 /* PROPS */, _hoisted_1))
}