import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 1,
  style: {"white-space":"pre-wrap"}
}
const _hoisted_3 = { key: 2 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.change.html)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        innerHTML: this.value
      }, null, 8 /* PROPS */, _hoisted_1))
    : ($props.change.preformatted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($options.formattedValue), 1 /* TEXT */))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($options.formattedValue), 1 /* TEXT */))
}