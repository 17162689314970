// Covers
//   @srs_1.6 @srs_7.1 @srs_8.1 @srs_9.1 @srs_12.1 @srs_15.3 @srs_15.4

export function meta_value(name) {
  const element = document.querySelector(`meta[name="${name}"]`)
  if( element ) return element.getAttribute('content')
}

export function logged_in() { return meta_value('logged_in') === 'true' }
export function csrfToken() { return meta_value('csrf-token') }
export function env() { return meta_value('rails_env') }
