import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "toast",
  role: "status",
  "aria-live": "polite",
  "aria-atomic": "true",
  "data-autohide": "false"
}
const _hoisted_2 = { class: "toast-body" }
const _hoisted_3 = { class: "toast-message" }
const _hoisted_4 = {
  key: 1,
  type: "button",
  class: "btn close",
  "data-dismiss": "toast",
  "aria-label": "Close"
}
const _hoisted_5 = { "aria-hidden": "true" }
const _hoisted_6 = {
  key: 0,
  class: "toast-footer"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_close_icon = _resolveComponent("close-icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.icon)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["badge badge-circle badge-lg", `badge-${$props.purpose}`])
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent($props.icon)))
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("span", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      ($props.closable)
        ? (_openBlock(), _createElementBlock("button", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_close_icon)
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    (_ctx.$slots.footer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "footer")
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}