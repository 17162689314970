import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($options.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("dt", null, _toDisplayString($options.label), 1 /* TEXT */),
        _createElementVNode("dd", null, _toDisplayString($options.formattedValue), 1 /* TEXT */)
      ]))
    : _createCommentVNode("v-if", true)
}