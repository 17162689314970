Covers
  @srs_15.5

An individual representation of a patient on the patient index.

<template>
  <card>
    <template #title>
      {{ patient.name }}
    </template>

    <dl>
      <dt>Blood type</dt>
      <dd>{{ patient.blood_type }}</dd>

      <udf-field v-bind="patient.significant_antibodies" />
      <udf-field v-bind="patient.insignificant_antibodies" />
      <udf-field v-for="field in additionalInformation" v-bind="field" :key="field.id" />
    </dl>

    <view-button :href="`${pathScope}/patients/${patient.id}/edit?search=${encodedSearch}`" class="btn-block" />
  </card>
</template>

<script>
import Card from 'bootstrap/card'
import ViewButton from 'bootstrap/view_button'
import UdfField from 'custom_field_schema/search_result_field'

export default {
  props: ['patient', 'pathScope', 'query'],
  components: { Card, ViewButton, UdfField },
  computed: {
    encodedSearch() { return encodeURIComponent(this.query.search) },
    additionalInformation() { return this.patient.additional_information },
  }
}
</script>
