Covers:
  @srs_3.1

Renders a change where there is both a before and after value

<template>
  <div>
    {{ change.label }} changed from
    <span class="font-weight-bold text-info">
      <value :change="change" :value="change.before" />
    </span>
    to
    <span class="font-weight-bold text-info">
      <value :change="change" :value="change.after" />
    </span>
  </div>
</template>

<script>
import Value from './value'

export default {
  props: ['change'],
  components: { Value },
}
</script>
