import 'fail'

import StackdriverErrorReporter from 'stackdriver-errors-js'
const errorReporter = new StackdriverErrorReporter()
errorReporter.start({ targetUrl: '/report/error' })

import '@hotwired/turbo-rails'
import 'ext/turbo'

import 'trix'
import 'ext/trix'

import '@rails/actiontext'
import 'theme/actiontext'

import { start as startActiveStorage } from "@rails/activestorage"
startActiveStorage()

import load from 'ext/vue/load'

// Explicitly imported for side effects before bootstrap init so we can cancel
// it's validation feedback
import 'add_async_submit_listener'

import 'init/timezone'
import 'init/session'

import 'ujs/auto_submit'
import 'ujs/linkish'
import 'ujs/sub_super'
import 'ujs/conditional_field'
import 'ujs/change_warning'
import 'ujs/modal_autofocus'
import 'ujs/rich_text_required'
import 'ujs/sortable'
import 'ujs/import_sidebar'

/**** Theme related styles and JS ****/
import 'theme/styles'
import 'theme/sidebar'
import 'theme/material'
import 'bootstrap/init'

/**** Admin ****/

import UsersIndex from 'admin/users/index'
load('#users_index', UsersIndex)

import UserPassword from 'admin/users/password'
load('#user_password', UserPassword)

import NewUserApiKey from 'admin/users/new_api_key'
load('#new_user_api_key', NewUserApiKey)

import CustomersIndex from 'admin/customers/index'
load('#customers_index', CustomersIndex)

import TestTypesIndex from 'admin/test_types/index'
load('#test_types_index', TestTypesIndex)
import ResultsConfiguration from 'admin/test_types/results'
load('#results_configuration', ResultsConfiguration)

import ReasonsIndex from 'admin/reasons/index'
load('#reasons_index', ReasonsIndex)

import LocationsIndex from 'admin/locations/index'
load('#locations_index', LocationsIndex)

import FieldSchemaList from 'custom_field_schema/edit_field_ui/field_schema_list'
load('.field-schema-list-editor', FieldSchemaList)

import UserDefinedFieldsIndex from 'admin/user_defined_fields/index'
load('#user_defined_fields_index', UserDefinedFieldsIndex)
import UserDefinedFieldsForm from 'admin/user_defined_fields/form'
load('#user_defined_fields_form', UserDefinedFieldsForm)
import SchemaUpdateAlert from 'admin/user_defined_fields/schema_update_alert'
load('.schema_update_alert', SchemaUpdateAlert)

import ListItemsIndex from 'admin/list_items/index'
load('#list_items_index', ListItemsIndex)

import PrintFormEditor from 'admin/print_form/form'
load('#print_form_editor', PrintFormEditor)

/**** Transactions ****/

import TransactionsIndex from 'transactions/index'
load('#transactions_index', TransactionsIndex)

import MakeAvailable from 'transactions/make_available'
load('#make_available_transaction', MakeAvailable)

import Quarantine from 'transactions/quarantine'
load('#quarantine_transaction', Quarantine)

import Ship from 'transactions/ship'
load('#ship_transaction', Ship)

/***** Order *****/

import ResourcePanel from 'resource_panel'
load('.resource-panel', ResourcePanel)

import OrdersIndex from 'orders/index'
load('#orders_index', OrdersIndex)

import ProductRequest from 'orders/product_request'
load('#product_request', ProductRequest)

import PatientsIndex from 'patients/index'
load('#patients_index', PatientsIndex)

import OrderState from 'orders/state'
load('.order-state', OrderState)

import Grid from 'orders/grid'
load('#test_grid', Grid)

import Report from 'orders/report'
load('.order-report', Report)

import CannedNotesInsertButton from 'canned_notes/insert_button'
load('.canned-notes-insert-button', CannedNotesInsertButton)

import 'orders/title'
import 'orders/report_status'
import 'orders/report_send'

/***** Other *****/

import StyledButton from 'bootstrap/styled_button'
StyledButton.progressiveEnhance()

import CustomField from 'custom_field_schema/form_field_ui/custom_field'
load('.custom-field', CustomField)

import CustomFieldList from 'custom_field_schema/form_field_ui/custom_field_list'
load('.custom-field-list', CustomFieldList)

import InventoryUnitsIndex from 'inventory_units/index'
load('#inventory_units_index', InventoryUnitsIndex)

import AuditIndex from 'audits/index'
load('#audits_index', AuditIndex)

import ImpersonationPanel from 'impersonation/panel'
load('#impersonation_panel', ImpersonationPanel)

import CodeEditorField from 'code_editor_field'
load('.code-editor-field', CodeEditorField)
