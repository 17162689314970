import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row justify-content-end" }
const _hoisted_2 = { class: "col-auto" }
const _hoisted_3 = {
  key: 0,
  "aria-label": "Page navigation"
}
const _hoisted_4 = { class: "pagination" }
const _hoisted_5 = { class: "page-item previous" }
const _hoisted_6 = { class: "page-item next" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_fade = _resolveComponent("fade")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.previousUri || $props.nextUri)
        ? (_openBlock(), _createElementBlock("nav", _hoisted_3, [
            _createElementVNode("ul", _hoisted_4, [
              _createVNode(_component_fade, null, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("li", _hoisted_5, [
                    _createElementVNode("a", {
                      onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.$emit('navigate', $props.previousUri)), ["prevent","stop"])),
                      class: "page-link",
                      href: "#"
                    }, " Previous ")
                  ], 512 /* NEED_PATCH */), [
                    [_vShow, $props.previousUri]
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_fade, null, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("li", _hoisted_6, [
                    _createElementVNode("a", {
                      onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.$emit('navigate', $props.nextUri)), ["prevent","stop"])),
                      class: "page-link",
                      href: "#"
                    }, " Next ")
                  ], 512 /* NEED_PATCH */), [
                    [_vShow, $props.nextUri]
                  ])
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}