import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-weight-bold text-info" }
const _hoisted_2 = { class: "font-weight-bold text-info" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_value = _resolveComponent("value")

  return (_openBlock(), _createElementBlock("div", null, [
    _createTextVNode(_toDisplayString($props.change.label) + " changed from ", 1 /* TEXT */),
    _createElementVNode("span", _hoisted_1, [
      _createVNode(_component_value, {
        change: $props.change,
        value: $props.change.before
      }, null, 8 /* PROPS */, ["change", "value"])
    ]),
    _createTextVNode(" to "),
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_value, {
        change: $props.change,
        value: $props.change.after
      }, null, 8 /* PROPS */, ["change", "value"])
    ])
  ]))
}