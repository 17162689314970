Covers
   @srs_7.5 @srs_20.5 @srs_20.6 @srs_21.4

Wrapper around PdfViewer. Trying to keep PdfViewer to something generic that
could be use in any app and the order/report component holds any behavior that
is specific to this app.

Currently does two things:

* Listens for report note changes to auto-refresh the PDF view
* When in the test environment also request the HTML version of the report
  and output that in a hidden element. While not perfect, it provides something
  for integration tests to use to verify certain content is in the report.

<template>
  <div>
    <pdf-viewer :href="href" ref="viewer" />
  </div>
</template>

<script>
import PdfViewer from 'pdf_viewer'

export default {
  props: ['href'],
  components: { PdfViewer },
  created() {
    this.boundReload = event => this.reload(event)
    this.noteEvents = ['create', 'update', 'delete']
  },
  mounted() {
    this.noteEvents.forEach(e => document.addEventListener(`note:${e}`, this.boundReload))
  },
  unmounted() {
    this.noteEvents.forEach(e => document.removeEventListener(`note:${e}`, this.boundReload))
  },
  methods: {
    reload(event) {
      const detail = event.detail

      if( detail.purpose == 'report' ) {
        this.$refs.viewer.reload()

        // Reload the frame if available
        if( this.$refs.hiddenContent ) this.$refs.hiddenContent.src += ''
      }
    },
  },
}
</script>
