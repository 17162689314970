// Covers
//   @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

import { findFieldContainer } from './common'
import $ from 'jquery'

// Show `field` if `visible` true. Hide if false
export default function(currentFieldId, scope, field, visible) {
  const $fld = $(findFieldContainer(currentFieldId, scope, field))
  $fld.addClass('collapse')
  if( visible )
    $fld.collapse('show')
  else
    $fld.collapse('hide')
}
