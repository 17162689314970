// Covers
//   @srs_20.5

import api from 'api'
import { notice } from 'flash'

document.addEventListener('click', async event => {
  const button = event.target
  if( !button.matches('.send-report') ) return

  const orderId = button.dataset.order

  await api(`orders/${orderId}/report/publish`, { method: 'POST' })
  notice('Report successfully sent')
})
