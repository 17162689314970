import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["selected"]
const _hoisted_2 = ["value", "selected"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("select", _mergeProps({ class: "custom-select" }, _ctx.$attrs, {
    onChange: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', $event.target.value)))
  }), [
    _createElementVNode("option", {
      selected: $options.defaultSelected,
      value: ""
    }, "-- Blank --", 8 /* PROPS */, _hoisted_1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.optionValues, (optionValue) => {
      return (_openBlock(), _createElementBlock("option", {
        value: optionValue,
        selected: $options.isSelected(optionValue)
      }, _toDisplayString(optionValue), 9 /* TEXT, PROPS */, _hoisted_2))
    }), 256 /* UNKEYED_FRAGMENT */))
  ], 16 /* FULL_PROPS */))
}