import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_pagination = _resolveComponent("pagination")
  const _component_overlay = _resolveComponent("overlay")

  return (_openBlock(), _createBlock(_component_overlay, {
    show: $data.loading,
    class: "results-panel"
  }, {
    default: _withCtx(() => [
      ($data.rows)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            ($data.rows.length > 0)
              ? _renderSlot(_ctx.$slots, "default", {
                  key: 0,
                  rows: $data.rows
                })
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(" No records found ")
                ], 64 /* STABLE_FRAGMENT */)),
            _createVNode(_component_pagination, {
              "previous-uri": $data.previousUri,
              "next-uri": $data.nextUri,
              onNavigate: _cache[0] || (_cache[0] = $event => ($data.dataUri = $event))
            }, null, 8 /* PROPS */, ["previous-uri", "next-uri"])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["show"]))
}