import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-html", "data-content"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon")

  return ($options.shouldDisplayPrevious)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "badge badge-warning persisted-value",
        "data-toggle": "popover",
        "data-trigger": "click hover",
        "data-html": $options.hasHtmlContent,
        "data-content": $options.content
      }, [
        _createVNode(_component_icon),
        _createTextVNode(" Previous Information ")
      ], 8 /* PROPS */, _hoisted_1))
    : _createCommentVNode("v-if", true)
}