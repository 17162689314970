import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-control custom-checkbox" }
const _hoisted_2 = ["checked", "id"]
const _hoisted_3 = ["for"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", _mergeProps({
      type: "checkbox",
      class: "custom-control-input"
    }, _ctx.$attrs, {
      checked: $props.modelValue || $props.checked,
      onChange: _cache[0] || (_cache[0] = (...args) => ($options.changed && $options.changed(...args))),
      id: $options.fieldId
    }), null, 16 /* FULL_PROPS */, _hoisted_2),
    _createElementVNode("label", {
      class: "custom-control-label",
      for: $options.fieldId
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8 /* PROPS */, _hoisted_3)
  ]))
}