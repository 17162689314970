Covers
  @srs_1.1 @srs_3.1 @srs_5.1 @srs_6.1 @srs_15.3 @srs_15.4 @srs_15.6 @srs_22.1 @srs_24.1

Conceptually operates like HTML radio buttons but looks a bit more like tabs
visually. Usage:

    <options-filter label="Status"
      v-model="query.status_eq" :options="['Completed', 'Pending']" />

If the values you want to submit in the model aren't the same as the labels
you want to display you can provide make each option a two element array where
the first value is the label and the second is the value. For example

    <options-filter label="Active/Inactive"
      v-model="query.active_eq" :options="[['Active', 'true'], ['Inactive', 'false']]" />

<template>
  <container :label="label" :hint="hint">
    <select @change="$emit('update:modelValue', optionValue())" v-bind="$attrs" ref="select">
      <option v-if="blank" value=""></option>
      <option v-for="option in normalizedOptions" :value="option[1]">
        {{ option[0] }}
      </option>
    </select>
  </container>
</template>

<script>
import Container from 'index/filter/container'
import $ from 'jquery'

export default {
  inheritAttrs: false,
  props: ['label', 'options', 'modelValue', 'blank', 'hint'],
  emits: ['update:modelValue'],
  components: { Container },
  mounted() { this.updateSelect2() },
  computed: {
    normalizedOptions() {
      return this.options.map(option => {
        return Array.isArray(option) ? option : [option, option]
      })
    }
  },
  methods: {
    optionValue() {
      const select = this.$refs.select
      if( select.multiple )
        return [...select.selectedOptions].map(o => o.value)
      else
        return select.value
    },

    // Normally we would just do `<select value="value"...` but select2 doesn't
    // deal well when the underlying select is changed outside of the jquery
    // way. This reads the Vue value and sends it to select2 via jQuery.
    updateSelect2() {
      $(this.$refs.select).val(this.modelValue)
    },
  },
  watch: {
    modelValue: {
      deep: true,
      handler() { this.updateSelect2() }
    },
  }
}
</script>
