// Covers
//   @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

/*
 * Unobtrusive JS to make something that is not a link act like a link. If
 * an element has the data attribute `href` set with a value this will cause
 * clicks on that element (even if not a real link) to navigate to the page
 * canceling all other default behavior by the browser.
 */

function linkishHandler(event) {
  const href = event.target.dataset.href
  if( !href ) return

  event.preventDefault()
  event.stopImmediatePropagation()
  Turbo.visit(href)
}

document.addEventListener('click', linkishHandler, { capture: true })
