// Covers
//   @srs_7.1 @srs_7.2 @srs_8.1 @srs_9.1 @srs_7.1 @srs_12.1 @srs_14.1 @srs_14.2 @srs_14.5

import InventoryUnit from 'transactions/inventory_unit'
import Transaction from 'transactions/transaction'

export default {
  watch: {
    groupedItems: function() {
      const errMsg = 'This entry is duplicated by another'
      this.eachItemWithDupFlag((item, isDup) => {
        const errors = new Set(item.errors)
        if( isDup )
          errors.add(errMsg)
        else
          errors.delete(errMsg)
        item.errors = [...errors]
      })
    },
  },

  computed: {
    isSendable() { return this.hasSendableItems && !this.hasErrors },
    isSubmittable() { return !this.sending && !this.hasErrors },
    hasErrors() { return this.items.some(item => item.errors.length > 0) },
    hasSendableItems() { return this.sendableItems.length > 0 },
    sendableItems() { return this.items.filter(i => i.isSendable()) },

    groupedItems() {
      return this.items.reduce((memo, item) => {
        if( item.isIdentifiable() ) {
          const key = `${item.unit_number}_${item.product_code}`
          return { ...memo, [key]: [...(memo[key] || []), item] }
        } else {
          return memo
        }
      }, {})
    },
  },

  methods: {
    async processingSend(callback) {
      this.sending = true
      try {
        await callback()
      } finally {
        this.sending = false
      }
    },

    newTransaction() {
      const transaction = new Transaction()
      transaction.id = this.id
      transaction.items = this.sendableItems.map(item => item.payload())
      return transaction
    },

    baseLoad(transactionType) {
      return {
        id: this.transaction.id,
        items: [
          new InventoryUnit(),
          ...this.transaction.items_payload.map(item => {
            const iu = new InventoryUnit(item)
            iu.validate(transactionType)
            return iu
          }),
        ],
        sending: false,
      }
    },

    reset(type) {
      Turbo.visit(`/${type}/transactions/new?submitted=true`)
    },

    async ensureItemsValidated(transactionType) {
      for( const item of this.items )
        if( item.isPopulated() && !item.validated )
          item.validate(transactionType)
    },

    nextItem() { this.items.unshift(new InventoryUnit()) },
    deleteItem(item) {
      this.items = this.items.filter(i => {
        // Is the same object if all values are the same. Easy but inefficent
        // method to determine that is to serialize both to JSON.
        return JSON.stringify(i) !== JSON.stringify(item)
      })
    },

    eachItemWithDupFlag(callback) {
      for( const dups of Object.values(this.groupedItems) )
        for( const dup of dups )
          callback(dup, dups.length > 1)
    },
  }
}
