// Covers
//   @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

import { findFieldContainer } from './common'
import getFieldValue from './get_value'

// Set the specified field to the new value. `undefined` will clear out.
// Will emit `input` event to cascade hooks
export default function(currentFieldId, scope, field, newVal) {
  // Despite callback this is not async. The callback is just a side effect
  // from the jailed library
  getFieldValue(currentFieldId, scope, field, existing => {
    // JSON to deep compare regardless of types. Only update and emit event
    // if actually making a change.
    if( JSON.stringify(existing) == JSON.stringify(newVal) ) return

    const container = findFieldContainer(currentFieldId, scope, field)
    const set = setVal[container.dataset.type] || setVal.default
    set(container, newVal)

    // If self-updating no need to trigger `input` or we end up in infinate
    // loop but we still do want to bubble the input event up to the form. If
    // updating another field then trigger input on that field so it can casade
    // run any hooks it has (and it will still bubble up to the form).
    let triggerElement
    const cascadeEvent = new Event('input', { bubbles: true, cancelable: true })
    if( container.querySelector(`#${currentFieldId}`) ) {
      triggerElement = container.closest('form')
    } else {
      triggerElement = container.querySelector('input, textarea, select')
    }

    triggerElement.dispatchEvent(cascadeEvent)
  })
}

const setVal = {
  checkboxes: (c, v)=> {
    if( !(v instanceof Array) ) v = [v]
    c
      .querySelectorAll('input[type=checkbox]')
      .forEach(fld => fld.checked = v.includes(fld.value))
  },
  file: (_c, _v)=> { throw new Error('Cannot set file value') },
  radio_buttons: (c, v)=> {
    c.querySelectorAll('input[type=radio]').forEach(fld => fld.checked = false)
    const fld = c.querySelector(`input[type=radio][value="${v}"]`)
    if( fld ) fld.checked = true
  },
  multi_select_list: (c, v)=> {
    if( !(v instanceof Array) ) v = [v]

    const fld = c.querySelector('select')
    fld.querySelectorAll('option').forEach(fld => fld.selected = v.includes(fld.value))

    // Manually trigger event so emulated `select2` widget will update UI
    const domEvent = new Event('change', { bubbles: true, cancelable: true })
    fld.dispatchEvent(domEvent)
  },
  select_list: (c, v)=> {
    c.querySelectorAll('select option').forEach(fld => fld.selected = false)
    const fld = c.querySelector(`select option[value="${v}"]`)
    if( fld ) fld.selected = true
  },
  switch: (c, v)=> c.querySelector('[type=checkbox]').checked = v,
  default: (c, v)=> c.querySelector('input, textarea').value = v,
}
setVal.yes_no = setVal.radio_buttons
