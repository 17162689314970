Covers:
  @srs_8.1

Client-side component to construct a "Make Available" transaction

<template>
  <layout :is-submittable="isSubmittable" @submit="send">
    <template #actions>
      <import @record="items.push($event)" transaction-type="make_available" />
    </template>

    <template #params>
      <location v-model="locationId" :locations="locations" />
    </template>

    <list :items="items" transaction-type="make_available"
      @next-item="nextItem()" @delete-item="deleteItem($event)" />
  </layout>
</template>

<script>
import Layout from './layout'
import Import from './import'
import List from './list/basic'
import Location from './params/location'
import Common from 'transactions/common'

export default {
  mixins: [Common],
  props: ['transaction', 'locations'],
  data: function () {
    return Object.assign({
      locationId: this.transaction.params.location_id,
    }, this.baseLoad('make_available'))
  },
  methods: {
    async send() {
      this.processingSend(async ()=> {
        await this.ensureItemsValidated('make_available')
        if( !this.isSendable ) return

        const transaction = this.newTransaction()
        transaction.type = 'make_available'
        transaction.location_id = this.locationId
        await transaction.send()

        this.reset('make_available')
      })
    },
  },
  components: { Layout, Import, List, Location }
}
</script>
