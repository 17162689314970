Covers
  @srs_20.5 @srs_25.2

Display form for field editing and PDF preview

<template>
  <div class="row">
    <div class="col-12 col-md-5">
      <div class="list-group">
        <field v-for="(field, idx) in dFields"
          :field="field" :objects="objects"
          @remove="removeField(idx)" />
      </div>

      <new-button tag="button" class="mt-3" id="new-field" @click="addField()">Add Field</new-button>
    </div>

    <div class="col-12 col-md-7">
      <panel>
        <template #title><h3>Preview</h3></template>

        <pdf-viewer :href="href" ref="viewer" />
      </panel>
    </div>

    <input type="hidden" :name="fieldName" :value="fieldsJSON" />
  </div>
</template>

<script>
import Field from './field'
import NewButton from 'bootstrap/new_button'
import Panel from 'bootstrap/panel'
import PdfViewer from 'pdf_viewer'
import { assign } from 'url_search_params'

export default {
  props: ['fields', 'fieldName', 'objects', 'previewHref'],
  components: { Field, Panel, PdfViewer, NewButton },
  data() {
    return {
      dFields: this.fieldsWithNormalizedSchema(),
    }
  },
  methods: {
    addField() {
      const blank = { object: this.defaultObject, field: '', x: 0, y: 0, format: '' }
      this.dFields.push(blank)
    },
    removeField(idx) { this.dFields.splice(idx, 1) },

    // Since the schema if flexible we might have old data that doesn't
    // populate all fields so provide a default value for all fields
    fieldsWithNormalizedSchema() {
      return this.fields.map(field => {
        field.object ??= this.defaultObject
        field.field ??= ''
        field.x ??= 0
        field.y ??= 0
        field.format ??= ''

        return field
      })
    },
  },
  computed: {
    populatedFields() { return this.dFields.filter(f => f.field.length > 0) },
    fieldsJSON() { return JSON.stringify(this.populatedFields) },
    defaultObject() { return this.objects[0][0] },
    href() {
      const url = new URL(this.previewHref, location.href)
      assign(url.searchParams, 'fields', this.populatedFields)
      return url.toString()
    },
  }
}
</script>
