Covers
  @srs_1.5

Each row resulting from search on impersonation component. Conceptually acts
like a regular `input[type=radio]` allowing use of the `v-model` directive but
will have additional styling as well as the ability to indicate the default
organization.

<template>
  <div class="row" :title="organization.name">
    <div class="col">
      <radio
        :value="organization.id" :checked="modelValue == organization.id"
        @change="$emit('update:modelValue', organization.id)">
        <span v-for="chunk in highlightChunks" :class="{ 'font-weight-bold': chunk.match }">{{ chunk.text }}</span>
      </radio>
    </div>

    <div class="col-1">
      <styled-button
        icon="award-fill" title="Toggle Default" size="sm" priority="secondary"
        :outline="isDefault" @click="toggleDefault()" />
    </div>
  </div>
</template>

<script>
import highlight from 'highlight'
import StyledButton from 'bootstrap/styled_button'
import Radio from 'bootstrap/radio'

export default {
  props: ['organization', 'modelValue', 'search', 'defaultOrganizationId'],
  emits: ['update:modelValue', 'update:default-organization-id'],
  computed: {
    highlightChunks: function() {
      return highlight(this.search, this.organization.name)
    },

    isDefault: function() {
      return this.defaultOrganizationId == this.organization.id
    },
  },
  methods: {
    toggleDefault() {
      let newDefault

      if( this.isDefault ) {
        newDefault = null
      } else {
        newDefault = this.organization.id
      }

      this.$emit('update:default-organization-id', newDefault)
    },
  },
  components: { StyledButton, Radio },
}
</script>
