Covers
  @srs_1.1 @srs_1.3 @srs_1.9

Component to allow the password on a user to be set.

<template>
  <div>
    <styled-button priority="secondary" purpose="success" class="btn-block"
      icon="arrow-repeat" data-toggle="modal" data-target="#password-modal">
      Reset Password
    </styled-button>

    <modal id="password-modal" ref="modal" @submit="submit" @close="close">
      <template #header>Reset Password</template>

      <field label="Password">
        <input type="password" v-model="password" required />
      </field>

      <field label="Confirm password">
        <input type="password" v-model="passwordConfirmation" required />
      </field>

      <template #footer>
        <styled-button priority="primary" type="submit">Save</styled-button>
      </template>
    </modal>
  </div>
</template>

<script>
import StyledButton from 'bootstrap/styled_button'
import Modal from 'bootstrap/modal'
import Field from 'bootstrap/field'
import { notice, alert } from 'flash'
import api from 'api'

export default {
  props: ['userId'],
  components: { StyledButton, Modal, Field },
  data() {
    return {
      password: null,
      passwordConfirmation: null,
    }
  },
  methods: {
    async submit() {
      this.$refs.modal.processing = true

      const [response, body] = await api(
        `users/${this.userId}/password`,
        {
          method: 'PATCH', allowedErrors: [422],
          body: { password: this.password, password_confirmation: this.passwordConfirmation },
        }
      )

      if( response.ok ) {
        this.$refs.modal.close()
        notice('Password updated')
      } else {
        alert(body.errors, { container: `#${this.$refs.modal.id} .modal-notification` })
      }

      this.$refs.modal.processing = false
    },

    close() {
      this.password = null
      this.passwordConfirmation = null
    },
  },
}
</script>
