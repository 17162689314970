import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_styled_button = _resolveComponent("styled-button")

  return (_openBlock(), _createBlock(_component_styled_button, {
    priority: "primary",
    tag: "a",
    size: $props.size
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode("View")
      ])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["size"]))
}