import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-header" }
const _hoisted_2 = ["data-target"]
const _hoisted_3 = { class: "pl-1 pr-3 title-text" }
const _hoisted_4 = ["id", "data-parent"]
const _hoisted_5 = { class: "card-body" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_fade = _resolveComponent("fade")
  const _component_pagination = _resolveComponent("pagination")
  const _component_overlay = _resolveComponent("overlay")

  return (_openBlock(), _createBlock(_component_overlay, {
    show: $data.loading,
    class: "card my-0"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", null, [
          _createElementVNode("button", {
            class: "btn",
            "data-toggle": "collapse",
            "data-target": `#${$options.panelId}`
          }, [
            ($props.icon)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.icon), { key: 0 }))
              : _createCommentVNode("v-if", true),
            _createElementVNode("span", _hoisted_3, _toDisplayString($props.name), 1 /* TEXT */),
            _createVNode(_component_fade, null, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("small", null, _toDisplayString($props.count) + " results ", 513 /* TEXT, NEED_PATCH */), [
                  [_vShow, typeof $props.count !== 'undefined']
                ])
              ]),
              _: 1 /* STABLE */
            })
          ], 8 /* PROPS */, _hoisted_2)
        ])
      ]),
      _createElementVNode("div", {
        id: $options.panelId,
        class: "collapse",
        "data-parent": $props.panelGroupId ? `#${$props.panelGroupId}` : undefined,
        ref: "collapsiblePanel"
      }, [
        _withDirectives(_createElementVNode("div", _hoisted_5, [
          ($data.rows.length > 0)
            ? _renderSlot(_ctx.$slots, "default", {
                key: 0,
                rows: $data.rows
              })
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(" No records found ")
              ], 64 /* STABLE_FRAGMENT */)),
          _createVNode(_component_pagination, {
            "previous-uri": $data.previousUri,
            "next-uri": $data.nextUri,
            onNavigate: _cache[0] || (_cache[0] = $event => ($data.dataUri = $event))
          }, null, 8 /* PROPS */, ["previous-uri", "next-uri"])
        ], 512 /* NEED_PATCH */), [
          [_vShow, $data.loaded]
        ])
      ], 8 /* PROPS */, _hoisted_4)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["show"]))
}