Covers:
  @srs_1.1 @srs_5.1 @srs_6.1 @srs_15.3 @srs_15.4 @srs_20.2 @srs_22.1

Primary goal is to just reduce boilerplate in outputting a stacked table. Also
ensures the stacked_table `data` annotations are initialized.

<template>
  <table class="table table-stacked table-sm align-middle table-borderless table-vcenter" :class="{ 'table-striped': striped }">
    <slot />
  </table>
</template>

<script>
import 'stacked_table/init'
import 'stacked_table/style'

export default {
  props: {
    striped: { default: true }
  }
}
</script>
