// Covers
//   @srs_1.1 @srs_1.10 @srs_5.1 @srs_6.1 @srs_8.1 @srs_9.1 @srs_12.1 @srs_23.1

import '@material/mwc-fab'
document.addEventListener('click', event => {
  const button = event.target.closest('mwc-fab')
  if( !button ) return

  if( button.getAttribute('disabled') ) return

  const form = event.target.closest('form')
  if( !form ) return;

  const submitEvent = new SubmitEvent(
    'submit',
    { submitter: button, bubbles: true, cancelable: true }
  )
  form.dispatchEvent(submitEvent)
});
