import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  title: "required"
}
const _hoisted_4 = {
  key: 1,
  class: "form-text text-muted"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: $options.fieldId,
          class: "control-label"
        }, [
          _createTextVNode(_toDisplayString($props.label) + " ", 1 /* TEXT */),
          ($data.required)
            ? (_openBlock(), _createElementBlock("abbr", _hoisted_3, "*"))
            : _createCommentVNode("v-if", true)
        ], 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _renderSlot(_ctx.$slots, "default"),
    ($props.hint)
      ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString($props.hint), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}