import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_resize_sensor = _resolveComponent("resize-sensor")
  const _component_overlay = _resolveComponent("overlay")

  return (_openBlock(), _createBlock(_component_overlay, {
    show: $data.loading || $data.rendering
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([{ rendered: $options.rendered }, "pdf-container"])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.pages.length, (i) => {
          return _withDirectives((_openBlock(), _createElementBlock("canvas", {
            key: i,
            ref_for: true,
            ref: "pages",
            class: "my-3 shadow-sm"
          })), [
            [_vShow, $data.pages[i-1]]
          ])
        }), 128 /* KEYED_FRAGMENT */)),
        _createVNode(_component_resize_sensor, { onResize: $options.renderAll }, null, 8 /* PROPS */, ["onResize"])
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["show"]))
}