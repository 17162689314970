import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["disabled", "name"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_checkbox = _resolveComponent("checkbox")

  return (_openBlock(), _createElementBlock("div", {
    id: $props.id,
    class: "field-group"
  }, [
    _createElementVNode("input", {
      disabled: _ctx.$attrs.disabled,
      name: $options.fieldName,
      type: "hidden",
      value: "",
      autocomplete: "off"
    }, null, 8 /* PROPS */, _hoisted_2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.optionValues, (optionValue) => {
      return (_openBlock(), _createBlock(_component_checkbox, _mergeProps({
        key: optionValue,
        name: $options.fieldName,
        checked: $options.isChecked(optionValue),
        value: optionValue,
        onChange: $event => (_ctx.$emit('update:modelValue', $options.checkboxChange(optionValue, $event)))
      }, _ctx.$attrs), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(optionValue), 1 /* TEXT */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["name", "checked", "value", "onChange"]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 8 /* PROPS */, _hoisted_1))
}