// If existing text in rich text editor, force cursor to be at the end on autofocus
document.addEventListener('trix-initialize', (event) => {
  // Only if we autofocus the editor should we move to the end since it is
  // assumed autofocus means the user is ready to type. If not focused then
  // where the user clicks when they provide focus is the right place to type.
  if( !event.target.hasAttribute('autofocus') ) return

  const editor = event.target.editor
  editor.setSelectedRange(0) // First give it focus
  editor.setSelectedRange(editor.getDocument().getLength() - 1) // Not to the end
})
