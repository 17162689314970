import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", { id: $options.panelGroupId }, [
    _renderSlot(_ctx.$slots, "default", {
      panelGroupId: $options.panelGroupId,
      counts: $data.counts
    })
  ], 8 /* PROPS */, _hoisted_1))
}