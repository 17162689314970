// Covers
//  @srs_22.1 @srs_24.1

const relevantSelectors = 'input:not([type]), input[type=text], textarea'

const map = {
         //          superscript     subscript
        '0'        : ['\u2070',   '\u2080'      ],
        '1'        : ['\u00B9',   '\u2081'      ],
        '2'        : ['\u00B2',   '\u2082'      ],
        '3'        : ['\u00B3',   '\u2083'      ],
        '4'        : ['\u2074',   '\u2084'      ],
        '5'        : ['\u2075',   '\u2085'      ],
        '6'        : ['\u2076',   '\u2086'      ],
        '7'        : ['\u2077',   '\u2087'      ],
        '8'        : ['\u2078',   '\u2088'      ],
        '9'        : ['\u2079',   '\u2089'      ],
        'a'        : ['\u1d43',   '\u2090'      ],
        'b'        : ['\u1d47',   '?'           ],
        'c'        : ['\u1d9c',   '?'           ],
        'd'        : ['\u1d48',   '?'           ],
        'e'        : ['\u1d49',   '\u2091'      ],
        'f'        : ['\u1da0',   '?'           ],
        'g'        : ['\u1d4d',   '?'           ],
        'h'        : ['\u02b0',   '\u2095'      ],
        'i'        : ['\u2071',   '\u1d62'      ],
        'j'        : ['\u02b2',   '\u2c7c'      ],
        'k'        : ['\u1d4f',   '\u2096'      ],
        'l'        : ['\u02e1',   '\u2097'      ],
        'm'        : ['\u1d50',   '\u2098'      ],
        'n'        : ['\u207f',   '\u2099'      ],
        'o'        : ['\u1d52',   '\u2092'      ],
        'p'        : ['\u1d56',   '\u209a'      ],
        'q'        : ['?',        '?'           ],
        'r'        : ['\u02b3',   '\u1d63'      ],
        's'        : ['\u02e2',   '\u209b'      ],
        't'        : ['\u1d57',   '\u209c'      ],
        'u'        : ['\u1d58',   '\u1d64'      ],
        'v'        : ['\u1d5b',   '\u1d65'      ],
        'w'        : ['\u02b7',   '?'           ],
        'x'        : ['\u02e3',   '\u2093'      ],
        'y'        : ['\u02b8',   '?'           ],
        'z'        : ['?',        '?'           ],
        'A'        : ['\u1d2c',   '?'           ],
        'B'        : ['\u1d2e',   '?'           ],
        'C'        : ['?',        '?'           ],
        'D'        : ['\u1d30',   '?'           ],
        'E'        : ['\u1d31',   '?'           ],
        'F'        : ['?',        '?'           ],
        'G'        : ['\u1d33',   '?'           ],
        'H'        : ['\u1d34',   '?'           ],
        'I'        : ['\u1d35',   '?'           ],
        'J'        : ['\u1d36',   '?'           ],
        'K'        : ['\u1d37',   '?'           ],
        'L'        : ['\u1d38',   '?'           ],
        'M'        : ['\u1d39',   '?'           ],
        'N'        : ['\u1d3a',   '?'           ],
        'O'        : ['\u1d3c',   '?'           ],
        'P'        : ['\u1d3e',   '?'           ],
        'Q'        : ['?',        '?'           ],
        'R'        : ['\u1d3f',   '?'           ],
        'S'        : ['?',        '?'           ],
        'T'        : ['\u1d40',   '?'           ],
        'U'        : ['\u1d41',   '?'           ],
        'V'        : ['\u2c7d',   '?'           ],
        'W'        : ['\u1d42',   '?'           ],
        'X'        : ['?',        '?'           ],
        'Y'        : ['?',        '?'           ],
        'Z'        : ['?',        '?'           ],
        '+'        : ['\u207A',   '\u208A'      ],
        '-'        : ['\u207B',   '\u208B'      ],
        '='        : ['\u207C',   '\u208C'      ],
        '('        : ['\u207D',   '\u208D'      ],
        ')'        : ['\u207E',   '\u208E'      ],
    }

function cancel(event) {
  event.preventDefault()
  event.stopImmediatePropagation()
  return true
}

const last = str => str.slice(-1)
const replaceLast = (str, replacement) => str.slice(0, -1) + replacement

function applyUnicode(element, idx) {
  element.value = replaceLast(element.value, map[last(element.value)][idx])

  // Dispatch an input event so anything else that might care about the change
  // can be notified.
  const event = new InputEvent('input', { bubbles: true, cancelable: true })
  element.dispatchEvent(event)
}

const applySuperscript = e => applyUnicode(e, 0)
const applySubscript = e => applyUnicode(e, 1)

document.addEventListener('keydown', (event)=> {
  if( !event.target.matches(relevantSelectors) ) return
  if( !event.ctrlKey ) return

  if( event.key == 'u' ) cancel(event) && applySuperscript(event.target)
  if( event.key == 'l' ) cancel(event) && applySubscript(event.target)
}, true)
