import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock(_Transition, {
    css: false,
    onBeforeEnter: $options.beforeEnter,
    onBeforeLeave: $options.ensureFade,
    onEnter: $options.enter,
    onLeave: $options.leave
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["onBeforeEnter", "onBeforeLeave", "onEnter", "onLeave"]))
}