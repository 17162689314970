Covers
  @srs_20.5

Creates a dropdown button that lets the user search for and select a canned note
that will be inserted into the nearest trix-editor with data-use-canned-notes=true
at the current location of the caret.

<template>
  <div class="dropdown">
    <input ref="formHandle" type="hidden" />
    <button class="btn dropdown-toggle"
      data-purpose="success" data-priority="secondary"
      data-toggle="dropdown" data-icon="plus"
      aria-haspopup="true" aria-expanded="false">
      Insert Canned Note
    </button>

    <div class="dropdown-menu dropdown-menu-right p-3 vw-100">
      <div class="dropdown-item p-1">
        <search v-model="query.search">
          <ul class="list-unstyled">
            <li>Comment Name</li>
          </ul>
        </search>
        <small>Click note name below to add</small>
      </div>
      <results initial-uri="canned_notes?per_page=12" :query="query"
        ref="results" :no-empty-query="false">
        <template #default="{ rows }">
          <div v-for="row in rows" :key="row.id"
            class="dropdown-item canned-note-item" tabindex="0"
            @click="insertCannedNote" :data-content="row.content"
            @keypress.enter="cannedNoteKeypress">
            {{ row.name }}
          </div>
        </template>
      </results>
    </div>
  </div>
</template>

<script>
import Results from 'index/results'
import Search from 'index/filter/search'

export default {
  components: {
    Search, Results,
  },
  props: ['searchExamples'],
  data() {
    return {
      query: { search: '' },
    }
  },
  computed: {
    parentForm() {
      return this.$refs.formHandle.form
    },
    textArea() {
      return this.parentForm.querySelector('[data-use-canned-notes=true]')
    },
    trixEditor() {
      return this.textArea.editor
    }
  },
  methods: {
     insertCannedNote(event) {
      this.trixEditor.insertHTML(event.target.dataset.content)
      this.query.search = ''
    },
    cannedNoteKeypress(event) {
      this.insertCannedNote(event)
      this.textArea.click()
    }
  }
}
</script>
