// Covers
//   @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

import { createApp, h } from 'vue'

import Confirm from 'bootstrap/confirm'

import onNodeRemove from 'on_node_remove'

export default function confirm(msg, callback) {
  const containerNode = document.querySelector('.toast-positioning')
  containerNode.innerHTML = ''

  if( window.lastNotice ) window.lastNotice.unmount()

  window.lastNotice = createApp({
    render() { return h(Confirm, { onApprove: callback }, ()=> msg) }
  })
  window.lastNotice.mount(containerNode)

  onNodeRemove(containerNode, ()=> {
    if( window.lastNotice ) window.lastNotice.unmount()
    window.lastNotice = null
  })
}
