import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_styled_button = _resolveComponent("styled-button")
  const _component_toast = _resolveComponent("toast")

  return (_openBlock(), _createBlock(_component_toast, { closable: true }, {
    footer: _withCtx(() => [
      _createVNode(_component_styled_button, {
        priority: "secondary",
        "data-dismiss": "toast"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Cancel")
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_styled_button, {
        priority: "primary",
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('approve'))),
        "data-dismiss": "toast"
      }, {
        default: _withCtx(() => [
          _createTextVNode("OK")
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }))
}