Covers
  @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

Vue component for managing a single field_schema definition for a UserDefinedField in the application.
It provides "new schema" and "remove schema" buttons when appropriate

<template>
  <div class="single-field-schema-container field-schema-management-container">
    <field-schema v-if="fieldSchema"
      :fieldSchema="fieldSchema" :needs-delete-button="true"
      :visiblity-types-allowed="visibilityTypesAllowed" :option-lists="optionLists"
      @delete-schema="$emit('delete-schema', fieldSchema)"
      @update:field-schema="$emit('update:field-schema', $event)" />
    <div v-else class="mb-3">
      <new-button tag="button" @click="$emit('init-schema')">
        Add Custom Input
      </new-button>
    </div>
  </div>
</template>

<script>
// Components
import NewButton from 'bootstrap/new_button'
import FieldSchema from './field_schema'

export default {
  components: {
    FieldSchema, NewButton
  },
  props: {
    'fieldSchema': {},
    'visibilityTypesAllowed': { default() { return ['Summary', 'Report', 'Search Results'] } },
    'optionLists': {},
  },
  emits: ['delete-schema', 'init-schema', 'update:field-schema'],
}
</script>
