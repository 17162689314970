Covers:
  @srs_9.1

Display provided list of reasons. A model can be bound to this component the
same as a native `select` HTML component.

<template>
  <field label="Reason">
    <select :value="modelValue" @change="$emit('update:modelValue', $event.target.value)" required :disabled="reasons.length === 0">
      <option value="">-- Select Reason --</option>
      <option v-for="reason in reasons" :key="reason.id" :value="reason.id">
        {{ reason.name }}
      </option>
    </select>
  </field>
</template>

<script>
import Field from 'bootstrap/field'

export default {
  props: ['modelValue', 'reasons'],
  emits: ['update:modelValue'],
  watch: {
    reasons(_newReasons, _oldReasons) {
      this.$emit('update:modelValue', '')
    },
  },
  components: { Field },
}
</script>
