Covers
  @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

Input widget that allows the user to choose a pre-defined list of options or
input custom options.

<template>
  <field label="Options List" :hint="hint">
    <select class="mb-3" @change="updateOptions($event.target.value)">
      <option :selected="isBlank" value="">-- Blank --</option>
      <option :selected="isCustom" value="0">Custom</option>
      <option v-for="list in optionLists" :key="list.id"
        :value="list.id" :selected="list.id == fieldSchema.list_id">
        {{ list.name }}
      </option>
    </select>

    <collapse v-show="isCustom">
      <option-values :optionValues="fieldSchema.option_values" />
    </collapse>
  </field>
</template>

<script>
import Field from 'bootstrap/field'
import Collapse from 'bootstrap/collapse'

import OptionValues from './option_values'

export default {
  props: ['fieldSchema', 'optionLists'],
  components: { OptionValues, Collapse, Field },
  computed: {
    isBlank() { return !this.fieldSchema.list_id && !this.fieldSchema.option_values },
    isCustom() { return this.fieldSchema.option_values },
    hint() { if( this.isCustom && this.fieldSchema.option_values.length > 1 ) return 'Use drag-n-drop to sort' },
  },
  methods: {
    updateOptions(value) {
      if( value == "" ) {
        this.fieldSchema['list_id'] = null
        this.fieldSchema['option_values'] = null
      } else if( value == "0" ) {
        this.fieldSchema['list_id'] = null
        this.fieldSchema['option_values'] = []
      } else {
        this.fieldSchema['list_id'] = parseInt(value)
        this.fieldSchema['option_values'] = null
      }
    }
  },
}
</script>
