Covers
  @srs_22.1

Grid configuration for either a static or dynamic grid. Allows test record
fields to be defined. A column can be marked as the "interpretation" column.

For static grid also allows the number of records to be specified and a label
for each record.

<template>
  <panel>
    <input type="hidden" :name="`${formScope}[record_schema_json]`" :value="JSON.stringify(dRecordSchema)" />
    <input type="hidden" :name="`${formScope}[record_labels_json]`" :value="JSON.stringify(dRecordLabels)" />
    <input type="hidden" :name="`${formScope}[dynamic]`" :value="!!dDynamic" />

    <template #title>
      <div class="d-flex">
        <div class="flex-grow-1">Data Grid</div>
        <div v-if="dRecordSchema.length > 0 && !newRecord" class="mr-3">
          <a href="result_tag/print_forms" class="text-light">
            Manage Result Tags
          </a>
        </div>
        <div><checkbox v-model="dDynamic">Dynamic?</checkbox></div>
      </div>
    </template>

    <table class="table table-sm align-middle table-borderless table-vcenter table-striped">
      <colgroup>
        <col v-if="fixed" style="width: 15rem" />
        <col v-if="!hasFields" />
        <col v-for="_udf in dRecordSchema" />
        <col class="min-width" />
      </colgroup>

      <thead><tr>
        <th v-if="fixed"></th>
        <th v-if="!hasFields"></th>
        <th v-for="(udf, idx) in dRecordSchema" :key="udf.id">
          <div v-if="dRecordSchema.length > 1" class="float-right mt-2 mr-3">
            <styled-button v-if="idx > 0"
              icon="caret-left" :link="true" class="mt-0 p-0 border-0"
              size="normal" title="Move Left"
              @click="swapColLeft(idx)" />
            <move />
            <styled-button v-if="idx < columnCount - 1"
              icon="caret-right" :link="true" class="mt-0 p-0 border-0"
              size="normal" title="Move Right"
              @click="swapColRight(idx)" />
          </div>

          {{ udf.title }}
          <abbr v-if="String(udf.required) == 'true'" title="required">*</abbr>
          <more-info-link :url="udf.more_information_url" />

          <styled-button icon="pencil" :link="true" title="Edit Column" @click="showModal(udf.id)" />
        </th>
        <th>
          <new-button tag="button" @click="showModal()" class="text-nowrap" :purpose="null" title="New Column" />
        </th>
      </tr></thead>

      <tbody>
        <tr v-for="(row, idx) in rows">
          <td v-if="fixed">
            <field>
              <!-- use rows[idx] instead of just row so we can modify in-place -->
              <input type="text" v-model="rows[idx]" required placeholder="Label">
            </field>
          </td>
          <td v-if="!hasFields"></td>
          <td v-for="udf in dRecordSchema" :key="udf.id">
            <custom-field :field-schema="udfForPreview(udf)" :show-label="false" :input-name="udf.id" />
          </td>
          <td class="text-center">
            <delete-button v-if="fixed" @click="deleteRow(idx)" />
            <span v-else>...</span>
          </td>
        </tr>
      </tbody>

      <tfoot v-if="fixed">
        <tr>
          <td :colspan="2 + dRecordSchema.length">
            <new-button tag="button" priority="secondary" @click="newRow">
              Add Row
            </new-button>
          </td>
        </tr>
      </tfoot>
    </table>

    <modal ref="schemaEditor" size="lg" @close="finishedEditing">
      <template #header>{{ editorTitle }}</template>

      <field-schema
        v-model:field-schema="editing"
        :key="schemaKey"
        :needs-delete-button="!!editing.id"
        :visibility-types-allowed="['Summary', 'Report']"
        :additional-flags="{ interpretation: 'Use as Test Interpretation' }"
        :option-lists="optionLists"
        @delete-schema="deleteSchema()" />

      <template #footer>
        <styled-button priority="primary" @click="$refs.schemaEditor.close()">Close</styled-button>
      </template>
    </modal>
  </panel>
</template>

<script>
import StyledButton from 'bootstrap/styled_button'
import NewButton from 'bootstrap/new_button'
import DeleteButton from 'bootstrap/delete_button'
import Checkbox from 'bootstrap/checkbox'
import Panel from 'bootstrap/panel'
import Modal from 'bootstrap/modal'
import Field from 'bootstrap/field'
import FieldSchema from 'custom_field_schema/edit_field_ui/field_schema'
import CustomField from 'custom_field_schema/form_field_ui/custom_field'
import MoreInfoLink from 'custom_field_schema/form_field_ui/common/more_info_link'

import Move from 'bootstrap-icons/icons/arrows-move'

export default {
  props: ['formScope', 'recordSchema', 'recordLabels', 'dynamic', 'newRecord', 'optionLists'],
  emits: ['grid-interpreted'],
  components: {
    Panel, StyledButton, NewButton, DeleteButton, MoreInfoLink,
    Checkbox, Modal, Field, FieldSchema, CustomField, Move,
  },
  created() {
    this.setupLabels()

    // Emit before it can even be changed so parent component knows initial state
    this.emitInterpretedState()
  },
  data() {
    return {
      editing: {},
      schemaKey: 1,
      dRecordSchema: this.recordSchema,
      dRecordLabels: this.recordLabels,
      dDynamic: this.dynamic,
    }
  },
  watch: {
    dDynamic() { this.setupLabels() },
  },
  methods: {
    setupLabels() {
      if( this.dDynamic ) this.dRecordLabels = []
      if( this.needsPlaceholderForFixed ) this.newRow()
    },
    newRow() { this.dRecordLabels.push('') },
    deleteRow(idx) { this.dRecordLabels.splice(idx, 1) },
    deleteSchema() {
      this.$refs.schemaEditor.close()
      this.dRecordSchema.splice(this.dRecordSchema.findIndex(udf => udf.id == this.editing.id), 1)
      this.emitInterpretedState()
    },

    swapColLeft(idx) {
      const swapped = this.dRecordSchema[idx-1]
      this.dRecordSchema[idx-1] = this.dRecordSchema[idx]
      this.dRecordSchema[idx] = swapped
    },

    swapColRight(idx) {
      const swapped = this.dRecordSchema[idx+1]
      this.dRecordSchema[idx+1] = this.dRecordSchema[idx]
      this.dRecordSchema[idx] = swapped
    },

    showModal(id) {
      // Hack to work around HTML5 restriction. Ideally we want to just re-use
      // the form to populate the new schema. But if the new data makes a
      // field invalid (such as clearing out the title) the HTML5 validation
      // is triggered and there is no way to say "reset validation" in HTML5.
      // This forces us to get an entirely new form each time it is displayed
      // so state from the previous display cannot affect current display.
      this.schemaKey += 1

      if( id ) {
        this.editing = this.dRecordSchema.find(udf => udf.id == id)
      } else {
        this.editing = {}
      }

      this.$refs.schemaEditor.open()
    },

    finishedEditing() {
      if( this.isAddingField )
        this.dRecordSchema.push(this.editing)
      else if( this.editing.id )
        this.dRecordSchema.splice(this.dRecordSchema.findIndex(udf => udf.id == this.editing.id), 1, this.editing)

      if( this.editing.interpretation ) this.clearOtherInterpretations()
      this.emitInterpretedState()
    },

    emitInterpretedState() { this.$emit('grid-interpreted', this.gridInterpreted) },

    clearOtherInterpretations() {
      this.nonActiveFields.forEach(udf => delete udf.interpretation)
    },

    udfForPreview(udf) {
      const copy = this.deepDup(udf)

      // Remove hook from preview as it will run on load and we don't want it
      // to run in this context.
      delete copy.hook

      return copy
    },

    deepDup(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
  },
  computed: {
    fixed() { return !this.dDynamic },
    needsPlaceholderForFixed() { return this.fixed && !this.hasLabels },
    hasLabels() { return this.dRecordLabels.length > 0 },
    hasFields() { return this.dRecordSchema.length > 0 },
    editorTitle() { return this.editing.title || 'New Column' },
    gridInterpreted() { return this.dRecordSchema.some(udf => String(udf.interpretation) == 'true') },
    nonActiveFields() { return this.dRecordSchema.filter(udf => udf.id != this.editing.id) },
    columnCount() { return this.dRecordSchema.length },

    rows() {
      if( this.dDynamic ) {
        // If dynamic then there are no defined rows. We just want to stub
        // some blank ones in to display the general look assuming the user
        // has added a few rows
        return [null, null, null, null]
      } else {
        return this.dRecordLabels
      }
    },

    isAddingField() {
      return this.editing.id && !this.dRecordSchema.find(udf => udf.id == this.editing.id)
    },
  }
}
</script>
