import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = ["value"]
const _hoisted_3 = { class: "input-group-append" }
const _hoisted_4 = { class: "input-group-text" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", _mergeProps({
      type: "time",
      class: "form-control"
    }, _ctx.$attrs, {
      value: $props.modelValue,
      onInput: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', $event.target.value)))
    }), null, 16 /* FULL_PROPS */, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, [
        _createVNode(_component_icon)
      ])
    ])
  ]))
}