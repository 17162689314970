import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("input", _mergeProps({
    type: "text",
    class: "form-control"
  }, _ctx.$attrs, {
    value: $props.modelValue,
    onKeydown: _cache[0] || (_cache[0] = (...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args))),
    onInput: _cache[1] || (_cache[1] = (...args) => (_ctx.onInput && _ctx.onInput(...args)))
  }), null, 16 /* FULL_PROPS */, _hoisted_1))
}