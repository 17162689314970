Covers
  @srs_15.3

Ship specific view for listing transactions. Adds location to the standard
listing.

<template>
  <transaction-table type="ship" :rows="rows">
    <template #additional-headers>
      <th>Customer</th>
      <th>Order #</th>
    </template>

    <template #default="{ row }">
      <td>{{ row.customer }}</td>
      <td>{{ row.order_num }}</td>
    </template>
  </transaction-table>
</template>

<script>
import TransactionTable from './table'

export default {
  props: ['rows'],
  components: { TransactionTable },
}
</script>
