import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "inline-block p-3 border bg-light mr-3" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon")
  const _component_draggable = _resolveComponent("draggable")
  const _component_container = _resolveComponent("container")
  const _component_styled_button = _resolveComponent("styled-button")
  const _component_modal = _resolveComponent("modal")

  return (_openBlock(), _createBlock(_component_modal, {
    id: $props.id,
    ref: "modal"
  }, {
    header: _withCtx(() => [
      _createTextVNode("Sort " + _toDisplayString($props.typeLabel), 1 /* TEXT */)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_styled_button, {
        priority: "primary",
        onClick: $options.close
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_container, {
        onDrop: $options.updateSort,
        tag: "ul",
        class: "list-group"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item) => {
            return (_openBlock(), _createBlock(_component_draggable, {
              key: item[$props.itemLabelAttr],
              tag: "li",
              class: "list-group-item pl-0"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, [
                  _createVNode(_component_icon)
                ]),
                _createTextVNode(" " + _toDisplayString(item[$props.itemLabelAttr]), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onDrop"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id"]))
}