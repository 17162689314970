// Covers
//   @srs_5.1 @srs_6.1 @srs_15.3 @srs_15.4 @srs_15.5 @srs_15.6 @srs_22.1 @srs_24.1

import { stringify, parse } from 'qs'

/*
 * Vue mixin to persist query state to the URL. Once mixed in intialize `query`
 * param like so:
 *
 * data() {
 *  return {
 *    query: this.fromUrlWithDefaults({ active: true })
 *  }
 * }
 *
 * This will intialize `query` with what is in the URL and using the argument
 * as the default query (query if no querystring). Any changes to the `query`
 * reactive data element are automatically pushed back up into the querystring.
 * User navigation (back button, etc) is automatically handled.
 *
 * If you never call fromUrlWithDefaults then the mixin will assume you don't
 * want to perist the query state to the URL even if mixed into the component.
 */
export default {
  methods: {
    fromUrlWithDefaults(defaults) {
      // Assume if this method is never called then the component doesn't want
      // to persist the query in the URL. This allows a component to decide if
      // this is desired at initialization time.
      this.persistQueryInUrl = true

      // Calculate and save the default querystring outside the reactive data
      // so we can use it when calculating the targetQueryString
      this.defaultQueryString = stringify(defaults)

      if( this.currentQueryString() == '' )
        return defaults
      else
        return parse(this.currentQueryString())
    },

    currentQueryString() { return window.location.search.substring(1) },

    // User navigation happened (back button, etc) restore previous query state
    popState(event) { this.query = event.state }
  },

  mounted() {
    if( !this.persistQueryInUrl ) return

    this.boundPopState = this.popState.bind(this)
    window.addEventListener('popstate', this.boundPopState)
  },

  unmounted() {
    if( !this.persistQueryInUrl ) return

    window.removeEventListener('popstate', this.boundPopState)
  },

  computed: {
    // Calculate desired querystring. If it matches the default querystring then
    // we actually don't need a querystring so an empty string is returned
    targetQueryString() {
      const qs = stringify(this.query)
      if( qs == this.defaultQueryString ) return ''
      return qs
    },

    // This is the value that can be given to pushState for the new URL. Is
    // relative so the path is kept (i.e. starts with ?) but if the
    // targetQueryString is blank (default query) then the path has to be
    // sent to `pushState` as just sending an empty string won't clear the
    // existing querystring.
    relativeUrl() {
      if( this.targetQueryString ) {
        return `?${this.targetQueryString}`
      } else {
        return window.location.pathname
      }
    },
  },

  watch: {
    // If the goal querystring does not match the current querystring then the
    // query must have been updated so pushState to update the URL
    targetQueryString() {
      if( !this.persistQueryInUrl ) return

      if( this.targetQueryString != this.currentQueryString() )
        history.pushState(stringify(this.query), '', this.relativeUrl)
    },
  },
}
