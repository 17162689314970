import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.wrap)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.wrapper), { key: 0 }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3 /* FORWARDED */
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]))
}