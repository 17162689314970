import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "field-schema-list-container field-schema-management-container" }
const _hoisted_2 = ["name", "value"]
const _hoisted_3 = { class: "pb-3 text-right" }
const _hoisted_4 = { class: "field-schema-list" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_styled_button = _resolveComponent("styled-button")
  const _component_field_schema = _resolveComponent("field-schema")
  const _component_new_button = _resolveComponent("new-button")
  const _component_sorting_modal = _resolveComponent("sorting-modal")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.fieldName)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          type: "hidden",
          name: $props.fieldName,
          value: JSON.stringify($data.list)
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      ($data.list.length > 1)
        ? (_openBlock(), _createBlock(_component_styled_button, {
            key: 0,
            icon: "sort-down",
            priority: "secondary",
            "data-toggle": "modal",
            "data-target": `#${$options.sortingModalId}`
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Sort ")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["data-target"]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, (fieldSchema, index) => {
        return (_openBlock(), _createBlock(_component_field_schema, {
          key: fieldSchema.id,
          "field-schema": $data.list[index],
          "onUpdate:fieldSchema": $event => (($data.list[index]) = $event),
          "needs-delete-button": true,
          "visibility-types-allowed": $props.visibilityTypesAllowed,
          "option-lists": $props.optionLists,
          onDeleteSchema: $options.removeFieldSchema
        }, null, 8 /* PROPS */, ["field-schema", "onUpdate:fieldSchema", "visibility-types-allowed", "option-lists", "onDeleteSchema"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createVNode(_component_new_button, {
      tag: "button",
      onClick: $options.addFieldSchema
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Add Custom Input ")
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onClick"]),
    _createVNode(_component_sorting_modal, {
      id: $options.sortingModalId,
      "type-label": "User Defined Fields",
      items: $data.list,
      "item-label-attr": "title"
    }, null, 8 /* PROPS */, ["id", "items"])
  ]))
}