Covers
  @srs_8.1 @srs_9.1 @srs_12.1 @srs_14.1 @srs_14.2 @srs_14.5

<template>
  <div v-if="errors.length > 0" class="alert alert-danger mar-no">
    <div v-for="error of errors.values()">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: ['errors'],
}
</script>
