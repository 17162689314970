Covers:
  @srs_3.1

Renders a change in the audit trail for the case where there was no before value

<template>
  <div>
    {{ change.label }} set to
    <span class="font-weight-bold text-info">
      <value :change="change" :value="change.after" />
    </span>
  </div>
</template>

<script>
import Value from './value'

export default {
  props: ['change'],
  components: { Value },
}
</script>
