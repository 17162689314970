Covers
  @srs_1.1 @srs_1.9 @srs_7.5 @srs_8.1 @srs_9.1 @srs_12.1 @srs_20.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

Renders a flash message into the common notification area. This shouldn't
generally be used directly but instead called by the `notice` and `alert`
methods in `flash.js`

<template>
  <div class="toast" role="status" aria-live="polite" aria-atomic="true" data-autohide="false">
    <div class="toast-body">
      <span v-if="icon" class="badge badge-circle badge-lg" :class="`badge-${purpose}`">
        <component :is="icon" />
      </span>

      <span class="toast-message">
        <slot />
      </span>

      <button v-if="closable" type="button" class="btn close" data-dismiss="toast" aria-label="Close">
        <span aria-hidden="true">
          <close-icon />
        </span>
      </button>
    </div>

    <div v-if="$slots.footer" class="toast-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

import CloseIcon from 'bootstrap-icons/icons/x.svg'

// Side effects that install arrive on global objects
import 'arrive'

export default {
  props: ['purpose', 'icon', 'closable'],
  components: { CloseIcon },

  // Monitors for any toast so server-side rendered messages show also
  activateAll() {
    document.arrive('.toast', { existing: true }, e => $(e).toast('show'))
  }
}
</script>
