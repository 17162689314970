import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  value: ""
}
const _hoisted_2 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_container = _resolveComponent("container")

  return (_openBlock(), _createBlock(_component_container, {
    label: $props.label,
    hint: $props.hint
  }, {
    default: _withCtx(() => [
      _createElementVNode("select", _mergeProps({
        onChange: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', $options.optionValue())))
      }, _ctx.$attrs, { ref: "select" }), [
        ($props.blank)
          ? (_openBlock(), _createElementBlock("option", _hoisted_1))
          : _createCommentVNode("v-if", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.normalizedOptions, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            value: option[1]
          }, _toDisplayString(option[0]), 9 /* TEXT, PROPS */, _hoisted_2))
        }), 256 /* UNKEYED_FRAGMENT */))
      ], 16 /* FULL_PROPS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["label", "hint"]))
}