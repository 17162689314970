// Covers
//   @srs_8.1 @srs_9.1

import moment from 'moment'
import InventoryUnit from 'transactions/inventory_unit'

// Parses import data into a inventory unit data structure to be used to
// populate the transaction table.
export default class {
  constructor(raw) { this.raw = raw }

  parse(onRecord) {
    this.raw.split(/\n+/).forEach(function(row) {
      row = row.trim()
      if( row.length == 0 ) return;
      let [unit_number, product_code, blood_type, expiration_at] = row.split(/\s+/)

      // Uses US format. The API only wants ISO format to avoid ambiguity
      // Convert as we are parsing to the standard format. Also assume the
      // end of the day rather than start of the day as date parsing libraries
      // will assume.
      expiration_at = moment(expiration_at, 'MM/DD/YYYY').format('YYYY-MM-DD')
      expiration_at += 'T23:59:59'

      onRecord(new InventoryUnit({ unit_number, product_code, blood_type, expiration_at }))
    })
  }
}
