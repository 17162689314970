import { createTextVNode as _createTextVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_styled_button = _resolveComponent("styled-button")
  const _component_modal = _resolveComponent("modal")

  return (_openBlock(), _createBlock(_component_modal, {
    id: "import-modal",
    ref: "modal",
    onSubmit: $options.submit
  }, {
    header: _withCtx(() => [
      _createTextVNode("Import")
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_styled_button, {
        priority: "primary",
        icon: "upload",
        type: "submit"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Load ")
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("textarea", {
        id: "data",
        class: "form-control",
        rows: "13",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.raw) = $event)),
        required: ""
      }, null, 512 /* NEED_PATCH */), [
        [_vModelText, _ctx.raw]
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onSubmit"]))
}