Covers
  @srs_1.1 @srs_5.1 @srs_6.1 @srs_15.3 @srs_15.4 @srs_15.5 @srs_15.6 @srs_22.1 @srs_24.1

Index filters are surrounded by this component to group them together as well
as make them collapsed in mobile view. Example usage:

    <filters>
      <date-filter .... />
      <option-filter .... />
    </filters>

<template>
  <div class="card collapse" id="filter">
    <div class="card-body">
      <h4 class="card-title">Filter</h4>

      <div ref="container">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // Tweak DOM to add `row` class to container unless slot already has rows
  mounted() {
    if( this.$refs.container.querySelector(':scope > .row') ) return

    this.$refs.container.classList.add('row')
  },
}
</script>
