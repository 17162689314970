// Covers
//   @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

import { meta_value } from 'meta_value'

export function findFieldContainer(currentFieldId, scope, field) {
  // Allow actual container to be passed through if already known
  if( typeof field !== 'string' ) return field

  let container
  if( isID(field) ) {
    container = findFieldContainerById(field)
  } else {
    container = findFieldContainerByLabel(currentFieldId, scope, field)
  }
  if( !container ) throw new Error(`${field} is an invalid field`)

  return container
}

export function fieldMessage(container, msg, type) {
  removeMessage(container)
  if( msg ) insertMessage(container, msg, type)
}

export function backendUri(scope, field) {
  const base = meta_value('backend-uri-base')
  return `${base}/${scope}/fields/${encodeURIComponent(field)}`
}

function findFieldContainerById(field) {
  return document.getElementById(field).closest('.form-group')
}

function findFieldContainerByLabel(currentFieldId, scope, field) {
  scope = document.getElementById(currentFieldId).closest(`.hook-scope-${scope}`)
  if( !scope ) scope = document // Fallback to whole page in case scope not defined
  return scope.querySelector(`.form-group[data-labeled="${CSS.escape(field)}"]`)
}

function insertMessage(container, msg, type) {
  container.querySelectorAll('input, textarea, select, .input-group, .field-group').forEach(f => f.classList.add(`is-${type}`))

  const messageContainer = document.createElement('div')
  messageContainer.classList.add(`${type}-feedback`)
  messageContainer.innerText = msg
  container.appendChild(messageContainer)
}

function removeMessage(container) {
  ['warning', 'invalid'].forEach( type => {
    container.querySelectorAll(`.is-${type}`).forEach(f => f.classList.remove(`is-${type}`))
    let feedback = container.querySelector(`.${type}-feedback`)
    if( feedback ) container.removeChild(feedback)
  })
}

function isID(field) {
  return field.match(/^a\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/)
}
