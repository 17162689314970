// Covers
//   @srs_1.6

// Actual action is abstracted to a callback function prevent issues when try
// to verify other behavior under the testing harness

import start_timeout_check from './timeout.js'
import start_csrf_refresh from './unauthenticated_csrf_refresh'

start_timeout_check(()=> location.href = '/')
start_csrf_refresh(()=> location.reload() )
