// Covers
//   @srs_3.1

import Base from './base'

import { formatTimestamp } from 'date_time_format'

export default class extends Base {
  canFormat() { return this.change.type == 'datetime' }
  formattedValue() { return formatTimestamp(this.value) }
}
