// Covers
//   @srs_1.1 @srs_3.1 @srs_7.5 @srs_8.1 @srs_9.1 @srs_12.1 @srs_15.3 @srs_15.4 @srs_15.5 @srs_20.1 @srs_20.2 @srs_20.3 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

import { createApp } from 'vue'

// Side effects that install arrive on global objects
import 'arrive'

import onNodeRemove from 'on_node_remove'

/*
 * Abstract loading a root vue component. Given a selector and a component it
 * will load that component on the element matching the selector when it
 * appears.
 *
 * Any data attributes are passed as properties to the root element. If the
 * data attribute is parsable by JSON complex data can be passed.
 */
export default function(selector, component) {
  document.arrive(selector, { existing: true }, container => {
    const props = parsedProps(container.dataset)
    const app = createApp(component, props)

    /*
     * Vue 2 replaced the container while Vue 3 appends the container as a child.
     * I prefer the Vue 2 approach as it means we don't end up with an extra
     * container even though generally that container is harmless. To make it
     * even more harmless I'm replacing with a element of the same tag name so
     * it has no classes, ids, data, etc that might cause problems. Still could
     * cause problems with things like direct descendant selectors.
     *
     * There is a way to not have the container using a document fragment.
     * I use this on the progressively enhanced styled buttons. But since
     * document fragments have no parent this means we cannot mount on it if
     * the component queries the page it is being mounted on. This is something
     * I can guarantee on styled buttons but not generally.
     *
     * If the extra container does cause an issue we might look to append by
     * default (for mount compat) but replace if specified.
     */
    const root = document.createElement(container.tagName)
    container.replaceWith(root)
    app.mount(root)
    onNodeRemove(root, ()=> app.unmount())
  })
}

function parsedProps(dataset) {
  const props = {}
  for( let prop in dataset ) {
    try {
      props[prop] = JSON.parse(dataset[prop])
    } catch {
      props[prop] = dataset[prop]
    }
  }
  return props
}
