import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "card-body-header" }
const _hoisted_4 = { class: "tabbed-heading tabbed-heading-rounded" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, [
          _renderSlot(_ctx.$slots, "title")
        ]),
        _renderSlot(_ctx.$slots, "controls")
      ]),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}