// Covers
//   @srs_1.5

import escape from 'escape-string-regexp'

export default function(search, text) {
  if( !search ) return [{ text: text, match: false }]
  if( !text ) return []
  const tokens = search.split(' ')
  const patterns = tokens.map(token => (new RegExp(escape(token))).source)
  const pattern = new RegExp(`\\b(${patterns.join('|')})`, 'gi')
  return text
    .split(pattern)
    .filter(c => c.length > 0)
    .map(c => ({ text: c, match: pattern.test(c) }))
}
