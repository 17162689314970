import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4d9b975"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-group-item" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-10" }
const _hoisted_4 = ["name", "value"]
const _hoisted_5 = ["name", "value"]
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 1,
  class: "text-danger error-message"
}
const _hoisted_8 = {
  key: 2,
  class: "progress upload-progress-container"
}
const _hoisted_9 = ["aria-valuenow"]
const _hoisted_10 = { class: "col-1 text-right" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_delete_button = _resolveComponent("delete-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        ($props.file.isAttachable())
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("input", {
                type: "hidden",
                name: `${$props.name}[][filename]`,
                value: $props.file.fileName
              }, null, 8 /* PROPS */, _hoisted_4),
              _createElementVNode("input", {
                type: "hidden",
                name: `${$props.name}[][signed_id]`,
                value: $props.file.signedId
              }, null, 8 /* PROPS */, _hoisted_5)
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", {
          class: _normalizeClass({ 'pending-delete': $props.file.pendingDelete })
        }, [
          ($props.file.url)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: $props.file.url,
                target: "_blank"
              }, _toDisplayString($props.file.fileName), 9 /* TEXT, PROPS */, _hoisted_6))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString($props.file.fileName), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
        ], 2 /* CLASS */),
        ($props.file.errorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($props.file.errorMessage), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        ($props.file.isUploading())
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", {
                class: "progress-bar progress-bar-striped progress-bar-animated",
                role: "progressbar",
                "aria-value-min": "0",
                "aria-valuemax": "100",
                "aria-valuenow": $props.file.percent(),
                style: _normalizeStyle({ width: `${$props.file.percent()}%` })
              }, null, 12 /* STYLE, PROPS */, _hoisted_9)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        ($options.deletable)
          ? (_openBlock(), _createBlock(_component_delete_button, {
              key: 0,
              confirm: false,
              size: "sm",
              onClick: _cache[0] || (_cache[0] = $event => ($props.file.pendingDelete = true))
            }))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}