import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-control custom-radio" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["for"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", _mergeProps({
      type: "radio",
      class: "custom-control-input",
      id: $options.fieldId
    }, _ctx.$attrs), null, 16 /* FULL_PROPS */, _hoisted_2),
    _createElementVNode("label", {
      class: "custom-control-label",
      for: $options.fieldId
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8 /* PROPS */, _hoisted_3)
  ]))
}