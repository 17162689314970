Covers
  @srs_7.5 @srs_7.6 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

  renders a link to a URL populated during UDF setup

<template>
  <a v-if="hasUrl" class="text-muted" target="_blank" :href="url"
    title="More Info" data-toggle="tooltip" v-bind="$attrs">
    <icon />
  </a>
</template>

<script>
import Icon from 'bootstrap-icons/icons/link-45deg.svg'

export default {
  props: ['url'],
  computed: {
    hasUrl() {
      return !!(this.url && this.url.trim())
    }
  },
  components: { Icon },
}
</script>
