// Covers
//   @srs_7.1 @srs_8.1 @srs_9.1 @srs_12.1 @srs_20.1 @srs_20.2

import Base from './base'

export default class extends Base {
  static type = 'product_code'
  static pattern = /=<[EF]\d{4}[0VRSTPrsA1XDLEQ23456][A-Z0-9][a-z0-9]/

  normalizedInput() {
    return super.normalizedInput().replace(/^=</, '')
  }
}
