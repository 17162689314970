Covers:
  @srs_8.1 @srs_9.1 @srs_14.1 @srs_14.2 @srs_14.5

Handles showing a modal to allow import data to be pasted in and imported into
the table for the transaction.

<template>
  <modal id="import-modal" ref="modal" @submit="submit">
    <template #header>Import</template>

    <textarea id="data" class="form-control" rows="13" v-model="raw" required></textarea>

    <template #footer>
      <styled-button priority="primary" icon="upload" type="submit">
        Load
      </styled-button>
    </template>
  </modal>
</template>

<script>
import Modal from 'bootstrap/modal'
import StyledButton from 'bootstrap/styled_button'
import ImportParser from './import/parser'

export default {
  props: ['transactionType'],
  emits: ['record'],
  data: function() {
    return {
      raw: null,
    }
  },
  methods: {
    submit: function() {
      const parser = new ImportParser(this.raw)
      parser.parse(async (record) => {
        await record.validate(this.transactionType)
        this.$emit('record', record)
      })
      this.raw = null
      this.$refs.modal.close()
    },
  },
  components: { Modal, StyledButton },
}
</script>
