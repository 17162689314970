import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "media" }
const _hoisted_2 = { class: "media-body" }
const _hoisted_3 = {
  key: 0,
  type: "button",
  class: "btn close",
  "data-dismiss": "alert",
  "aria-label": "Close"
}
const _hoisted_4 = { "aria-hidden": "true" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_close_icon = _resolveComponent("close-icon")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert", [`alert-${$props.purpose}`, { 'alert-dismissable fade show': $props.closable }]]),
    role: "alert"
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($props.icon)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["badge badge-circle badge mr-2", `badge-${$props.purpose}`])
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent($props.icon)))
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    ($props.closable)
      ? (_openBlock(), _createElementBlock("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_close_icon)
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}