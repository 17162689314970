Covers
  @srs_7.5 @srs_21.4

Input for test grid. Manipulated data stored as standard form fields.

<template>
  <div class="container-fluid">
    <table class="table table-sm align-middle table-borderless table-vcenter table-striped mb-3">
      <colgroup>
        <col v-if="fixed" />
        <col :span="recordSchema.length" />
        <col v-if="showResultTag" class="min-width" />
        <col v-if="deletable" class="min-width" />
      </colgroup>

      <thead><tr>
        <th v-if="fixed"></th>
        <th v-for="udf in recordSchema" :key="udf.id">
          {{ udf.title }}
          <abbr v-if="String(udf.required) == 'true'" title="required">*</abbr>
          <more-info-link :url="udf.more_information_url" />
        </th>
        <th v-if="showResultTag"></th>
        <th v-if="deletable"></th>
      </tr></thead>

      <tbody>
        <template v-for="(record, idx) in dRecords">
          <tr v-if="!record._destroy" :key="record.id || record.fake_id" class="hook-scope-grid-row">
            <td v-if="fixed" class="label">{{ record.label }}</td>
            <td v-for="udf in record.data" :key="udf.id">
              <custom-field :field-schema="udf" :show-label="false" :readonly="readonly"
                :input-name="`${formScope}[${idx}][data_value][${udf.id}]`" />
            </td>
            <td v-if="showResultTag">
              <styled-button v-if="record.id"  icon="printer" tag="a"
                target="_blank" :disabled="!record.required_udf_populated"
                :title="record.required_udf_populated ? null : 'Incomplete'"
                :href="`/api/${apiVersion}/order/test/records/${record.id}/result_tag/print_form`" />
            </td>
            <td v-if="deletable">
              <delete-button @click="deleteRecord(idx)" />
            </td>
          </tr>
        </template>
      </tbody>

      <tfoot v-if="deletable"><tr>
        <td :colspan="footerSpan">
          <new-button tag="button" priority="secondary" @click="newRecord">
            Add Row
          </new-button>
        </td>
      </tr></tfoot>
    </table>

    <template v-if="!readonly">
      <template v-for="(record, idx) in dRecords">
        <input v-if="record.id" type="hidden" :name="`${formScope}[${idx}][id]`" :value="record.id" />
      </template>

      <template v-for="(record, idx) in dRecords">
        <input v-if="record._destroy" type="hidden" :name="`${formScope}[${idx}][_destroy]`" value="true" />
      </template>
    </template>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

import { apiVersion } from 'api'
import StyledButton from 'bootstrap/styled_button'
import NewButton from 'bootstrap/new_button'
import DeleteButton from 'bootstrap/delete_button'
import CustomField from 'custom_field_schema/form_field_ui/custom_field'
import MoreInfoLink from 'custom_field_schema/form_field_ui/common/more_info_link'

export default {
  props: ['formScope', 'dynamic', 'records', 'recordSchema', 'readonly', 'showResultTag'],
  components: { NewButton, DeleteButton, CustomField, StyledButton, MoreInfoLink },
  data() {
    return {
      dRecords: this.records,
    }
  },
  mounted() {
    // It looks odd with no records. The first one is free
    if( this.dynamic && this.dRecords.length == 0 ) this.newRecord()
  },
  methods: {
    newRecord() {
      const schemaCopy = JSON.parse(JSON.stringify(this.recordSchema))
      this.dRecords.push({ data: schemaCopy, fake_id: uuid() })
    },
    deleteRecord(idx) {
      if( this.dRecords[idx].id )
        this.dRecords[idx]['_destroy'] = true
      else
        this.dRecords.splice(idx, 1)
    },
  },
  computed: {
    apiVersion() { return apiVersion },
    fixed() { return !this.dynamic },
    deletable() { return this.dynamic && !this.readonly },
    footerSpan() {
      let cols = this.recordSchema.length
      if( this.deletable ) cols++
      if( this.showResultTag ) cols++
      return cols
    },
  },
}
</script>
