Covers
  @srs_1.1 @srs_1.10

Root component for the user admin search screen. Displays list of users.
Provides search options to filter list.

<template>
  <div>
    <filters>
      <search v-model="query.search">
        <ul class="list-unstyled">
          <li>Name</li>
          <li>Email</li>
          <li>Phone</li>
          <li>Organization</li>
          <li>Permission Role</li>
        </ul>
      </search>
      <options-filter label="Status"
        v-model="query.active_eq" :options="options" />
    </filters>

    <results initial-uri="users?per_page=12" :query="query" ref="results">
      <template #default="{ rows }">
        <div class="row row-cols-xs-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
          <div v-for="row in rows" :key="row.id" class="col my-3">
            <card>
              <template #title>
                {{ row.first_name }} {{ row.last_name }}
              </template>

              <template #controls>
                <delete-button v-if="row.active" class="button-circle" @click="remove(row.id)" />
                <restore-button v-else @click="restore(row.id)" />
              </template>

              <dl>
                <dt>Email</dt>
                <dd>{{ row.email }}</dd>
                <dt>Organization</dt>
                <dd>{{ row.organization_name }}</dd>
              </dl>

              <view-button :href="`users/${row.id}/edit`" class="btn-block" />
            </card>
          </div>
        </div>
      </template>
    </results>
  </div>
</template>

<script>
import api from 'api'
import ViewButton from 'bootstrap/view_button'
import DeleteButton from 'bootstrap/delete_button'
import RestoreButton from 'bootstrap/restore_button'
import Card from 'bootstrap/card'
import Filters from 'index/filters'
import Search from 'index/filter/search'
import OptionsFilter from 'index/filter/options'
import Results from 'index/results'
import QueryPersistence from 'index/query_persistence'

export default {
  mixins: [QueryPersistence],
  components: {
    Filters, Search, OptionsFilter, Results, Card,
    ViewButton, DeleteButton, RestoreButton,
  },
  data() {
    return {
      query: this.fromUrlWithDefaults({
        search: '',
        active_eq: 'true',
      })
    }
  },
  computed: {
    options() {
      return [
        ['Active', 'true'],
        ['Inactive', 'false'],
      ]
    },
  },
  methods: {
    async remove(user_id) {
      await api(`users/${user_id}`, { method: 'DELETE' })
      await this.$refs.results.load()
    },

    async restore(user_id) {
      await api(`users/${user_id}/restore`, { method: 'PATCH' })
      this.$refs.results.load()
    },
  },
}
</script>
