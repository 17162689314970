import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-6" }
const _hoisted_3 = { class: "col-sm-6" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_import = _resolveComponent("import")
  const _component_location = _resolveComponent("location")
  const _component_reason = _resolveComponent("reason")
  const _component_reason_lookup = _resolveComponent("reason-lookup")
  const _component_field = _resolveComponent("field")
  const _component_list = _resolveComponent("list")
  const _component_layout = _resolveComponent("layout")

  return (_openBlock(), _createBlock(_component_layout, {
    "is-submittable": _ctx.isSubmittable,
    onSubmit: $options.send
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_import, {
        onRecord: _cache[0] || (_cache[0] = $event => (_ctx.items.push($event))),
        "transaction-type": "quarantine"
      })
    ]),
    params: _withCtx(() => [
      _createVNode(_component_reason_lookup, {
        type: "quarantine",
        "location-id": _ctx.locationId,
        locations: $props.locations,
        onChange: _cache[3] || (_cache[3] = $event => ($options.updateReasons($event)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_location, {
                modelValue: _ctx.locationId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.locationId) = $event)),
                locations: $props.locations
              }, null, 8 /* PROPS */, ["modelValue", "locations"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_reason, {
                modelValue: _ctx.reasonId,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.reasonId) = $event)),
                reasons: _ctx.reasons
              }, null, 8 /* PROPS */, ["modelValue", "reasons"])
            ])
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["location-id", "locations"]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_field, null, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.comment) = $event)),
                placeholder: "Comment...",
                rows: "5"
              }, null, 512 /* NEED_PATCH */), [
                [_vModelText, _ctx.comment]
              ])
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_list, {
        items: _ctx.items,
        "transaction-type": "quarantine",
        onNextItem: _cache[5] || (_cache[5] = $event => (_ctx.nextItem())),
        onDeleteItem: _cache[6] || (_cache[6] = $event => (_ctx.deleteItem($event)))
      }, null, 8 /* PROPS */, ["items"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-submittable", "onSubmit"]))
}