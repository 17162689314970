// Covers
//   @srs_7.1 @srs_8.1 @srs_9.1 @srs_12.1 @srs_15.3 @srs_15.4 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

import moment from 'moment-timezone'

export function formatDate(date) {
  return moment(date).format('L')
}

export function formatTimestamp(timestamp) {
  return moment(timestamp).format('L HH:mm z')
}
