import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon")

  return ($options.hasUrl)
    ? (_openBlock(), _createElementBlock("a", _mergeProps({
        key: 0,
        class: "text-muted",
        target: "_blank",
        href: $props.url,
        title: "More Info",
        "data-toggle": "tooltip"
      }, _ctx.$attrs), [
        _createVNode(_component_icon)
      ], 16 /* FULL_PROPS */, _hoisted_1))
    : _createCommentVNode("v-if", true)
}