Covers
  @srs_6.1

Root component for the reason admin screen. Displays list of reasons. Since
the list of reasons is expected to be small we don't need a text search but
we will still toggle between active and inactive.

<template>
  <div>
    <filters>
      <options-filter label="Status"
        v-model="query.active_eq" :options="options" />
    </filters>

    <results :initial-uri="`${type}/reasons`" :query="query" ref="results">
      <template #default="{ rows }">
        <stacked-table class="results-table">
          <thead>
            <tr>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="row in rows" :key="row.id">
              <td>{{ row.name }}</td>
              <td class="min-width">
                <view-button :href="`reasons/${row.id}/edit`" />
                <delete-button v-if="row.active" @click="remove(row.id)" />
                <restore-button v-else @click="restore(row.id)" />
              </td>
            </tr>
          </tbody>
        </stacked-table>
      </template>
    </results>
  </div>
</template>

<script>
import api from 'api'
import StackedTable from 'stacked_table/component'
import ViewButton from 'bootstrap/view_button'
import DeleteButton from 'bootstrap/delete_button'
import RestoreButton from 'bootstrap/restore_button'
import Filters from 'index/filters'
import OptionsFilter from 'index/filter/options'
import Results from 'index/results'
import QueryPersistence from 'index/query_persistence'

export default {
  mixins: [QueryPersistence],
  props: ['type'],
  components: {
    Filters, OptionsFilter, Results, ViewButton,
    DeleteButton, RestoreButton, StackedTable
  },
  data() {
    return {
      query: this.fromUrlWithDefaults({
        active_eq: 'true',
      })
    }
  },
  computed: {
    options() {
      return [
        ['Active', 'true'],
        ['Inactive', 'false'],
      ]
    }
  },
  methods: {
    async remove(user_id) {
      await api(`${this.type}/reasons/${user_id}`, { method: 'DELETE' })
      this.$refs.results.load()
    },

    async restore(user_id) {
      await api(`${this.type}/reasons/${user_id}/restore`, { method: 'PATCH' })
      this.$refs.results.load()
    },
  },
}
</script>
