Covers
  @srs_3.1

When the audit presenter only want to present a single message vs a before/after
value this component is used to render that message.

<template>
  <div class="font-weight-bold text-info">{{ change.message }}</div>
</template>

<script>
export default {
  props: ['change'],
}
</script>
