// Covers
//   @srs_7.1 @srs_8.1 @srs_9.1 @srs_12.1 @srs_20.1 @srs_20.2

import Base from './base'

const mapping = {
  'O-':  '95 91 92 93 94 96 97 98',
  'O+':  '51 47 48 49 50 52 53 54',
  'A-':  '06 02 03 04 05 07 08 09',
  'A+':  '62 58 59 60 61 63 64 65',
  'B-':  '17 13 14 15 16 18 19 20',
  'B+':  '73 69 70 71 72 74 75 76',
  'AB-': '28 24 25 26 27 29 30 31',
  'AB+': '84 80 81 82 83 85 86 87',
}

const lookup = {}
for( let [blood_type, codes] of Object.entries(mapping) )
  for( let code of codes.split(/\s+/) )
    lookup[code] = blood_type

export default class extends Base {
  static type = 'blood_type'
  static pattern = /=%(\w{2})[A-Z0-9]{2}/

  normalizedInput() {
    return lookup[this.constructor.completePattern().exec(super.normalizedInput())[1]]
  }
}
