import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_radio = _resolveComponent("radio")

  return (_openBlock(), _createElementBlock("div", {
    class: "radio-buttons field-group",
    id: $props.id
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.optionValues, (optionValue) => {
      return (_openBlock(), _createBlock(_component_radio, _mergeProps({
        key: optionValue,
        value: optionValue,
        onChange: $event => (_ctx.$emit('update:modelValue', optionValue)),
        checked: $options.isChecked(optionValue)
      }, _ctx.$attrs), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(optionValue), 1 /* TEXT */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["value", "onChange", "checked"]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 8 /* PROPS */, _hoisted_1))
}