Covers:
  @srs_1.1 @srs_1.9 @srs_8.1 @srs_9.1 @srs_12.1

For cases when we have a form being handled client-side but we still want to
take advantage of HTML5 form validation. Prevents the default submission of the
form but still triggers reporting the validity. Emits a `submit` event for
further handling of the form when submitted.

<template>
  <form @submit.prevent="submit" ref="form">
    <slot />
  </form>
</template>

<script>
export default {
  emits: ['submit'],
  methods: {
    submit(event) {
      if( !this.$refs.form.reportValidity() ) return
      this.$emit('submit', event)
    },
  }
}
</script>
