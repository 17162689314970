// Covers:
//   @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4 @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1
import Base from './base'

export default class extends Base {

  constructor(attributes) {
    super(attributes)
    this.type = 'text_box'
    this.enableFlag('searchable')
    this.enableFlag('required')
  }

}
