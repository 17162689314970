Covers:
  @srs_15.5

Conditionally wraps content with a component.

  <conditional-wrapper :wrap="shouldBeWrapping" :wrapper="wrapperComponent">
    Content to be wrapped
  </conditional-wrapper>

Regardless of the state the the content to be wrapped will appear in the
final output. If `shouldBeWrapping` is `false` then that content will just be
wrapped by a div. If `shouldBeWrapping` is `true` then the content will be
wrapped by the component pointed to be wrapperComponent.

NOTE: Referring to an imported component directly in a Vue template is not
possible as that template is not in the same scope. You need to bring it into
the scope of the Vue object. The easiest way to do this is via a method or
computed attribute.

<template>
  <component :is="wrapper" v-if="wrap">
    <slot />
  </component>
  <div v-else>
    <slot />
  </div>
</template>

<script>
export default {
  props: ['wrap', 'wrapper'],
}
</script>
