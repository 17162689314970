Covers
  @srs_15.3

Make available specific view for listing transactions. Adds location to the
standard listing.

<template>
  <transaction-table type="make_available" :rows="rows">
    <template #additional-headers>
      <th>Location</th>
    </template>

    <template #default="{ row }">
      <td>{{ row.location }}</td>
    </template>
  </transaction-table>
</template>

<script>
import TransactionTable from './table'

export default {
  props: ['rows'],
  components: { TransactionTable },
}
</script>
