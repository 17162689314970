Covers:
  @srs_9.1 @srs_9.2

Client-side component to construct a "Quarantine" transaction

<template>
  <layout :is-submittable="isSubmittable" @submit="send">
    <template #actions>
      <import @record="items.push($event)" transaction-type="quarantine" />
    </template>

    <template #params>
      <reason-lookup type="quarantine" :location-id="locationId" :locations="locations" @change="updateReasons($event)">
        <div class="row">
          <div class="col-sm-6">
            <location v-model="locationId" :locations="locations" />
          </div>

          <div class="col-sm-6">
            <reason v-model="reasonId" :reasons="reasons" />
          </div>
        </div>
      </reason-lookup>

      <div class="row">
        <div class="col-12">
          <field>
            <textarea v-model="comment" placeholder="Comment..." rows="5" />
          </field>
        </div>
      </div>
    </template>

    <list :items="items" transaction-type="quarantine"
      @next-item="nextItem()" @delete-item="deleteItem($event)" />
  </layout>
</template>

<script>
import Layout from './layout'
import Import from './import'
import List from './list/basic'
import Location from './params/location'
import Reason from './params/reason'
import ReasonLookup from './params/reason_lookup'
import Field from 'bootstrap/field'
import Common from 'transactions/common'

export default {
  mixins: [Common],
  props: ['transaction', 'locations'],
  data: function () {
    return Object.assign({
      locationId: this.transaction.params.location_id,
      loadedReasonId: this.transaction.params.reason_id,
      comment: this.transaction.params.comment,
      reasonId: '',
      reasons: [],
    }, this.baseLoad('quarantine'))
  },
  methods: {
    async updateReasons(reasons) {
      this.reasons = reasons
      await this.$nextTick()
      this.reasonId = this.loadedReasonId
    },

    async send() {
      this.processingSend(async ()=> {
        await this.ensureItemsValidated('quarantine')
        if( !this.isSendable ) return

        const transaction = this.newTransaction()
        transaction.type = 'quarantine'
        transaction.location_id = this.locationId
        transaction.reason_id = this.reasonId
        transaction.comment = this.comment
        await transaction.send()

        this.reset('quarantine')
      })
    },
  },
  components: { Layout, Import, List, Location, Reason, ReasonLookup, Field }
}
</script>
