Covers
  @srs_7.1 @srs_7.2 @srs_7.4 @srs_7.6 @srs_8.1 @srs_9.1 @srs_12.1

Allows a inventory unit to be scanned in. Put the focus into the barcode
field and the different parts of the inventory unit can be scanned. Once a new
record is started the parent is notified of the previous recoreds completion.
Once identifying params are scanned, a lookup is done to fill in other bits
(blood type, expiration) if already in system.

This field is given the autofocus so if everything else is already filled in
the user can simply start scanning without messing with the focus.

<template>
  <div class="barcode">
    <errors :errors="inventoryUnit.errors" />

    <div :class="{ 'bg-danger': hasErrors, 'active': !hasErrors }">
      <overlay :show="validating">
        <overlay :show="searching">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><icon /></span>
                </div>
                <input type="text" class="form-control" autofocus
                  id="barcode" v-model="value"
                  @input="check" @keydown="discardControl" />
              </div>
            </div>
          </div>
        </overlay>

        <div class="row">
          <div class="col-3">{{ inventoryUnit.unit_number || '...' }}</div>
          <div class="col-3">{{ inventoryUnit.product_code || '...'}}</div>
          <div class="col-2">{{ inventoryUnit.blood_type || '...' }}</div>
          <div class="col-3">{{ inventoryUnit.formattedExpirationAt() || '...' }}</div>
          <div class="col-1 text-right">
            <delete-button @click="inventoryUnit.reset()" />
          </div>
        </div>

        <custom-field-list v-if="inventoryUnit.additional_information"
          :field-schema-list="inventoryUnit.additional_information" />
      </overlay>
    </div>
  </div>
</template>

<script>
import Icon from 'bootstrap-icons/icons/upc.svg'
import Overlay from 'overlay'
import DeleteButton from 'bootstrap/delete_button'
import api from 'api'

import Errors from './errors'
import UnitNumber from 'barcode/unit_number'
import ProductCode from 'barcode/product_code'
import BloodType from 'barcode/blood_type'
import Expiration from 'barcode/expiration'

import CustomFieldList from 'custom_field_schema/form_field_ui/custom_field_list'

const matchers = [UnitNumber, ProductCode, BloodType, Expiration]

export default {
  props: ['transactionType', 'inventoryUnit'],
  emits: ['complete'],
  components: { Overlay, Errors, DeleteButton, Icon, CustomFieldList },
  data() {
    return {
      value: '',
      searching: false,
      validating: false,
      lookup_processed: false,
    }
  },
  computed: {
    hasErrors() { return this.inventoryUnit.errors.length > 0 },
  },
  methods: {
    check() {
      matchers.forEach(async matcher => {
        const m = new matcher(this.value)
        if( !m.isValid() ) return

        if( matcher.type == 'unit_number' && this.inventoryUnit.unit_number )
          await this.emitComplete()

        this.inventoryUnit[matcher.type] = m.value()
        this.value = ''

        if( ['unit_number', 'product_code'].includes(matcher.type) ) await this.lookup()
        if( this.inventoryUnit.isSendable() ) this.validate()
      })
    },

    discardControl(event) {
      // Discard Enter and Tab control codes
      if( ['Enter', 'Tab'].includes(event.key) ) event.preventDefault()
    },

    async lookup() {
      if( this.lookup_processed ) return
      if( !this.inventoryUnit.isIdentifiable() ) return

      this.searching = true

      const [response, body] = await api(`inventory_units/${this.inventoryUnit.unit_number}/${this.inventoryUnit.product_code}`, {allowedErrors: [403, 404]})
      if( response.ok ) {
        this.inventoryUnit.blood_type ??= body.blood_type
        this.inventoryUnit.expiration_at ??= body.expiration_at
        this.inventoryUnit.additional_information = body.additional_information
      } else {
        // No unit found. Just populate UDF schema
        const [response, body] = await api('user_defined_fields/inventory_unit/additional_information', { allowedErrors: [404] })
        if( response.ok ) this.inventoryUnit.additional_information = body.field_schema
      }

      this.lookup_processed = true
      this.searching = false
    },

    async validate() {
      this.validating = true
      await this.inventoryUnit.validate(this.transactionType)
      this.validating = false
    },

    async emitComplete() {
      if( !this.inventoryUnit.isIdentifiable() ) return
      if( !this.inventoryUnit.validated ) await this.validate()
      this.lookup_processed = false
      this.$emit('complete')
    },
  }
}
</script>
