Covers:
  @srs_8.1 @srs_9.1 @srs_9.2

Display provided list of locations. A model can be bound to this component the
same as a native `select` HTML component.

<template>
  <field label="Location">
    <select :value="modelValue" @change="$emit('update:modelValue', $event.target.value)" required>
      <option value="">-- Select Location --</option>
      <option v-for="location in locations" :key="location.id" :value="location.id">
        {{ location.name }}
      </option>
    </select>
  </field>
</template>

<script>
import Field from 'bootstrap/field'

export default {
  props: ['modelValue', 'locations'],
  emits: ['update:modelValue'],
  created() {
    if( this.locations.length == 1 )
      this.$emit('update:modelValue', this.locations[0].id)
  },
  components: { Field },
}
</script>
