// Covers
//   @srs_7.1 @srs_8.1 @srs_9.1 @srs_20.1 @srs_20.2

import Base from './base'
import moment from 'moment-timezone'

export default class extends Base {
  static type = 'expiration_at'
  static pattern = /&>\d{10}/

  normalizedInput() {
    const input = '2' + super.normalizedInput().replace(/^&>/, '') + '59'
    const iso = moment(input, 'YYYYDDDHHmmss').format()
    if( iso == 'Invalid date' ) return;
    return iso
  }
}
