Covers
  @srs_7.5 @srs_15.5 @srs_20.1 @srs_20.2 @srs_20.3 @srs_20.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

Displays a page within a panel. Has notion of a real-only "show" page and a
"edit" form page to update the data. Example calling code:

  <div class="resource-panel"
    data-default-title="Order Details"
    data-show-uri="<%= summary_order_path(@order) %>"
    data-edit-uri="<%= edit_order_path(@order) %>">
  </div>

Actual panel content is rendered server-side. If content contains a link then
the target is loaded in the panel. If content submits a form then content is
loaded in the panel.

Only one panel can be open at a given time on the same age. Any unsaved content
displays a warning to the user when closing a panel.

<template>
  <overlay :show="loading">
    <div v-if="visible" ref="self"
      class="resource-panel-card card hook-scope-panel" :class="{ open }"
      :data-panel-title="title"
      :data-backend-object="backendObject"
      @refresh.stop="reload">

      <div class="card-header" @click="togglePanel">
        <div class="row">
          <h2 class="col">
            <span class="pr-4">{{ title }}</span>
            <small v-if="subtitle">
              <a :href="subtitleLink" target="_blank" class="text-reset" @click.stop>
                {{ subtitle }}
              </a>
            </small>
          </h2>

          <div class="col-auto">
            <span v-if="complete" class="badge badge-circle badge-light badge-text-success">
              <complete-icon />
            </span>
          </div>

          <div v-if="editUri" class="col-auto">
            <styled-button
              circle="true" icon="chevron-double-down"
              data-toggle="card" />
          </div>
        </div>
      </div>

      <div class="card-body">
        <collapse @showing="replacePanelContent">
          <div
            v-if="!toggling"
            class="card-text"
            ref="panelContainer"
            @hook:submit.stop="loading = true"
            @hook:cancel-submit.stop="loading = false"
            @turbo:submit-start="beforeRemoteForm"
            @turbo:before-fetch-response="afterRemoteForm"
            @click="handleLink"
            @input="markDirty"
            v-html="panelContent">
          </div>
        </collapse>
      </div>
    </div>
  </overlay>
</template>

<script>
import { notice, alert } from 'flash'
import StyledButton from 'bootstrap/styled_button'
import CompleteIcon from 'bootstrap-icons/icons/check2'
import Collapse from 'bootstrap/collapse'
import Overlay from 'overlay'
import $ from 'jquery'

export default {
  props: ['showUri', 'editUri', 'defaultTitle', 'alwaysReloadOnSubmit', 'startOpened', 'backendObject'],
  components: { CompleteIcon, StyledButton, Collapse, Overlay },
  data() {
    return {
      open: this.startOpened,
      loading: false,
      toggling: false,
      complete: false,
      dirty: false,
      visible: true,
      loadedContent: null,
      panelContent: null,
      openModal: null,
      title: this.defaultTitle,
      subtitle: null,
      subtitleLink: null,
    }
  },
  mounted() {
    this.goTo(this.currentUri)

    this.boundReloadIfNeeded = e => this.reloadIfNeeded(e)
    document.addEventListener('panel:submitted', this.boundReloadIfNeeded)

    // Model is implemented via Bootstrap so we have to do event handling with
    // jQuery since it uses non-standard events.
    $(this.$el).on('show.bs.modal', (event)=> this.trackModal(event))
    $(this.$el).on('hide.bs.modal', (event)=> this.clearModalTracking(event))

    // If we start opened then send signal for other panels to close
    if( this.open ) this.fire(this.$el, `panel:open`, this)
  },
  beforeUnmount() {
    document.removeEventListener('panel:submitted', this.boundReloadIfNeeded)
  },
  methods: {
    communicationStart() {
      this.loading = true

      if( this.openModal ) $(this.openModal).modal('hide')
      $(this.$el).find('[data-toggle="popover"]').popover('dispose')
    },

    communicationComplete(response, updateImmediate=true) {
      response.metadata ||= '{ "flash": {} }' // Avoid nil checks
      response.metadata = JSON.parse(decodeURIComponent(response.metadata))
      response.metadata.flash ||= {} // Avoid nil checks

      this.complete = !!response.metadata.complete
      this.dirty = false

      const statusType = Math.floor(response.status / 100)
      const contentType = response.contentType
      const isHTML = contentType.includes('text/html')

      this.fire(this.$el, 'panel:load')

      if( statusType == 2 || (statusType == 4 && isHTML) ) {
        // Display is updating either via Turbo or if HTML we replace
        this.visible = true
        if( isHTML ) {
          this.loadedContent = response.body
          if( updateImmediate ) this.replacePanelContent()
        }
      } else if( statusType == 4 && contentType.includes('application/json') && JSON.parse(response.body)['errors'].includes('Panel not ready') ) {
        // Panel not ready
        this.visible = false
      } else if( isHTML ) {
        // Unexpected HTML returned. Replace entire page.
        this.replacePage(response.body)
      } else {
        // Some other response. Just throw exception with status
        this.loading = false
        throw new Error(`Processing Error: ${response.status}`)
      }

      if( response.metadata.flash.notice ) notice(response.metadata.flash.notice)
      if( response.metadata.flash.alert ) alert(response.metadata.flash.alert)
      if( response.metadata.order_state ) this.fire(this.$el, 'order:state', response.metadata.order_state)
      if( response.metadata.test_actions ) {
        const container = document.getElementById('test-actions')
        if( container ) container.innerHTML = response.metadata.test_actions
      }

      if( response.metadata.title )
        this.title = response.metadata.title
      else
        this.title = this.defaultTitle
      this.subtitle = response.metadata.subtitle
      this.subtitleLink = response.metadata.subtitle_link

      this.loading = false
    },

    async goTo(uri, updateImmediate=true) {
      this.communicationStart()

      const options = { headers: { 'X-Resource-Panel-Request': this.currentState } }
      const response = await fetch(uri, options)
      const body = await response.text()

      this.communicationComplete({
        body,
        status: response.status,
        contentType: response.headers.get('Content-Type'),
        metadata: response.headers.get('X-Resource-Panel-Response')
      }, updateImmediate)
    },

    async togglePanel() {
      if( !this.editUri ) return
      if( this.dirty && this.dirtyCancel() ) return

      this.open = !this.open

      this.toggling = true
      await this.goTo(this.currentUri, false)
      this.toggling = false
    },

    trackModal(event) { this.openModal = event.target },
    clearModalTracking(_event) { this.openModal = null },

    reload() { this.goTo(this.currentUri) },

    reloadIfNeeded(event) {
      // If this is the panel that submitted then don't reload
      if( event.target == this.$el ) return

      // Only reload if invisible or set to always reload
      if( this.visible && !this.alwaysReloadOnSubmit ) return

      this.reload()
    },

    handleLink(event) {
      const link = event.target.closest('a[href]:not([target]):not([data-turbo-stream])')
      if( !link ) return

      event.preventDefault()
      event.stopPropagation()

      this.goTo(link.getAttribute('href'))
    },

    beforeRemoteForm({ target, detail: { formSubmission: { fetchRequest } }}) {
      if( !this.remoteFormRequest(target) ) return
      if( !this.managesForm(target) ) return

      fetchRequest.headers['X-Resource-Panel-Request'] = this.currentState
      this.communicationStart()
    },

    async afterRemoteForm(event) {
      const { target, detail: { fetchResponse } } = event

      if( !this.remoteFormRequest(target) ) return
      if( !this.managesForm(target) ) return

      // If response is requesting page to reload then let normal Turbo Frame
      // take it from here. Having to put this info a HTTP header vs just
      // reading from the body as reading from the body is async and we need to
      // either cancel or not the event synchronously.
      if( fetchResponse.header('X-Reload') ) return

      event.preventDefault()

      this.fire(this.$el, 'panel:submitted')

      this.communicationComplete({
        body: await fetchResponse.responseText,
        status: fetchResponse.statusCode,
        contentType: fetchResponse.header('Content-Type'),
        metadata: fetchResponse.header('X-Resource-Panel-Response'),
      })
    },

    replacePanelContent() {
      // Lock height as we replace the content to prevent page jumping
      if( this.$refs.panelContainer )
        this.$refs.panelContainer.style.height = `${this.$refs.panelContainer.offsetHeight}px`

      // Replace the existing content with the loaded content
      this.panelContent = this.loadedContent

      // Unlock height to allow natural height again once the browser is ready
      // to repaint.
      requestAnimationFrame(()=> {
        if( this.$refs.panelContainer )
          this.$refs.panelContainer.style.height = ''
      })
    },

    replacePage(markup) {
      const htmlElement = document.createElement("html")
      htmlElement.innerHTML = markup

      const { documentElement, head, body } = document
      documentElement.replaceChild(htmlElement.querySelector("head"), head)
      documentElement.replaceChild(htmlElement.querySelector("body"), body)
    },

    markDirty(event) {
      if( !event.target.closest('form') ) return
      event.stopPropagation()

      this.dirty = true
    },

    remoteFormRequest(target) { return !target.matches('form[data-turbo="false"]') },
    managesForm(target) { return target.closest('.resource-panel-card') == this.$refs.self },

    dirtyCancel() { return !confirm('Form changed, proceed?') },

    fire(element, eventName, detail) {
      const event = new CustomEvent(eventName, { bubbles: true, cancelable: true, detail })
      element.dispatchEvent(event)
      return event
    },
  },
  computed: {
    currentUri() { return this.open ? this.editUri : this.showUri },
    currentState() { return this.open ? 'opened' : 'closed' },
    targetState() { return this.open ? 'close' : 'open' },
  },
}
</script>
