// Covers
//   @srs_24.1 @srs_26.1

import Cookies from "js-cookie"
import toSentence from 'array-to-sentence'
import { pluralize, underscore, titleize } from 'inflection'

const cookieName = 'schemas-to-update'

export default {
  // Dynamically calculated based on the cookie as the cookie is the single
  // source of truth. This ensures different browser tabs are in sync.
  get classes() { return new Set(Cookies.get(cookieName)?.split(',') ?? []) },

  add(className) {
    const newCookieValue = [...this.classes.add(className)].join(',')
    Cookies.set(cookieName, newCookieValue, { expires: Infinity, path: '/' })
    this._dispatchEvent()
  },

  clear() {
    Cookies.remove(cookieName)
    this._dispatchEvent()
  },
  isEmpty() { return this.classes.size == 0 },
  description() { return toSentence([...this.classes].map(c => pluralize(titleize(underscore(c))))) },
  toPath() { return [...this.classes].map(underscore).join('/') },

  // The UI listener for this to know when and not to present the trigger
  _dispatchEvent() {
    const event = new CustomEvent('schemas-to-update:changed', { bubbles: true, cancelable: false })
    document.dispatchEvent(event)
  },
}
