Covers
  @srs_1.1 @srs_1.10 @srs_5.1 @srs_6.1 @srs_7.5 @srs_8.1 @srs_9.1 @srs_12.1 @srs_22.1 @srs_24.1 @srs_25.2

<template>
  <styled-button class="btn-circle" title="Delete" :size="size"
    icon="trash-fill" :confirm="confirm" v-bind="$attrs" />
</template>

<script>
import StyledButton from 'bootstrap/styled_button'

export default {
  props: {
    size: {},
    confirm: { default: true },
  },
  components: { StyledButton },
}
</script>
