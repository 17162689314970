Covers:
  @srs_1.1 @srs_1.3 @srs_1.9 @srs_8.1 @srs_9.1 @srs_22.1

Primary goal is to provide the boilerplate for creating a modal easily. General
usage:

  <modal id="my_modal">Modal Content</modal>

Support for both a title and footer HTML is provided via `header` and `footer`
slots:

  <modal id="my_modal">
    <template #header>My Title</template>

    Modal Content

    <template #footer><input type="submit" value="save"></template>
  </modal>

The `id` attribute is optional if you plan to use the `open` and `close` methods
to control rather than Bootstraps' UJS. A `size` can also be specified.

The content and footer are surrounded by a "validate-only-form" allowing you
to use HTML5 validation and triggering via a `submit` button in the footer. If
you want to do more than just prevent the form default and display the
validations the component will also emit a `submit` event.

<template>
  <div class="modal fade" :id="id" ref="modal" tabindex="-1" role="dialog" :aria-labelledby="id + '-label'" aria-hidden="true">
    <div class="modal-dialog" :class="classes" role="document">
      <overlay :show="processing">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" :id="id + '-label'" v-if="$slots.header">
              <slot name="header" />
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-notification" ref="notifications">
            <slot name="notification" />
          </div>

          <validate-only-form @submit="$emit('submit', $event)">
            <div class="modal-body">
              <slot />
            </div>

            <div class="modal-footer" v-if="$slots.footer">
              <slot name="footer" />
            </div>
          </validate-only-form>
        </div>
      </overlay>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import ValidateOnlyForm from 'validate_only_form'
import Overlay from 'overlay'

import $ from 'jquery'

export default {
  props: {
    id: { default() { return uuid() } },
    size: {},
  },
  emits: ['submit', 'close'],
  mounted() { this.$modal().on('hide.bs.modal', ()=> this.cleanup()) },
  data() {
    return { processing: false }
  },
  methods: {
    // Allow calling component to easily open/close
    open() { this.$modal().modal('show') },
    close() { this.$modal().modal('hide') },

    cleanup() {
      this.$refs.notifications.innerHTML = ''
      this.$emit('close')
    },

    $modal() { return $(this.$refs.modal) },
  },
  computed: {
    classes() {
      const cls = []
      if( this.size ) cls.push(`modal-${this.size}`)
      return cls
    },
  },
  components: { ValidateOnlyForm, Overlay }
}
</script>
