// Covers
//   @srs_1.1 @srs_5.1 @srs_15.3 @srs_15.4 @srs_15.5 @srs_15.6 @srs_22.1 @srs_24.1

import { createApp } from 'vue'

const VNodeRenderer = {
  props: ['vnodes'],
  render() { return this.vnodes },
}

/*
 * Renders a collection of vnodes to string. Useful for turning `slot` content
 * into a string. Bootstrap sometimes uses HTML inside of HTML attributes :(.
 * Vue is not designed to support that sort of oddity. This function allows us
 * to handle that by serializing the slot content and placing it in an attribute.
 */
export default function(vnodes) {
  const container = document.createElement('div')
  const renderer = createApp(VNodeRenderer, { vnodes })
  renderer.mount(container)
  const ret = container.innerHTML
  //renderer.unmount()
  return ret
}
