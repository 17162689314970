// Covers
//   @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

/*
 * My ideal would be something like this:
 *
 *   form.addEventListener('submit', async function(event) {
 *     const shouldCancel = await someAsyncActivity()
 *     if( shouldCancel ) event.preventDefault()
 *   })
 *
 * Unfortunately calling `preventDefault` AFTER any async activity will not work
 * AFAIK. This function provides an abstraction to work around this. Usage
 * should look like:
 *
 *   addAsyncSubmitListener(form, async function(event) {
 *     const shouldCancel = await someAsyncActivity()
 *     if( shouldCancel ) event.preventDefault()
 *   })
 *
 * As you can see this is very close to my ideal.
 *
 * Small caviot. IF there is a regular event listener installed BEFORE this
 * event listener DURING the capture phase AND that event listener does not
 * call `preventDefault` consistently then unexpected behavior could occur.
 *
 * Also note you don't have control regarding if your event handler runs during
 * the capture or bubble phase. It is always the capture phase.
 *
 * Calling `stopImmediatePropagation` will not prevent other async event
 * handlers on that element from running. Just regular event handlers.
 *
 * Finally, if the form is removed from the page all event handlers are
 * automatically cleaned up but if you need to remove a specific handler
 * earlier (callback no longer valid) you can use removeAsyncSubmitListener
 * with the same form and callback as an argument.
 *
 */

import onNodeRemove from 'on_node_remove'

export function addAsyncSubmitListener(form, listener) {
  if( !listeners.has(form) ) {
    listeners.set(form, new Set())
    onNodeRemove(form, ()=> listeners.delete(form))
  }

  listeners.get(form).add(listener)
}

export function removeAsyncSubmitListener(form, listener) {
  if( !listeners.has(form) ) return
  listeners.get(form).delete(listener)
}

// All the listeners that have been registered keyed by the related form
const listeners = new Map()

// Feels a bit like a real event but the flags to indicate the various
// directives can be reset allowing a later event to undo the flags set by an
// earlier event.
class ResettableEvent {
  constructor(type) {
    this._type = type
    this.reset()
  }

  preventDefault() { this.defaultPrevented = true }
  stopPropagation() { this.propagationStopped = true }
  stopImmediatePropagation() { this.immediatePropagationStopped = true }

  reset() {
    this.defaultPrevented = false
    this.propagationStopped = false
    this.immediatePropagationStopped = false
  }
}

document.addEventListener('submit', async (event) => {
  const form = event.target.closest('form')

  // Form is not using async submit listeners so return early
  if( !listeners.has(form) ) return

  // Form has already run async listeners
  if( form.dataset.asyncSubmitExecuted == 'true' ) {
    delete form.dataset.asyncSubmitExecuted

    if( form.dataset.preventDefault == 'true' ) {
      event.preventDefault()
      delete form.dataset.preventDefault
    }

    if( form.dataset.propagationStopped == 'true' ) {
      event.stopPropagation()
      delete form.dataset.propagationStopped
    }

    if( form.dataset.immediatePropagationStopped == 'true' ) {
      event.stopImmediatePropagation()
      delete form.dataset.immediatePropagationStopped
    }

    // Return early to hand off to non-async
    return
  }

  // Stop everything to run async callbacks so we can wait for them to complete
  event.preventDefault()
  event.stopImmediatePropagation()

  const asyncEvent = new ResettableEvent('submit')
  await Promise.all([...listeners.get(form)].map(l => l(asyncEvent)))

  if( asyncEvent.defaultPrevented ) form.dataset.preventDefault = 'true'
  if( asyncEvent.propagationStopped ) form.dataset.propagationStopped = 'true'
  if( asyncEvent.immediatePropagationStopped ) form.dataset.immediatePropagationStopped = 'true'

  // Mark async submit listeners as having been run and restart the process
  form.dataset.asyncSubmitExecuted = 'true'

  // Attempt to submit with original submitter but this will only work if
  // the submitter is an HTML submit button so verify this first
  let submitter = event.submitter
  if( !event.submitter || event.submitter.getAttribute('type') != 'submit' ) submitter = null

  setTimeout(()=> form.requestSubmit(submitter))
}, { capture: true })
