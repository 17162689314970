Covers
  @srs_1.1 @srs_5.1 @srs_6.1 @srs_15.4 @srs_15.5 @srs_15.6 @srs_22.1 @srs_24.1

Wraps the actual table of output providing common functions of retrieving the
index data via the API. This is sort of a simplified version of the ResultGroup
component. Props:

* initial-uri - The HTTP endpoint used to retrieve the first page of results.
  It is expected this endpoint will return a list of records along with
  pagination info in the `Link` header using the `prev` and `next` rel values
  as defined in RFC 5988.
* query - Any search params (querystring) to be included with the request.

The provided slot will be given a scope with the actual rows to render. If no
rows this component will take care of indicating that so the slot doesn't need
to handle it.

<template>
  <overlay :show="loading" class="results-panel">
    <div v-if="rows">
      <slot v-if="rows.length > 0" :rows="rows" />
      <template v-else>
        No records found
      </template>

      <pagination :previous-uri="previousUri" :next-uri="nextUri" @navigate="dataUri = $event" />
    </div>
  </overlay>
</template>

<script>
import api from 'api'
import Overlay from 'overlay'
import Pagination from 'bootstrap/pagination'
import PaginationProcessing from 'pagination'

export default {
  mixins: [PaginationProcessing],
  props: ['initialUri', 'query', 'noEmptyQuery'],
  data() {
    return {
      dataUri: this.initialUri,
      previousUri: null,
      nextUri: null,
      rows: null,
      loading: false,
    }
  },
  created() { this.load() },
  watch: {
    dataUri() { this.load() },
    query: {
      deep: true,
      // NOTE: We conditionally load if the data URI changed. If it did not
      // (no pagination reset) then we need to trigger a load of fresh data. If
      // it does change (pagination data needs to be removed) then the update
      // to dataUri will trigger load for us.
      handler() {
        const oldDataUri = this.dataUri
        this.dataUri = this.initialUri
        if( this.dataUri == oldDataUri ) this.load()
      },
    },
  },
  methods: {
    async load() {
      if( this.noEmptyQuery && this.isEmptyQuery() ) {
        this.rows = null
        return
      }

      this.loading = true

      const [response, body] = await api(this.dataUri, { search: this.query })
      this.setPagination(response.headers.get('Link'))
      this.rows = body

      this.loading = false
    },

    isEmptyQuery() {
      for( let key in this.query )
        if( this.query[key] && String(this.query[key]).length > 0 ) return false
      return true
    }
  },
  components: { Overlay, Pagination },
}
</script>
