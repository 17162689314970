import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17228ba9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-auto" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_field = _resolveComponent("field")
  const _component_new_button = _resolveComponent("new-button")
  const _component_OptionValue = _resolveComponent("OptionValue")
  const _component_container = _resolveComponent("container")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_field, { class: "col" }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "new-option-value",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.newOptionValue) = $event)),
            onKeypress: _cache[1] || (_cache[1] = _withKeys((...args) => ($options.addOptionValue && $options.addOptionValue(...args)), ["enter"])),
            placeholder: "New Option Value"
          }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */), [
            [_vModelText, $data.newOptionValue]
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_new_button, {
          class: "add-option",
          tag: "button",
          onClick: $options.addOptionValue
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Add ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ]),
    _createVNode(_component_container, {
      class: "option-value-list",
      "drag-handle-selector": ".sortable-handle",
      onDrop: $options.updateSort
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.optionValues, (optionValue) => {
          return (_openBlock(), _createBlock(_component_OptionValue, {
            key: optionValue,
            optionValue: optionValue,
            onDeleteOption: $options.removeOptionValue
          }, null, 8 /* PROPS */, ["optionValue", "onDeleteOption"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onDrop"])
  ]))
}