import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = ["value"]
const _hoisted_3 = { class: "input-group-append" }
const _hoisted_4 = { class: "input-group-text border-0 bg-transparent" }
const _hoisted_5 = ["data-content"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_container = _resolveComponent("container")

  return (_openBlock(), _createBlock(_component_container, { label: "Search" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("input", {
          type: "search",
          autocomplete: "off",
          value: $props.modelValue,
          onInput: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.debouncedInputChange($event.target.value)), ["stop"]))
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createElementVNode("span", {
              class: "badge badge-circle badge-info",
              "data-toggle": "popover",
              "data-trigger": "hover click",
              "data-placement": "right",
              "data-html": "true",
              "data-container": "body",
              "data-title": "Search By",
              "data-content": $options.renderedHelp()
            }, " ? ", 8 /* PROPS */, _hoisted_5)
          ])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}