Covers:
  @srs_12.1 @srs_12.2

Display provided list of customers. A model can be bound to this component the
same as a native `select` HTML component.

<template>
  <field :label="label">
    <select :value="parseInt(modelValue)" @change="$emit('update:modelValue', $event.target.value)" required>
      <option value="">-- Select Customer --</option>
      <option v-for="customer in customers" :key="customer.id" :value="customer.id">
        {{ customer_label(customer) }}
      </option>
    </select>
  </field>
</template>

<script>
import Field from 'bootstrap/field'

export default {
  props: ['label', 'modelValue', 'customers'],
  emits: ['update:modelValue'],
  components: { Field },
  methods: {
    customer_label(customer) {
      return `${String(customer.id).padStart(4, '0')} - ${customer.name}`
    }
  }
}
</script>
