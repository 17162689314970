import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_field = _resolveComponent("field")

  return (_openBlock(), _createElementBlock("div", {
    class: "col",
    "data-label": $props.label
  }, [
    _createVNode(_component_field, {
      label: $props.label,
      hint: $props.hint
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["label", "hint"])
  ], 8 /* PROPS */, _hoisted_1))
}