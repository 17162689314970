import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_styled_button = _resolveComponent("styled-button")

  return (_openBlock(), _createBlock(_component_styled_button, _mergeProps({
    class: "btn-circle",
    title: "Delete",
    size: $props.size,
    icon: "trash-fill",
    confirm: $props.confirm
  }, _ctx.$attrs), null, 16 /* FULL_PROPS */, ["size", "confirm"]))
}