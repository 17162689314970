// Covers
//   @srs_20.6

document.addEventListener('order:state', event => {
  const title = event.detail.order_title

  const selectors = ['#page-title', '.breadcrumb .active']
  selectors.forEach(selector => {
    const el = document.querySelector(selector)
    if( el ) el.innerText = title
  })
})
