// Covers
//   @srs_1.6

import { meta_value, logged_in } from 'meta_value'

function refresh_unless_logged_in(refresh_behavior) {
  if( logged_in() ) return
  refresh_behavior()
}

export default (refresh_behavior) => {
  // Read from meta value that Rails left us so if we change the duration
  // in the future we can just change Rails and this will follow
  const session_duration = parseInt(meta_value('session_duration')) || 1800

  return setTimeout(()=> refresh_unless_logged_in(refresh_behavior), session_duration * 1000)
}
