import { createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, mergeProps as _mergeProps, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name"]
const _hoisted_2 = ["id", "name"]
const _hoisted_3 = ["for"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["custom-control custom-switch field-group", {'pt-0': $props.noPadding}])
  }, [
    _createElementVNode("input", {
      type: "hidden",
      value: "false",
      name: $props.name
    }, null, 8 /* PROPS */, _hoisted_1),
    _withDirectives(_createElementVNode("input", _mergeProps({
      type: "checkbox",
      class: "custom-control-input",
      value: "true",
      id: $props.id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.currentValue) = $event)),
      onChange: _cache[1] || (_cache[1] = $event => (_ctx.$emit('update:modelValue', $data.currentValue))),
      "true-value": "true",
      "false-value": "false",
      name: $props.name
    }, _ctx.$attrs), null, 16 /* FULL_PROPS */, _hoisted_2), [
      [_vModelCheckbox, $data.currentValue]
    ]),
    _createElementVNode("label", {
      class: "custom-control-label",
      for: $props.id
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8 /* PROPS */, _hoisted_3)
  ], 2 /* CLASS */))
}