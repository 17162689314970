Covers
  @srs_7.5 @srs_20.1 @srs_20.2 @srs_20.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

Display a progress bar representing a subset of the order state. Current state
can be passed in via props. Component will also listen for a `order:state`
event to get dynamic updates on the state.

<template>
  <div class="stepper stepper-showlabels-md stepper-stack-sm">
    <template v-for="step in steps">
      <div class="stepper-step" :class="stateFor(step)" :data-step="step">
        <span class="stepper-step-icon" data-toggle="tooltip" :title="`${step} ${descriptionFor(step)}`"></span>
        <div class="stepper-step-label">
          <strong>{{ step }}</strong> {{ descriptionFor(step) }}
        </div>
      </div>

      <hr />
    </template>
  </div>
</template>

<script>
export default {
  props: ['initialState', 'sampleRequired'],
  data() {
    return this.stateAssign(this.initialState)
  },
  created() {
    if( this.sampleRequired )
      this.steps = ['Order', 'Sample', 'Testing', 'Report']
    else
      this.steps = ['Order', 'Testing', 'Report']
  },
  mounted() {
    this.boundStateUpdate = event => this.stateUpdate(event)
    document.addEventListener('order:state', this.boundStateUpdate)
  },
  unmounted() {
    document.removeEventListener('order:state', this.boundStateUpdate)
  },
  methods: {
    stateUpdate(event) {
      Object.assign(this, this.stateAssign(event.detail))
    },

    stateAssign(data) {
      return {
        state: data.state,
        orderNumber: data.order_number,
      }
    },

    stateFor(step) {
      if( this.notTracking ) return 'not_started'
      return this[`${step.toLowerCase()}State`]
    },

    descriptionFor(step) {
      if( this.notTracking ) return ''
      return this[`${step.toLowerCase()}Description`]
    },
  },
  computed: {
    orderState() { return this.state == 'draft' ? 'incomplete' : 'complete' },
    orderDescription() { return this.orderNumber || 'Pending' },
    noSampleYet() { return ['draft', 'order_received'].includes(this.state) },
    notTracking() { return ['canceled', 'imported'].includes(this.state) },

    sampleState() {
      if( this.noSampleYet ) return 'not_started'
      return this.state == 'sample_received' ? 'incomplete' : 'complete'
    },

    sampleDescription() {
      switch( this.sampleState ) {
        case 'not_started':
          return 'Pending'
        case 'incomplete':
          return 'Received'
        case 'complete':
          return 'Recorded'
      }
      return ''
    },

    testingState() {
      if( this.notTestingYet ) return 'not_started'
      return this.state == 'testing_in_process' ? 'incomplete' : 'complete'
    },

    testingDescription() {
      switch( this.testingState ) {
        case 'not_started':
          return 'Not Started'
        case 'incomplete':
          return 'In-Process'
        case 'complete':
          return 'Finished'
      }
      return ''
    },

    reportState() {
      if( this.state == 'report_finalized' ) return 'complete'
      if( this.state.startsWith('report_') ) return 'incomplete'
      return 'not_started'
    },

    reportDescription() {
      switch( this.state ) {
        case 'report_generated':
          return 'Pending'
        case 'report_prelim_submitted':
          return 'Prelim Review'
        case 'report_final_submitted':
          return 'Final Review'
        case 'report_finalized':
          return 'Final'
        default:
          return 'Not Started'
      }
    },

    notTestingYet() {
      return ['draft', 'order_received', 'sample_received', 'sample_recorded'].includes(this.state)
    }
  }
}
</script>
