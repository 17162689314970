Covers
  @srs_1.5

Dropdown content to allow organization to be searched for impersonation

<template>
  <overlay :show="loading" class="m-sm-3">
    <input type="search" v-model="search"
      autocomplete="off" class="form-control"
      placeholder="Search for an organization to impersonate">

    <collapse>
      <div v-if="organizations.length" class="topbar-impersonate-results">
        <div class="topbar-impersonate-list">
          <result v-for="organization in organizations" v-model="selected"
            :organization="organization" :key="organization.id" :search="search"
            v-model:default-organization-id="defaultOrganizationId" />
        </div>

        <div class="row">
          <div class="col-auto ml-auto mt-3">
            <styled-button
              purpose="primary" icon="box-arrow-up-right"
              :disabled="!selected" @click="impersonate" class="impersonate">
              Impersonate
            </styled-button>
          </div>
        </div>
      </div>
    </collapse>
  </overlay>
</template>

<script>
import debounce from 'debounce'
import api from 'api'
import StyledButton from 'bootstrap/styled_button'
import Collapse from 'bootstrap/collapse'
import Overlay from 'overlay'
import Result from './result'

export default {
  props: ['initialDefaultOrganizationId', 'realOrganizationId'],
  created() { this.debouncedSearchOrgs = debounce(this.searchOrgs, 500) },
  data() {
    return {
      search: '',
      loading: false,
      organizations: [],
      selected: null,
      defaultOrganizationId: this.initialDefaultOrganizationId,
    }
  },
  watch: {
    search: function() {
      this.debouncedSearchOrgs.clear()
      if( this.search == '' ) {
        // Clear out immediately rather than wait for debounce
        this.organizations = []
      } else {
        this.debouncedSearchOrgs(this.search)
      }
    },

    organizations: {
      deep: true,
      handler() {
        if( this.organizations.some(org => org.id == this.selected) ) return
        this.selected = null
      },
    },

    defaultOrganizationId: async function() {
      this.loading = true

      await api(
        'users/me',
        {
          method: 'PATCH',
          body: { default_impersonation_organization_id: this.defaultOrganizationId },
        }
      )

      this.loading = false
    },
  },
  methods: {
    async searchOrgs(search) {
      this.loading = true

      const [_response, body] = await api(
        'organizations',
        {
          search: { search: search },
          headers: {
            'X-Impersonated-Organization-Id': this.realOrganizationId,
          },
        }
      )
      this.organizations = body

      this.loading = false
    },

    async impersonate() {
      this.loading = true

      await api(
        '/profile/impersonation',
        {
          method: 'PATCH',
          body: { organization_id: this.selected }
        }
      )
      Turbo.visit('/')

      this.loading = false
    },
  },
  components: { StyledButton, Overlay, Collapse, Result },
}
</script>
