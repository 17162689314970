Covers
  @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

Vue component for managing the class and field of a UserDefinedField.

<template>
  <panel>
    <field label="Available User Defined Fields">
      <select @change="$emit('input', $event.target.value)" required>
        <option value="0">-- Select Field --</option>
        <option v-for="availableField in availableFields" :key="availableField.display_name" :value="availableField.display_name">
          {{ availableField.display_name }}
        </option>
      </select>
    </field>
  </panel>
</template>

<script>
import Field from 'bootstrap/field'
import Panel from 'bootstrap/panel'

export default {
  components: { Field, Panel },
  emits: ['input'],
  props: ['availableFields']
}
</script>
