import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("input", _mergeProps({
    type: "number",
    class: "form-control"
  }, _ctx.$attrs, {
    value: $props.modelValue,
    onInput: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', $event.target.value)))
  }), null, 16 /* FULL_PROPS */, _hoisted_1))
}