// Covers
//   @srs_7.5 @srs_21.4

export default function(currentFieldId, callback) {
  const row = document.getElementById(currentFieldId).closest('.hook-scope-grid-row')
  if( !row ) return callback(null)

  const label = row.querySelector('td.label')
  if( !label ) return callback(null)

  callback(label.innerText)
}
