import { vModelRadio as _vModelRadio, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "custom-control custom-radio custom-control-inline" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "custom-control custom-radio custom-control-inline" }
const _hoisted_6 = ["id"]
const _hoisted_7 = ["for"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "yes-no-field form-row field-group",
    id: $props.id
  }, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", _mergeProps({
        type: "radio",
        class: "custom-control-input",
        id: $options.yesFieldId,
        value: "true",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.yesNoValue) = $event)),
        onChange: _cache[1] || (_cache[1] = $event => (_ctx.$emit('update:modelValue', 'true')))
      }, _ctx.$attrs), null, 16 /* FULL_PROPS */, _hoisted_3), [
        [_vModelRadio, $data.yesNoValue]
      ]),
      _createElementVNode("label", {
        class: "custom-control-label",
        for: $options.yesFieldId
      }, "Yes", 8 /* PROPS */, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _withDirectives(_createElementVNode("input", _mergeProps({
        type: "radio",
        class: "custom-control-input",
        id: $options.noFieldId,
        value: "false",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.yesNoValue) = $event)),
        onChange: _cache[3] || (_cache[3] = $event => (_ctx.$emit('update:modelValue', 'false')))
      }, _ctx.$attrs), null, 16 /* FULL_PROPS */, _hoisted_6), [
        [_vModelRadio, $data.yesNoValue]
      ]),
      _createElementVNode("label", {
        class: "custom-control-label",
        for: $options.noFieldId
      }, "No", 8 /* PROPS */, _hoisted_7)
    ])
  ], 8 /* PROPS */, _hoisted_1))
}