Covers
  @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4 @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

Reduces some of the bootstrap boilerplate when outputting a switch control in Vue.
It includes a hidden input with the value set to 'false' for when the toggle checkbox is unchecked.

<template>
  <div class="custom-control custom-switch field-group" :class="{'pt-0': noPadding}">
    <input type="hidden" value="false" :name="name" />
    <input type="checkbox" class="custom-control-input" value="true"
      :id="id" v-model="currentValue" @change="$emit('update:modelValue', currentValue)"
      true-value="true" false-value="false" :name="name" v-bind="$attrs" />
    <label class="custom-control-label" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  inheritAttrs: false,
  props: {
    'id': { default() { return `a${uuid()}` } },
    'name': {},
    'modelValue': {},
    'noPadding': { default: false },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      currentValue: this.modelValue
    }
  },
}
</script>
