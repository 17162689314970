// Covers
//   @srs_7.5 @srs_18.2 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

import { DynamicPlugin as sandbox } from 'jailed'

import lockSetFor from './lock_set'
import toggleFieldVisibility from './actions/visibility'
import toggleReadOnly from './actions/readonly'
import getFieldValue from './actions/get_value'
import getBackendValue from './actions/get_backend_value'
import getGridValues from './actions/get_grid_values'
import getGridRowLabel from './actions/get_grid_row_label'
import setFieldValue from './actions/set_value'
import setBackendValue from './actions/set_backend_value'
import warning from './actions/warning'
import error from './actions/error'
import confirm from './actions/confirm'
import afterSave, { clearPreviousCallbacksFor } from './actions/after_save'

// Some methods do not return a value. But we still want to allow the
// possibility of waiting until they are complete before continuing the script.
// Even if the hook writer does not await the callback the infrastructure will
// wait for all remote calls to have been completed.
function resolved(method) {
  return function() {
    const args = Array.from(arguments)
    const callback = args.pop()
    Promise.resolve(method.apply(null, args)).then(callback)
  }
}

// Methods a user-defined script can call
const callbacks = {
  toggleFieldVisibility: resolved(toggleFieldVisibility),
  toggleReadOnly: resolved(toggleReadOnly),
  getFieldValue, getBackendValue,
  getGridValues, getGridRowLabel,
  setFieldValue: resolved(setFieldValue),
  setBackendValue: resolved(setBackendValue),
  warning: resolved(warning),
  error: resolved(error),
  confirm, afterSave, clearPreviousCallbacksFor,
}

const wrappers = `
  ${action('toggleFieldVisibility', withCurrentFieldDefault(2), withScope(3))}
  ${action('toggleReadOnly', withCurrentFieldDefault(2), withScope(3))}
  ${action('getFieldValue', withCurrentFieldDefault(1), withScope(2))}
  ${action('getBackendValue', autoFieldId())}
  ${action('getGridValues', autoFieldId())}
  ${action('getGridRowLabel', autoFieldId())}
  ${action('setFieldValue', withCurrentFieldDefault(2), withScope(3))}
  ${action('setBackendValue', autoFieldId())}
  ${action('warning', withCurrentFieldDefault(2), withScope(3))}
  ${action('error', withCurrentFieldDefault(2), withScope(3))}
  ${action('confirm', { noWait: true })}
  ${action('afterSave', autoFieldId(), withCurrentFieldDefault(3), resolvableCallback(), { noWait: true })}
`

const boilerplate = `
  application.setInterface({
    async run(data, done) {
      // Script to be run
      const script = data.script

      // Return early if no hook actually sent in
      if( !script ) return done()

      // Provide hook id of current field
      const fieldId = data.fieldId

      // Provide event that triggered hook
      const event = data.event

      // List of promises for most calls to the application (those not using
      // the 'noWait' option). This allows the hook to wait for them all to
      // complete so we can signal to the code triggering the hook that the
      // hook was completely executed.
      //
      // Note that some hooks may internally wait for certain actions
      // such as those that return values. But this ensures even if the hook
      // writer doesn't wait we do.
      const remoteCalls = []

      ${wrappers}

      function clearWarning() { warning(...[...arguments, undefined]) }
      function clearError() { error(...[...arguments, undefined]) }

      // Auto-clear previous callbacks made by this field so the hook doesn't
      // have to if the change in state means no callback will be registered
      // the next time around. Only on the 'input' hook since the state will
      // not change for the 'submit' hook and this allows callbacks registered
      // on the 'input' hooks to persist through the submit hook.
      if( event == 'input' )
        application.remote.clearPreviousCallbacksFor(fieldId)

      // Go ahead and lookup value of current field for easy reference
      let value = await getFieldValue()

      // Run the script. We want to use 'eval' as that will capture the closure
      // (unlike the Function or AsyncFunction constructor). Since the script
      // may 'await' for an sync function we need to wrap it in a async
      // function. Then use a promise to wait for that function to resolve.
      await new Promise((resolve, reject) => {
        try {
          eval(\`
            (async function() {
              \${script}
            })().then(resolve)
          \`)
        } catch(e) { reject(e) }
      })

      // Ensure all remote calls have been completed
      await Promise.all(remoteCalls)

      done()
    },
  })
`

let process = null
document.addEventListener('turbo:before-render', ()=> {
  if( process ) process.disconnect()
  process = null
})

// Execute a field hook using the given script against the specified field
export default async function fieldHook(script, fieldId, event) {
  if( !script ) return

  return new Promise(resolve => {
    if( !process ) process = new sandbox(boilerplate, callbacks)
    process.whenConnected(async ()=> {
      lockSetFor(fieldId).clear()
      process.remote.run({ script, fieldId, event }, resolve)
    })
  })
}

function action(method) {
  const args = Array.from(arguments)

  // Optional options can be given to `action`
  if( typeof args[args.length - 1] != 'object' ) args.push({})
  const options = args.pop()

  // By default the wrapper will send the remote method a callback to execute
  // when finished. This both allows a return value and allows for the hook
  // infrastructure to wait for all remote calls to be finished.
  //
  // But if the hook action has a callback used for another purpose than a
  // return value (such as a block of code to execute later) we don't want this
  // behavior since jailed can only call one callback it is given therefore
  // we aren't going to use that on waiting for the action to be run.
  const wait = !options.noWait

  // The rest of the args are the various lines of code to inject into the wrapper
  const code = args.slice(1)

  return `
    function ${method}() {
      const method = '${method}'
      const wait = ${wait}
      const args = Array.from(arguments)

      ${code.join("\n")}

      const promise = new Promise(resolve => {
        if( wait ) args.push(resolve)
        application.remote[method].apply(null, args)
        if( !wait ) resolve()
      })
      remoteCalls.push(promise)
      return promise
    }
  `
}

function autoFieldId() { return 'args.unshift(fieldId)' }

function withScope(argCount) {
  return `
    if( args.length < ${argCount} ) args.unshift('panel')
    args.unshift(fieldId)
  `
}

function withCurrentFieldDefault(argCount) {
  return `if( args.length < ${argCount} ) args.unshift(fieldId)`
}

function resolvableCallback() {
  return `
    const callback = args[args.length-1]
    args[args.length-1] = async function(resolve) {
      await callback()
      resolve()
    }
  `
}
