import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-danger mar-no"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.errors.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.errors.values(), (error) => {
          return (_openBlock(), _createElementBlock("div", null, _toDisplayString(error), 1 /* TEXT */))
        }), 256 /* UNKEYED_FRAGMENT */))
      ]))
    : _createCommentVNode("v-if", true)
}