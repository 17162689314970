import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_import = _resolveComponent("import")
  const _component_location = _resolveComponent("location")
  const _component_list = _resolveComponent("list")
  const _component_layout = _resolveComponent("layout")

  return (_openBlock(), _createBlock(_component_layout, {
    "is-submittable": _ctx.isSubmittable,
    onSubmit: $options.send
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_import, {
        onRecord: _cache[0] || (_cache[0] = $event => (_ctx.items.push($event))),
        "transaction-type": "make_available"
      })
    ]),
    params: _withCtx(() => [
      _createVNode(_component_location, {
        modelValue: _ctx.locationId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.locationId) = $event)),
        locations: $props.locations
      }, null, 8 /* PROPS */, ["modelValue", "locations"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_list, {
        items: _ctx.items,
        "transaction-type": "make_available",
        onNextItem: _cache[2] || (_cache[2] = $event => (_ctx.nextItem())),
        onDeleteItem: _cache[3] || (_cache[3] = $event => (_ctx.deleteItem($event)))
      }, null, 8 /* PROPS */, ["items"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-submittable", "onSubmit"]))
}