// Covers
//  @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4 @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

import CheckboxesField from './field_types/checkboxes'
import DateField from './field_types/date'
import FileField from './field_types/file'
import MultiSelectListField from './field_types/multi_select_list'
import NumberField from './field_types/number'
import OneLineField from './field_types/one_line'
import RadioButtonsField from './field_types/radio_buttons'
import SelectListField from './field_types/select_list'
import SwitchField from './field_types/switch'
import TextBoxField from './field_types/text_box'
import TimeField from './field_types/time'
import YesNoField from './field_types/yes_no'

const fieldMap = {
  'checkboxes': CheckboxesField,
  'date': DateField,
  'file': FileField,
  'multi_select_list': MultiSelectListField,
  'number': NumberField,
  'one_line': OneLineField,
  'radio_buttons': RadioButtonsField,
  'select_list': SelectListField,
  'switch': SwitchField,
  'text_box': TextBoxField,
  'time': TimeField,
  'yes_no': YesNoField,
}

export default function(attributes) {
  return new fieldMap[attributes.type](attributes)
}
