import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "table table-sm align-middle table-borderless table-vcenter table-striped mb-3" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["span"]
const _hoisted_5 = {
  key: 1,
  class: "min-width"
}
const _hoisted_6 = {
  key: 2,
  class: "min-width"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  title: "required"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = {
  key: 0,
  class: "label"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["colspan"]
const _hoisted_16 = ["name", "value"]
const _hoisted_17 = ["name"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_more_info_link = _resolveComponent("more-info-link")
  const _component_custom_field = _resolveComponent("custom-field")
  const _component_styled_button = _resolveComponent("styled-button")
  const _component_delete_button = _resolveComponent("delete-button")
  const _component_new_button = _resolveComponent("new-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("colgroup", null, [
        ($options.fixed)
          ? (_openBlock(), _createElementBlock("col", _hoisted_3))
          : _createCommentVNode("v-if", true),
        _createElementVNode("col", {
          span: $props.recordSchema.length
        }, null, 8 /* PROPS */, _hoisted_4),
        ($props.showResultTag)
          ? (_openBlock(), _createElementBlock("col", _hoisted_5))
          : _createCommentVNode("v-if", true),
        ($options.deletable)
          ? (_openBlock(), _createElementBlock("col", _hoisted_6))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          ($options.fixed)
            ? (_openBlock(), _createElementBlock("th", _hoisted_7))
            : _createCommentVNode("v-if", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.recordSchema, (udf) => {
            return (_openBlock(), _createElementBlock("th", {
              key: udf.id
            }, [
              _createTextVNode(_toDisplayString(udf.title) + " ", 1 /* TEXT */),
              (String(udf.required) == 'true')
                ? (_openBlock(), _createElementBlock("abbr", _hoisted_8, "*"))
                : _createCommentVNode("v-if", true),
              _createVNode(_component_more_info_link, {
                url: udf.more_information_url
              }, null, 8 /* PROPS */, ["url"])
            ]))
          }), 128 /* KEYED_FRAGMENT */)),
          ($props.showResultTag)
            ? (_openBlock(), _createElementBlock("th", _hoisted_9))
            : _createCommentVNode("v-if", true),
          ($options.deletable)
            ? (_openBlock(), _createElementBlock("th", _hoisted_10))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dRecords, (record, idx) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (!record._destroy)
              ? (_openBlock(), _createElementBlock("tr", {
                  key: record.id || record.fake_id,
                  class: "hook-scope-grid-row"
                }, [
                  ($options.fixed)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_11, _toDisplayString(record.label), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.data, (udf) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: udf.id
                    }, [
                      _createVNode(_component_custom_field, {
                        "field-schema": udf,
                        "show-label": false,
                        readonly: $props.readonly,
                        "input-name": `${$props.formScope}[${idx}][data_value][${udf.id}]`
                      }, null, 8 /* PROPS */, ["field-schema", "readonly", "input-name"])
                    ]))
                  }), 128 /* KEYED_FRAGMENT */)),
                  ($props.showResultTag)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_12, [
                        (record.id)
                          ? (_openBlock(), _createBlock(_component_styled_button, {
                              key: 0,
                              icon: "printer",
                              tag: "a",
                              target: "_blank",
                              disabled: !record.required_udf_populated,
                              title: record.required_udf_populated ? null : 'Incomplete',
                              href: `/api/${$options.apiVersion}/order/test/records/${record.id}/result_tag/print_form`
                            }, null, 8 /* PROPS */, ["disabled", "title", "href"]))
                          : _createCommentVNode("v-if", true)
                      ]))
                    : _createCommentVNode("v-if", true),
                  ($options.deletable)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_13, [
                        _createVNode(_component_delete_button, {
                          onClick: $event => ($options.deleteRecord(idx))
                        }, null, 8 /* PROPS */, ["onClick"])
                      ]))
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        }), 256 /* UNKEYED_FRAGMENT */))
      ]),
      ($options.deletable)
        ? (_openBlock(), _createElementBlock("tfoot", _hoisted_14, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", { colspan: $options.footerSpan }, [
                _createVNode(_component_new_button, {
                  tag: "button",
                  priority: "secondary",
                  onClick: $options.newRecord
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Add Row ")
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ], 8 /* PROPS */, _hoisted_15)
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    (!$props.readonly)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dRecords, (record, idx) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (record.id)
                ? (_openBlock(), _createElementBlock("input", {
                    key: 0,
                    type: "hidden",
                    name: `${$props.formScope}[${idx}][id]`,
                    value: record.id
                  }, null, 8 /* PROPS */, _hoisted_16))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 256 /* UNKEYED_FRAGMENT */)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dRecords, (record, idx) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (record._destroy)
                ? (_openBlock(), _createElementBlock("input", {
                    key: 0,
                    type: "hidden",
                    name: `${$props.formScope}[${idx}][_destroy]`,
                    value: "true"
                  }, null, 8 /* PROPS */, _hoisted_17))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 256 /* UNKEYED_FRAGMENT */))
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ]))
}