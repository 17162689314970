import { vModelText as _vModelText, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_code_editor = _resolveComponent("code-editor")

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("input", _mergeProps({
      type: "hidden",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.dValue) = $event))
    }, _ctx.$attrs), null, 16 /* FULL_PROPS */), [
      [_vModelText, $data.dValue]
    ]),
    _createVNode(_component_code_editor, {
      modelValue: $data.dValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.dValue) = $event)),
      height: $props.height,
      language: $props.language
    }, null, 8 /* PROPS */, ["modelValue", "height", "language"])
  ]))
}