Covers:
  @srs_1.5 @srs_15.3 @srs_15.5 @srs_20.1 @srs_20.2 @srs_20.3 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

Integrate Vue's transition system with Bootstrap's `collapse` effect so `v-if`
and `v-show` can trigger the collapse and expansion of a block. Example:

    <collapse>
      <div v-if="show">Collapsed Content</div>
    </collapse>

<template>
  <transition @enter="enter" @leave="leave" :css="false">
    <slot />
  </transition>
</template>

<script>
import $ from 'jquery'

export default {
  emits: ['showing', 'hiding'],
  methods: {
    enter(el, done) {
      const $el = $(el)
      $el.addClass('collapse')
      $el.one('shown.bs.collapse', ()=>
        // Ensure we have a state added at the very end that doesn't change the
        // visibility so we can look for it being done opening in tests.
        $el.addClass('shown')
      )
      $el.one('shown.bs.collapse', done)
      this.$emit('showing')
      $el.collapse('show')
    },

    leave(el, done) {
      const $el = $(el)
      $el.addClass('collapse show')
      $el.one('hidden.bs.collapse', done)
      this.$emit('hiding')
      $el.collapse('hide')
    },
  }
}
</script>
