Covers
  @srs_15.4

Root component for the inventory units index screen. Displays list of inventory
units. Provides search options to filter list.

<template>
  <div>
    <filters>
      <div class="row">
        <search v-model="query.search">
          <ul class="list-unstyled">
            <li>Inventory Unit ID</li>
            <li>Unit Number</li>
            <li>Product Code</li>
            <li>Blood type</li>
            <li>Location</li>
          </ul>
        </search>

        <options-filter label="Status" blank="true"
          v-model="query.status_eq" :options="statusOptions"/>
      </div>

      <date-filter label="Expiration" quick-direction="future"
        v-model:at-gteq="query.expiration_at_gteq"
        v-model:at-lteq="query.expiration_at_lteq" />
    </filters>

    <results initial-uri="inventory_units" :query="query">
      <template #default="{ rows }">
        <stacked-table class="results-table" :striped="false">
          <thead>
            <tr>
              <th>Unit Number</th>
              <th>Product Code</th>
              <th>Blood Type</th>
              <th>Expiration Date</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <template v-for="(row, index) in rows" :key="row.id">
              <tr :class="{ odd: (index % 2 == 0) }">
                <td>{{ row.unit_number }}</td>
                <td rowspan="2">{{ row.product_code }}</td>
                <td rowspan="2">{{ row.blood_type }}</td>
                <td rowspan="2">{{ formattedExpirationAt(row) }}</td>
                <td rowspan="2">{{ row.status }}</td>
                <td class="min-width" rowspan="2">
                  <view-button :href="`inventory_units/${row.id}/edit`" />
                </td>
              </tr>
              <tr :class="{ odd: (index % 2 == 0) }">
                <td colspan="5" data-th="Location">
                  <map-icon />
                  {{ row.location_name }}
                </td>
              </tr>
            </template>
          </tbody>
        </stacked-table>
      </template>
    </results>
  </div>
</template>

<script>
import Filters from 'index/filters'
import Search from 'index/filter/search'
import DateFilter from 'index/filter/date'
import OptionsFilter from 'index/filter/options'
import Results from 'index/results'
import QueryPersistence from 'index/query_persistence'
import StackedTable from 'stacked_table/component'
import ViewButton from 'bootstrap/view_button'
import { formatTimestamp } from 'date_time_format'

import moment from 'moment-timezone'

import MapIcon from 'bootstrap-icons/icons/geo-alt.svg'

export default {
  mixins: [QueryPersistence],
  props: ['statusOptions'],
  data() {
    return {
      query: this.fromUrlWithDefaults({
        search: '',
        status_eq: null,

        // Default to next 7 days. Code copied from DateFilter. Not strictly
        // necessary for functionality but ensures the query persistence can
        // properly recognize the default query
        expiration_at_gteq: moment().startOf('day').format(),
        expiration_at_lteq: moment().add(7, 'days').endOf('day').format(),
      })
    }
  },
  methods: {
    formattedExpirationAt(row) {
      return formatTimestamp(row.expiration_at)
    },
  },
  components: {
    Search, Filters, DateFilter, OptionsFilter,
    Results, StackedTable, ViewButton, MapIcon
  }
}
</script>
