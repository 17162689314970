Covers
  @srs_7.5 @srs_15.3 @srs_15.4 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4 @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

Reduce boilerplate in outputting a bootstrapped styled date input. In general
rely on native date input but also include calendar icon to make the field
purpose apparent. Usage:

    <date-input v-model="my_date" />

<template>
  <div class="input-group">
    <input type="date" class="form-control" v-bind="$attrs" :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)" />
    <div class="input-group-append">
      <span class="input-group-text"><icon /></span>
    </div>
  </div>
</template>

<script>
import Icon from 'bootstrap-icons/icons/calendar2-date.svg'

export default {
  inheritAttrs: false,
  props: ['modelValue'],
  emits: ['update:modelValue'],
  components: { Icon },
}
</script>
