import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["selected"]
const _hoisted_2 = ["selected"]
const _hoisted_3 = ["value", "selected"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_option_values = _resolveComponent("option-values")
  const _component_collapse = _resolveComponent("collapse")
  const _component_field = _resolveComponent("field")

  return (_openBlock(), _createBlock(_component_field, {
    label: "Options List",
    hint: $options.hint
  }, {
    default: _withCtx(() => [
      _createElementVNode("select", {
        class: "mb-3",
        onChange: _cache[0] || (_cache[0] = $event => ($options.updateOptions($event.target.value)))
      }, [
        _createElementVNode("option", {
          selected: $options.isBlank,
          value: ""
        }, "-- Blank --", 8 /* PROPS */, _hoisted_1),
        _createElementVNode("option", {
          selected: $options.isCustom,
          value: "0"
        }, "Custom", 8 /* PROPS */, _hoisted_2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.optionLists, (list) => {
          return (_openBlock(), _createElementBlock("option", {
            key: list.id,
            value: list.id,
            selected: list.id == $props.fieldSchema.list_id
          }, _toDisplayString(list.name), 9 /* TEXT, PROPS */, _hoisted_3))
        }), 128 /* KEYED_FRAGMENT */))
      ], 32 /* HYDRATE_EVENTS */),
      _withDirectives(_createVNode(_component_collapse, null, {
        default: _withCtx(() => [
          _createVNode(_component_option_values, {
            optionValues: $props.fieldSchema.option_values
          }, null, 8 /* PROPS */, ["optionValues"])
        ]),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */), [
        [_vShow, $options.isCustom]
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["hint"]))
}