// Covers
//   @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

// This is intended to represent a UserDefinedField definition that can be modified by a user
import api from 'api'
import BaseField from 'custom_field_schema/field_types/base'

export default class {
  constructor(attributes={}) {
    this.reset()
    Object.assign(this, attributes)
  }

  payload() {
    let { id, display_name, class_name, field_name, is_list, field_schema } = this
    return { id, display_name, class_name, field_name, is_list, field_schema }
  }

  reset() {
    this.id = null
    this.display_name = null
    this.class_name = null
    this.field_name = null
    this.is_list = null
    this.field_schema = null
  }

  setField(new_field) {
    this.class_name = new_field.class_name
    this.field_name = new_field.field_name
    this.display_name = new_field.display_name
    this.setIsList(new_field.is_list)
  }

  setIsList(new_val) {
    if(this.is_list !== new_val) {
      this.is_list = new_val

      if(this.is_list) {
        this.field_schema = []
      }
      else {
        this.initFieldSchema()
      }
    }
  }

  initFieldSchema() {
    this.field_schema = new BaseField({}).payload()
  }

  isFieldList() {
    return Array.isArray(this.field_schema)
  }

  async send() {
    const {id, _displayName, _className, ...data} = this.payload()

    let path, method
    if( id ) {
      path = `user_defined_fields/${id}`
      method = 'PATCH'
    } else {
      path = `user_defined_fields`
      method = 'POST'
    }

    let [_, body] = await api(path, { method, body: data })
    this.id = body.id
  }
}
