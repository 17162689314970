Covers
  @srs_15.3

Quarantine specific view for listing transactions. Adds location to the standard
listing.

<template>
  <transaction-table type="quarantine" :rows="rows">
    <template #additional-headers>
      <th>Location</th>
      <th>Reason</th>
    </template>

    <template #default="{ row }">
      <td>{{ row.location }}</td>
      <td>{{ row.reason }}</td>
    </template>
  </transaction-table>
</template>

<script>
import TransactionTable from './table'

export default {
  props: ['rows'],
  components: { TransactionTable },
}
</script>
