Covers:
  @srs_1.1 @srs_1.3 @srs_1.5 @srs_1.9 @srs_1.10 @srs_5.1 @srs_6.1 @srs_7.5 @srs_8.1 @srs_9.1 @srs_12.1 @srs_15.5 @srs_15.6 @srs_20.1 @srs_20.2 @srs_20.3 @srs_20.5 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4 @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1 @srs_25.2 @srs_26.1

Reduces the boilerplate in rendering a button with bootstrap styles. Usage:

    <styled-button priority="primary" icon="upload">Button text</styled-button>

Any events or attributes that you can normally provide a HTML `button` can be
provided here as well. If you wish to make an icon only button you can just
not provide content and instead provide a `title` prop.

Props:

* tag - Defaults to using a `button` markup but can be changed to another tag
  such as `a` for a link. If the `disabled` attribute is provided then button
  is enforced but it will still be styled as a link.
* type - Only relevant if `tag` is `button`. Specifies the HTML button type
  defaulting to `button`
* href - Only relevant if `tag` is `a`. Specifies where the link navigates to
* priority - One of the bootstrap styles to express the general priority of
  this button ("primary", "secondary", etc).
* purpose - One of the bootstrap styles to express the type of button this
  is ("success", "danger", "info", etc).
* icon - The name of a bootstrap icon. If an icon needs to be used that is
  not a bootstrap icon then the an actual Vue component can also be directly
  provided.
* size - Values such as `xs`, `sm`, etc to indicate the overall button size
* link - Semantically could be a button a link (depending on tag) but visually
  will look like a link.
* outline - If the button should display in outlined format
* title - Specifies the HTML `title` prop. Generally used if no content provided
  Will automatically convert this to a bootstrap title and ensure it works
  even if the button is disabled.
* confirm - If the user should be prompted to confirm they want to do this action

NOTE: purpose and priority are essentially aliases. Sometimes one makes more
sense while other times the other does. But they can be both used. In that
case the priority is used on the button itself while the purpose is used on
the icon (and icon must be specified). This is a special non-bootstrap button
that was created for our theme.

<template>
  <span v-if="disabledTitle" class="d-inline-block" :data-toggle="toggle" :title="title">
    <styled-button v-bind="disabledTitleAttrs" type="button" :disabled="true" style="pointer-events: none">
      <slot />
    </styled-button>
  </span>
  <component v-else :is="normalizedTag" :href="normalizedHref" class="btn"
    :type="buttonType" v-bind="$attrs" :disabled="disabled ? 'disabled' : null"
    :data-toggle="toggle" :title="title" @click="click"
    :class="[buttonClasses, sizeButtonClasses, iconButtonClasses]">
    <span><component :class="{ 'mr-1': link }" v-if="icon" :is="iconComponent" /></span>
    <slot />
  </component>
</template>

<script>
import { createApp, h } from 'vue'

// Side effects that install arrive on global objects
import 'arrive'

import icons from './icons'

export default {
  name: 'StyledButton',
  props: {
    tag: { default: 'button' },
    type: { default: 'button' },
    href: {},
    confirm: { default: false },
    priority: {},
    purpose: {},
    icon: {},
    size: {},
    link: { default: false },
    outline: { default: false },
    circle: { default: false },
    title: {},
    disabled: { default: false },
  },
  emits: ['click'],
  inheritAttrs: false,
  computed: {
    buttonClasses() {
      const classes = []
      const outline = this.outline ? 'outline-' : ''
      if( this.priority ) classes.push(`btn-${outline}${this.priority}`)
      if( this.purpose && !this.priority ) classes.push(`btn-${outline}${this.purpose}`)
      if( this.circle ) classes.push(`btn-circle`)
      if( this.styleAsLink ) classes.push('btn-link')
      return classes
    },

    sizeButtonClasses() {
      if( this.size !== undefined ) return `btn-${this.size}`
      if( !this.$slots.default ) return 'btn-lg'
      return ''
    },

    iconButtonClasses() {
      if( this.icon && this.purpose && this.priority )
        return `btn-icon btn-icon-${this.purpose}`
      return ''
    },

    normalizedTag() {
      if( this.disabled ) return 'button'
      return this.tag
    },

    styleAsLink() {
      // User requested display as link
      if( this.link ) return true

      // User requested disabled `a`. We'll use button and style as link
      if( this.tag == 'a' && this.disabled ) return true

      return false
    },

    buttonType() {
      if( this.normalizedTag == 'button' ) return this.type
      return null
    },

    normalizedHref() {
      if( this.normalizedTag == 'button' ) return
      return this.href
    },

    iconComponent() {
      if( typeof this.icon === 'string' )
        return icons[this.icon]
      else
        return this.icon
    },

    toggle() {
      // If toggle provided then use it
      if( this.$attrs['data-toggle'] ) return this.$attrs['data-toggle']

      // If not provided but title is provided then convert to a BS tooltip
      if( this.title ) return 'tooltip'
    },

    disabledTitle() { return this.title && this.disabled },

    disabledTitleAttrs() {
      const { title, disabled, ...rest } = this.$props
      return {...rest, ...this.$attrs}
    },
  },
  methods: {
    click(event) {
      if( this.confirm && !confirm('Are you sure?') ) {
        event.stopImmediatePropagation()
        event.preventDefault()
        return
      }

      this.$emit('click')
    }
  },

  progressiveEnhance() {
    const tags = ['a', 'button']
    const dataAttrs = ['icon', 'priority', 'purpose', 'size', 'outline', 'link', 'confirm', 'circle']
    const selectors = []

    for( const tag of tags ) {
      for( const dataAttr of dataAttrs ) {
        selectors.push(`${tag}[data-${dataAttr}]`)
      }
    }

    document.arrive(selectors.join(', '), { existing: true }, container => {
      const props = {}

      props.tag = container.tagName.toLowerCase()

      for( const attr of dataAttrs ) {
        if( !container.dataset[attr] ) continue
        props[attr] = container.dataset[attr]
      }

      attr_loop: for( var i = container.attributes.length - 1; i >= 0; i-- ) {
        const attr_name = container.attributes[i].name
        for( const dAttr of dataAttrs )
          if( attr_name == `data-${dAttr}`) continue attr_loop
        props[attr_name] = container.attributes[i].value
      }

      const me = this
      const root = document.createDocumentFragment()
      const app = createApp({
        render() { return h(me, props, ()=> container.innerHTML) }
      }, props)
      app.mount(root)
      container.replaceWith(root)
      //onNodeRemove(root, ()=> app.unmount())
    })
  }
}
</script>
